import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "services/axios";

const ENDPOINT = process.env.REACT_APP_API_URL;
const API = `${ENDPOINT}/api/sdl`;

const SdlApi = createApi({
  reducerPath: "sdl",
  baseQuery: axiosBaseQuery({
    baseUrl: API,
  }),
  endpoints(builder) {
    return {
      fetchSdl: builder.query({
        providesTags: () => ["sdlFetch"],
        query: (query) => {
          return {
            url: "/read",
            method: "GET",
            params: query,
          };
        },
      }),
      fetchSdlId: builder.query({
        providesTags: (id) => ["sdlFetchId", id],
        query: (id) => {
          return {
            url: `/${id}`,
            method: "GET",
          };
        },
      }),
      addSdl: builder.mutation({
        invalidatesTags: (result, error, body) => {
          return [{ type: "" }];
        },
        query: (body) => {
          return {
            url: "/create",
            method: "POST",
            body: body,
          };
        },
      }),
      updateSdl: builder.mutation({
        invalidatesTags: (result, error, body) => {
          return [{ type: "sdlUpdate" }];
        },
        query: (body) => {
          return {
            url: `/update`,
            method: "PUT",
            body: body,
          };
        },
      }),
      deleteSdl: builder.mutation({
        invalidatesTags: (result, error, body) => {
          return [{ type: "" }];
        },
        query: (body) => {
          return {
            url: "/delete",
            method: "POST",
            body: body,
          };
        },
      }),
    };
  },
});

export const {
  useAddSdlMutation,
  useFetchSdlQuery,
  useUpdateSdlMutation,
  useDeleteSdlMutation,
  useFetchSdlIdQuery,
} = SdlApi;
export { SdlApi };
