import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "services/axios";

const ENDPOINT = process.env.REACT_APP_API_URL;
const API = `${ENDPOINT}/api/payroll`;

const PayrollApi = createApi({
  reducerPath: "payroll",
  baseQuery: axiosBaseQuery({
    baseUrl: API,
  }),
  endpoints(builder) {
    return {
      fetchPayrollCalculateComponent: builder.mutation({
        query: (params) => {
          return {
            url: "/readEmployeePreview",
            method: "GET",
            params: params,
          };
        },
      }),
      createPayroll: builder.mutation({
        query: (body) => {
          return {
            url: "/create",
            method: "POST",
            body: body,
          };
        },
      }),
      generatePayrollRunner: builder.mutation({
        query: (body) => {
          return {
            url: "/generate-payroll",
            method: "POST",
            body: body,
          };
        },
      }),
      updatePayroll: builder.mutation({
        query: (body) => {
          return {
            url: "/update",
            method: "PUT",
            body: body,
          };
        },
      }),
      generatePdfPayroll: builder.mutation({
        query: (id) => {
          return {
            url: `/generate-pdf/${id}`,
            method: "POST",
            responseType: "blob",
          };
        },
      }),
      generatePdfPayslip: builder.mutation({
        query: (data) => {
          return {
            url: `/generate-pdf-payslip/${data?.idParent}`,
            method: "POST",
            body: data?.idSelect,
            responseType: "blob",
          };
        },
      }),
      generatePdfPayslipDetail: builder.mutation({
        query: (data) => {
          return {
            url: `/generate-pdf-payslip-detail/${data?.idParent}`,
            method: "POST",
            body: data?.idSelect,
            responseType: "blob",
          };
        },
      }),
      generateExcel: builder.mutation({
        query: (data) => {
          return {
            url: `/postExcel`,
            method: "POST",
            body: data,
            responseType: "blob",
          };
        },
      }),
      fetchPayroll: builder.query({
        providesTags: () => ["payroll"],
        query: (params) => {
          return {
            url: "/read",
            method: "GET",
            params: params,
          };
        },
      }),
      deletePayroll: builder.mutation({
        providesTags: () => ["payroll-delete"],
        query: (params) => {
          return {
            url: "/delete",
            method: "DELETE",
            body: params,
          };
        },
      }),
      fetchPayrollOption: builder.mutation({
        providesTags: () => ["payroll-option"],
        query: (params) => {
          return {
            url: "/option",
            method: "GET",
            params: params,
          };
        },
      }),
      fetchPayrollAttendanceSettingLeave: builder.query({
        providesTags: () => ["attendance-setting"],
        query: () => {
          return {
            url: "/setting-attendance",
            method: "GET",
          };
        },
      }),
      fetchPayrollLeaveReport: builder.query({
        providesTags: () => ["leave-report"],
        query: (params) => {
          return {
            url: "/leave-report",
            method: "GET",
            params: params,
          };
        },
      }),
      fetchPayrollCalculateLeaveOvertime: builder.query({
        providesTags: () => ["calculate-overtime"],
        query: (params) => {
          return {
            url: "/calculate-overtime",
            method: "GET",
            params,
          };
        },
      }),
    };
  },
});
export const {
  useFetchPayrollAttendanceSettingLeaveQuery,
  useFetchPayrollCalculateLeaveOvertimeQuery,
  useFetchPayrollLeaveReportQuery,
  useCreatePayrollMutation,
  useFetchPayrollQuery,
  useFetchPayrollOptionMutation,
  useGeneratePdfPayrollMutation,
  useGeneratePdfPayslipMutation,
  useUpdatePayrollMutation,
  useDeletePayrollMutation,
  useGeneratePayrollRunnerMutation,
  useGeneratePdfPayslipDetailMutation,
  useGenerateExcelMutation,
} = PayrollApi;

export { PayrollApi };
