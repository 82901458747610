import React from "react";

export const FileSizeSVG = ({ color, ...otherProps }) => {
  return (
    <svg
      width="37"
      height="46"
      viewBox="0 0 37 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.83588 36.51C6.91024 35.4374 6.91024 33.6983 5.83588 32.6256C4.76152 31.5529 3.01965 31.5529 1.94529 32.6256C0.870938 33.6983 0.870938 35.4374 1.94529 36.51C3.01965 37.5827 4.76152 37.5827 5.83588 36.51Z"
        fill="#2BB673"
      />
      <path
        d="M3.89422 37.8487C2.08174 37.8487 0.617188 36.3784 0.617188 34.5768C0.617188 32.7753 2.08983 31.305 3.89422 31.305C5.69861 31.305 7.17125 32.7753 7.17125 34.5768C7.17125 36.3784 5.69861 37.8487 3.89422 37.8487ZM3.89422 32.3471C2.66432 32.3471 1.66907 33.3489 1.66907 34.5688C1.66907 35.7967 2.67241 36.7904 3.89422 36.7904C5.12411 36.7904 6.11936 35.7886 6.11936 34.5688C6.11936 33.3408 5.12411 32.3471 3.89422 32.3471Z"
        fill="#1A1A2E"
      />
      <path
        d="M24.1066 0.525421H10.7071C7.04981 0.525421 4.08026 3.4822 4.08026 7.14182V38.8666C4.08026 42.5181 7.04172 45.483 10.7071 45.483H29.8272C33.4845 45.483 36.4541 42.5262 36.4541 38.8666V12.8534L24.1066 0.525421Z"
        fill="#FDFDFD"
      />
      <path
        d="M29.8272 46H10.7071C6.76662 46 3.55432 42.8009 3.55432 38.8585V7.14181C3.55432 3.20752 6.75853 0.000305176 10.7071 0.000305176H24.1066C24.2441 0.000305176 24.3817 0.0568575 24.4788 0.153801L36.8182 12.4737C36.9153 12.5706 36.9719 12.708 36.9719 12.8453V38.8585C36.9719 42.8009 33.7677 46 29.8272 46ZM10.7071 1.05053C7.3492 1.05053 4.60621 3.7811 4.60621 7.14181V38.8666C4.60621 42.2192 7.34111 44.9579 10.7071 44.9579H29.8272C33.1851 44.9579 35.9281 42.2273 35.9281 38.8666V13.0634L23.8881 1.05053H10.7071Z"
        fill="#1A1A2E"
      />
      <path
        d="M24.1066 0.525421L36.446 12.8453H26.7363C25.2798 12.8453 24.1066 11.6658 24.1066 10.2198V0.525421Z"
        fill="#E3E3E3"
      />
      <path
        d="M36.446 13.3704H26.7363C24.9966 13.3704 23.5806 11.9567 23.5806 10.2198V0.525414C23.5806 0.31537 23.7101 0.121483 23.9043 0.0406969C24.0985 -0.0400893 24.325 0.000305758 24.4788 0.1538L36.7939 12.4495C36.9072 12.5464 36.9719 12.6837 36.9719 12.8453C36.9719 13.1361 36.7373 13.3704 36.446 13.3704ZM24.6325 1.79376V10.2198C24.6325 11.3831 25.5792 12.3202 26.7363 12.3202H35.1756L24.6325 1.79376Z"
        fill="#1A1A2E"
      />
      <path
        d="M4.14506 18.7104C2.51059 18.7104 1.18359 20.0353 1.18359 21.6672V34.0517H1.24023C1.50725 32.6864 2.70478 31.6604 4.14506 31.6604H33.9782V18.7104H4.14506Z"
        fill="#2BB673"
      />
      <path
        d="M1.23212 34.5849H1.17548C0.884188 34.5849 0.649536 34.3506 0.649536 34.0598V21.6752C0.649536 19.7525 2.21119 18.1934 4.13695 18.1934H33.9701C34.2613 18.1934 34.496 18.4276 34.496 18.7185V31.6685C34.496 31.9593 34.2613 32.1936 33.9701 32.1936H4.14504C2.97987 32.1936 1.97653 33.0176 1.74997 34.1648C1.70143 34.3991 1.48295 34.5849 1.23212 34.5849ZM4.14504 19.2355C2.80186 19.2355 1.70952 20.3261 1.70952 21.6672V32.1209C2.34065 31.5069 3.20643 31.1272 4.14504 31.1272H33.4522V19.2355H4.14504Z"
        fill="#1A1A2E"
      />
      <path
        d="M8.72479 27.6454C8.66815 27.5888 8.61151 27.4838 8.61151 27.3788C8.61151 27.1687 8.78952 26.991 8.9999 26.991C9.12936 26.991 9.21837 27.0637 9.27501 27.1283C9.65531 27.6454 10.0599 27.912 10.7072 27.912C11.4921 27.912 12.0746 27.3465 12.0746 26.2397V21.9257C12.0746 21.7076 12.2527 21.5298 12.4711 21.5298C12.6896 21.5298 12.8595 21.7076 12.8595 21.9257V26.2316C12.8595 27.0475 12.6168 27.6615 12.2203 28.0574C11.8319 28.4371 11.306 28.631 10.6829 28.631C9.77668 28.639 9.15363 28.2189 8.72479 27.6454Z"
        fill="#FDFDFD"
      />
      <path
        d="M14.7286 21.9742C14.7286 21.756 14.9066 21.5783 15.117 21.5783H17.3341C18.9038 21.5783 19.9395 22.4104 19.9395 23.7838V23.7999C19.9395 25.3026 18.6853 26.0781 17.1965 26.0781H15.5054V28.1866C15.5054 28.4048 15.3274 28.5825 15.1089 28.5825C14.8985 28.5825 14.7205 28.4048 14.7205 28.1866V21.9742H14.7286ZM17.237 25.3672C18.394 25.3672 19.1465 24.7532 19.1465 23.8322V23.8161C19.1465 22.8224 18.4021 22.3054 17.2774 22.3054H15.5135V25.3672H17.237Z"
        fill="#FDFDFD"
      />
      <path
        d="M21.0723 25.0764V25.0602C21.0723 23.1536 22.4802 21.4571 24.5678 21.4571C25.6197 21.4571 26.3075 21.7318 26.9467 22.2004C27.0357 22.2731 27.1085 22.3781 27.1085 22.5154C27.1085 22.7335 26.9305 22.9113 26.7201 22.9113C26.6311 22.9113 26.5502 22.8709 26.4693 22.8143C25.9676 22.4346 25.4174 22.1761 24.5355 22.1761C22.99 22.1761 21.8896 23.4768 21.8896 25.0279V25.044C21.8896 26.7082 22.9576 27.9362 24.6487 27.9362C25.4417 27.9362 26.1861 27.6292 26.6635 27.2414V25.5126H24.7944C24.6083 25.5126 24.4465 25.351 24.4465 25.1652C24.4465 24.9632 24.6083 24.8098 24.7944 24.8098H27.0357C27.2461 24.8098 27.4241 24.9875 27.4241 25.2056V27.3222C27.4241 27.508 27.3351 27.6777 27.1571 27.7988C26.5179 28.2674 25.6602 28.6471 24.6245 28.6471C22.4317 28.6552 21.0723 27.0637 21.0723 25.0764Z"
        fill="#FDFDFD"
      />
    </svg>
  );
};
