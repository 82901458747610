import React from "react";

const FileIconSvg = ({ color, ...otherProps }) => {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.7934 7.36726L7.6667 13.4939C6.91613 14.2445 5.89815 14.6662 4.8367 14.6662C3.77524 14.6662 2.75726 14.2445 2.0067 13.4939C1.25613 12.7434 0.834473 11.7254 0.834473 10.6639C0.834473 9.60247 1.25613 8.58449 2.0067 7.83393L8.13336 1.70726C8.63374 1.20689 9.31239 0.925781 10.02 0.925781C10.7277 0.925781 11.4063 1.20689 11.9067 1.70726C12.4071 2.20764 12.6882 2.88629 12.6882 3.59393C12.6882 4.30157 12.4071 4.98022 11.9067 5.4806L5.77336 11.6073C5.52318 11.8575 5.18385 11.998 4.83003 11.998C4.47621 11.998 4.13688 11.8575 3.8867 11.6073C3.63651 11.3571 3.49596 11.0177 3.49596 10.6639C3.49596 10.3101 3.63651 9.97078 3.8867 9.7206L9.5467 4.06726"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default FileIconSvg;
