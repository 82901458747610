import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "services/axios";

const ENDPOINT = process.env.REACT_APP_API_URL;
const API = `${ENDPOINT}/api/fwl`;

const FwlApi = createApi({
  reducerPath: "fwl",
  baseQuery: axiosBaseQuery({
    baseUrl: API,
  }),
  endpoints(builder) {
    return {
      fetchFwl: builder.query({
        providesTags: () => ["fwlFetch"],
        query: (query) => {
          return {
            url: "/read",
            method: "GET",
            params: query,
          };
        },
      }),
      addFwl: builder.mutation({
        invalidatesTags: (result, error, body) => {
          return [{ type: "" }];
        },
        query: (body) => {
          return {
            url: "/create",
            method: "POST",
            body: body,
          };
        },
      }),
      updateFwl: builder.mutation({
        invalidatesTags: (result, error, body) => {
          return [{ type: "fwlUpdateFetch" }];
        },
        query: (body) => {
          return {
            url: `/update`,
            method: "PUT",
            body: body,
          };
        },
      }),
      deleteFwl: builder.mutation({
        invalidatesTags: (result, error, body) => {
          return [{ type: "" }];
        },
        query: (body) => {
          return {
            url: "/delete",
            method: "POST",
            body: body,
          };
        },
      }),
    };
  },
});

export const {
  useFetchFwlQuery,
  useAddFwlMutation,
  useUpdateFwlMutation,
  useDeleteFwlMutation,
} = FwlApi;
export { FwlApi };
