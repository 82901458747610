import { createSlice } from "@reduxjs/toolkit";

const payrollSlice = createSlice({
  name: "payroll-slice",
  initialState: {
    payrollSetting: null,
    payrollProcess: null,
    dataLocationEmployee: [],
    dataAttendance: {
      _id: "66b36d5d2476995a31a8b18e",
      uId: "8288940d-ab9d-400c-b3b8-39cfe009588f",
      organizationID: "ed22bcb9-a3ee-48e7-b92f-c70471cf62e2",
      earlyPunchIn: 15,
      earlyPunchOut: 15,
      punchInDispen: 15,
      punchOutDispen: 15,
      selectOvertimeType: "daily",
      dailyOvertime: 9,
      weeklyOvertime: 40,
      overtimeRounding: [
        {
          from: 0,
          to: 15,
          equal: 0,
          _id: "66b36d5d2476995a31a8b18f",
        },
        {
          from: 15,
          to: 30,
          equal: 15,
          _id: "66b36d5d2476995a31a8b190",
        },
        {
          from: 30,
          to: 45,
          equal: 30,
          _id: "66b36d5d2476995a31a8b191",
        },
        {
          from: 45,
          to: 60,
          equal: 45,
          _id: "66b36d5d2476995a31a8b192",
        },
      ],
      overtimeMultiplier: [
        {
          from: 0,
          to: 2,
          equal: 1,
          _id: "66b36d5d2476995a31a8b193",
        },
        {
          from: 2,
          to: 99,
          equal: 2,
          _id: "66b36d5d2476995a31a8b194",
        },
      ],
      createdAt: "2024-08-07T12:49:33.698Z",
      updatedAt: "2024-08-07T12:49:33.698Z",
    },
    payslipDetail: null,
  },
  reducers: {
    setPayrollReducers: (state, data) => {
      state.payrollSetting = data;
    },
    setPayrollProcessReducers: (state, data) => {
      state.payrollProcess = data;
    },
    setPayslipDetailReducers: (state, data) => {
      state.payslipDetail = data;
    },
    setDataLocationEmployeeReducers: (state, data) => {
      state.dataLocationEmployee = data;
    },
  },
});

export const {
  setPayrollReducers,
  setDataLocationEmployeeReducers,
  setPayrollProcessReducers,
  setPayslipDetailReducers,
} = payrollSlice.actions;
export default payrollSlice.reducer;
