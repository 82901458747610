import React from "react";

const PayrollSVG = ({ color, ...otherProps }) => {
  return (
    <svg
      width="24"
      height="24"
      className={`w-6 h-6 ${otherProps.className}`}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.82996 10.357L6.82538 7.76352L9.1015 9.55146L11.0542 7.03125"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle
        cx="13.3303"
        cy="3.301"
        r="1.28146"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.94969 2.58203H5.10453C3.0969 2.58203 1.85205 4.00385 1.85205 6.01147V11.3997C1.85205 13.4074 3.07249 14.8231 5.10453 14.8231H10.8406C12.8482 14.8231 14.0931 13.4074 14.0931 11.3997V6.70713"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default PayrollSVG;
