import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "services/axios";

const ENDPOINT = process.env.REACT_APP_API_URL;
const API = `${ENDPOINT}/api/shg`;

const ShgApi = createApi({
  reducerPath: "shg",
  baseQuery: axiosBaseQuery({
    baseUrl: API,
  }),
  endpoints(builder) {
    return {
      fetchShg: builder.query({
        providesTags: () => ["cpfbFetch"],
        query: (query) => {
          return {
            url: "/read",
            method: "GET",
            params: query,
          };
        },
      }),
      fetchShgId: builder.query({
        providesTags: (id) => ["claimFetchId", id],
        query: (id) => {
          return {
            url: `/${id}`,
            method: "GET",
          };
        },
      }),
      addShg: builder.mutation({
        invalidatesTags: (result, error, body) => {
          return [{ type: "" }];
        },
        query: (body) => {
          return {
            url: "/create",
            method: "POST",
            body: body,
          };
        },
      }),
      updateShg: builder.mutation({
        invalidatesTags: (result, error, body) => {
          return [{ type: "updateShg" }];
        },
        query: (body) => {
          return {
            url: `/update`,
            method: "PUT",
            body: body,
          };
        },
      }),
      updateShgStatus: builder.mutation({
        invalidatesTags: (result, error, body) => {
          return [{ type: "updateShgtatus" }];
        },
        query: (body) => {
          return {
            url: `/updateStatus`,
            method: "PUT",
            body: body,
          };
        },
      }),
      deleteShg: builder.mutation({
        invalidatesTags: (result, error, body) => {
          return [{ type: "" }];
        },
        query: (body) => {
          return {
            url: "/delete",
            method: "POST",
            body: body,
          };
        },
      }),
    };
  },
});

export const {
  useFetchShgQuery,
  useAddShgMutation,
  useFetchShgIdQuery,
  useDeleteShgMutation,
  useUpdateShgMutation,
  useUpdateShgStatusMutation,
} = ShgApi;
export { ShgApi };
