import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

import { claimsTypeApi } from "./api/claimsTypeApi";
import { PayrollComponentApi } from "./api/payrollComponentApi";
import { EmployeeComponentApi } from "./api/employeeComponentApi";
import payrollSlice from "./slice/payrollSlice";
import loginSlice from "./slice/userLoginSlice";
import cpfbSlice from "./slice/cpfbSlice";
import { claimsApi } from "./api/claimsApi";
import { CpfbApi } from "./api/cpfbApi";
import { ShgApi } from "./api/shgApi";
import { PayrollApi } from "./api/payrollApi";
import { SalaryCeilingsApi } from "./api/salaryCeilingsApi";
import { SdlApi } from "./api/sdlApi";
import { CommonApi } from "./api/commonApi";
import { FwlApi } from "./api/fwl";
import { PaymentScheduleApi } from "./api/parymentScheduleApi";

const persistConfig = {
  key: "root",
  storage,
  blacklist: [
    "claim",
    "claimtype",
    "payroll-component",
    "cpfb",
    "payrollSlice",
    "employee-component",
    "payroll",
    // "loginSlice",
    "shg",
    "cpfbSlice",
    "payment-schedule",
  ],
};

const rootReducer = combineReducers({
  [claimsTypeApi.reducerPath]: claimsTypeApi.reducer,
  [claimsApi.reducerPath]: claimsApi.reducer,
  [PayrollComponentApi.reducerPath]: PayrollComponentApi.reducer,
  [EmployeeComponentApi.reducerPath]: EmployeeComponentApi.reducer,
  [CpfbApi.reducerPath]: CpfbApi.reducer,
  [ShgApi.reducerPath]: ShgApi.reducer,
  [PayrollApi.reducerPath]: PayrollApi.reducer,
  [SalaryCeilingsApi.reducerPath]: SalaryCeilingsApi.reducer,
  [SdlApi.reducerPath]: SdlApi.reducer,
  [CommonApi.reducerPath]: CommonApi.reducer,
  [FwlApi.reducerPath]: FwlApi.reducer,
  [PaymentScheduleApi.reducerPath]: PaymentScheduleApi.reducer,
  payrollSlice: payrollSlice,
  loginSlice: loginSlice,
  cpfbSlice: cpfbSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware()
      .concat(thunk)
      .concat(claimsTypeApi.middleware)
      .concat(claimsApi.middleware)
      .concat(PayrollComponentApi.middleware)
      .concat(EmployeeComponentApi.middleware)
      .concat(PaymentScheduleApi.middleware)
      .concat(CpfbApi.middleware)
      .concat(ShgApi.middleware)
      .concat(SalaryCeilingsApi.middleware)
      .concat(SdlApi.middleware)
      .concat(PayrollApi.middleware)
      .concat(CommonApi.middleware)
      .concat(FwlApi.middleware)
      .concat(PayrollApi.middleware);
  },
});

setupListeners(store.dispatch);

export default configureStore({
  reducer: {},
});

export {
  useAddClaimsTypeMutation,
  useUpdateClaimsTypeMutation,
  useDeleteClaimsTypeMutation,
} from "./api/claimsTypeApi";

export {
  useFetchPayrollComponentQuery,
  useAddPayrollComponentMutation,
  useUpdatePayrollComponentMutation,
  useDeletePayrollComponentMutation,
} from "./api/payrollComponentApi";
export const persistor = persistStore(store);
