import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "services/axios";

const ENDPOINT = process.env.REACT_APP_API_URL;
const API = `${ENDPOINT}/api/payroll-cpfb`;

const CpfbApi = createApi({
  reducerPath: "cpfb",
  baseQuery: axiosBaseQuery({
    baseUrl: API,
  }),
  endpoints(builder) {
    return {
      fetchCpfb: builder.query({
        providesTags: () => ["cpfbFetch"],
        query: (query) => {
          return {
            url: "/read",
            method: "GET",
            params: query,
          };
        },
      }),
      fetchCpfbStatus: builder.query({
        providesTags: () => ["cpfbStatusFetch"],
        query: (query) => {
          return {
            url: "/status",
            method: "GET",
            params: query,
          };
        },
      }),
      fetchCpfbId: builder.query({
        providesTags: (id) => ["claimFetchId", id],
        query: (id) => {
          return {
            url: `/${id}`,
            method: "GET",
          };
        },
      }),
      addCpfb: builder.mutation({
        invalidatesTags: (result, error, body) => {
          return [{ type: "" }];
        },
        query: (body) => {
          return {
            url: "/create",
            method: "POST",
            body: body,
          };
        },
      }),
      updateCpfb: builder.mutation({
        invalidatesTags: (result, error, body) => {
          return [{ type: "updateCpfb" }];
        },
        query: (body) => {
          return {
            url: `/update`,
            method: "PUT",
            body: body,
          };
        },
      }),
      updateCpfbStatus: builder.mutation({
        invalidatesTags: (result, error, body) => {
          return [{ type: "updateCpfbtatus" }];
        },
        query: (body) => {
          return {
            url: `/updateStatus`,
            method: "PUT",
            body: body,
          };
        },
      }),
      deleteCpfb: builder.mutation({
        invalidatesTags: (result, error, body) => {
          return [{ type: "" }];
        },
        query: (body) => {
          return {
            url: "/delete",
            method: "POST",
            body: body,
          };
        },
      }),
    };
  },
});

export const {
  useFetchCpfbQuery,
  useDeleteCpfbMutation,
  useAddCpfbMutation,
  useUpdateCpfbMutation,
  useFetchCpfbStatusQuery,
} = CpfbApi;
export { CpfbApi };
