import React from "react";

const PayrollComponentSVG = ({ color, ...otherProps }) => {
  return (
    <svg
      width="24"
      height="24"
      className={`w-6 h-6 ${otherProps.className}`}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_5510_19973)">
        <path
          d="M14.2934 7.86726L8.1667 13.9939C7.41613 14.7445 6.39815 15.1662 5.3367 15.1662C4.27524 15.1662 3.25726 14.7445 2.5067 13.9939C1.75613 13.2434 1.33447 12.2254 1.33447 11.1639C1.33447 10.1025 1.75613 9.08449 2.5067 8.33393L8.63336 2.20726C9.13374 1.70689 9.81239 1.42578 10.52 1.42578C11.2277 1.42578 11.9063 1.70689 12.4067 2.20726C12.9071 2.70764 13.1882 3.38629 13.1882 4.09393C13.1882 4.80157 12.9071 5.48022 12.4067 5.9806L6.27336 12.1073C6.02318 12.3575 5.68385 12.498 5.33003 12.498C4.97621 12.498 4.63688 12.3575 4.3867 12.1073C4.13651 11.8571 3.99596 11.5177 3.99596 11.1639C3.99596 10.8101 4.13651 10.4708 4.3867 10.2206L10.0467 4.56726"
          stroke={color}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5510_19973">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PayrollComponentSVG;
