import React, { useCallback, useEffect, useState } from "react";
import { Button, Accordion, InputNumber } from "@bluesilodev/timhutcomponents";
import SaveIconSVG from "assets/icons/SaveIconSVG/SaveIconSVG";
import {
  useAddPaymentScheduleMutation,
  useFetchPaymentScheduleBiUserIdQuery,
  useUpdatePaymentScheduleMutation,
} from "store/api/parymentScheduleApi";
import { useAlertApi } from "services/alert-api";
import { useSelector } from "react-redux";
const PaymentSchedule = () => {
  const [noDay, setNoDay] = useState(30);
  const { currentUser, currentRole } = useSelector((state) => state.loginSlice);
  const [saveInfo, setSaveInfo] = useState(false);
  const [role] = React.useState(localStorage.getItem("role") || "user");
  const { data } = useFetchPaymentScheduleBiUserIdQuery(
    {
      userId: currentUser?.uId,
    },
    { refetchOnMountOrArgChange: true, refetchOnFocus: true },
  );

  const [requestAddPaymentSchedule, { data: dataPayment, isSuccess }] =
    useAddPaymentScheduleMutation();

  const [requestUpdatePaymentSchedule, { isSuccess: isUpdate, isError }] =
    useUpdatePaymentScheduleMutation();
  const dataPayrollSchedule = data?.data;

  useAlertApi(
    Object.assign(
      { data: { dataPayment, message: "success" } },
      isSuccess
        ? { isSuccess: isSuccess && saveInfo }
        : { isError: isSuccess && saveInfo },
    ),
  );
  useAlertApi(
    Object.assign(
      {
        data: { dataPayment, message: "success" },
      },
      isUpdate
        ? { isSuccess: isUpdate && saveInfo }
        : { isError: isUpdate && saveInfo },
    ),
  );

  useEffect(() => {
    if (dataPayrollSchedule) {
      setNoDay(parseInt(dataPayrollSchedule?.time));
    }
  }, [dataPayrollSchedule]);
  const save = useCallback(async () => {
    setSaveInfo(true);
    if (dataPayrollSchedule) {
      await requestUpdatePaymentSchedule({
        time: noDay,
        userId: currentUser?.uId,
        id: dataPayrollSchedule?._id,
      });
    } else {
      await requestAddPaymentSchedule({
        time: noDay,
        userId: currentUser?.uId,
      });
    }
    setSaveInfo(false);
  }, [
    dataPayrollSchedule,
    requestUpdatePaymentSchedule,
    noDay,
    currentUser?.uId,
    requestAddPaymentSchedule,
  ]);
  return (
    <div className="w-full flex-col mt-5 ">
      <div className="w-[100%]  flex justify-end">
        {currentRole?.toLowerCase() === "admin" && (
          <Button
            onClick={save}
            style="solid"
            label={
              <div className="flex px-4 gap-1">
                <SaveIconSVG color={"white"} />
                <div>Save</div>
              </div>
            }
          />
        )}
      </div>
      <div className="w-full flex mt-5 ">
        <Accordion
          title={"Payment Schedule"}
          children={
            <div className="mt-5 w-[30%]">
              <InputNumber
                disable={role?.toLowerCase() === "employee"}
                label="Payroll will be scheduled on this date every month"
                name="input_number1_1"
                value={noDay}
                onChange={(d) => setNoDay(d)}
              />
            </div>
          }
        />
      </div>
    </div>
  );
};

export default PaymentSchedule;
