import React from "react";

const ReportsSVG = ({ color, ...otherProps }) => {
  return (
    <svg
      width="24"
      height="24"
      className={`w-6 h-6 ${otherProps.className}`}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.8252 2.33986H5.38987C4.0032 2.33453 2.86654 3.43986 2.83387 4.82586V11.9679C2.8032 13.3765 3.91987 14.5439 5.32854 14.5752C5.3492 14.5752 5.3692 14.5759 5.38987 14.5752H10.7159C12.1119 14.5185 13.2119 13.3652 13.2019 11.9679V5.8572L9.8252 2.33986Z"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.65009 2.33203V4.27136C9.65009 5.21803 10.4154 5.98536 11.3621 5.98803H13.1988"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.52541 10.7396H5.92542"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.16214 8.23568H5.9248"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ReportsSVG;
