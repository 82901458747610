import React from "react";

const PayrollReportSVG = ({ color, ...otherProps }) => {
  return (
    <svg
      width="24"
      height="24"
      className={`w-6 h-6 ${otherProps.className}`}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 5.16797L8.66667 5.16797"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M6 10.5L8 10.5"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M6 7.83203L10 7.83203"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M12.6666 7.83333V7.16667C12.6666 5.61217 12.6666 4.83492 12.3856 4.23215C12.0875 3.59292 11.5737 3.07915 10.9345 2.78108C10.3317 2.5 9.55448 2.5 7.99998 2.5V2.5C6.44548 2.5 5.66823 2.5 5.06546 2.78108C4.42623 3.07915 3.91247 3.59292 3.61439 4.23215C3.33331 4.83492 3.33331 5.61217 3.33331 7.16667V9.83333C3.33331 11.3878 3.33331 12.1651 3.61439 12.7679C3.91247 13.4071 4.42623 13.9208 5.06546 14.2189C5.66823 14.5 6.44548 14.5 7.99998 14.5V14.5"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle
        cx="11.6667"
        cy="12.1667"
        r="1.66667"
        stroke={color}
        stroke-width="1.5"
      />
      <path
        d="M14 14.5L13 13.5"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default PayrollReportSVG;
