import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
  Accordion,
  InputDate,
  InputSelect,
  InputText,
  Button,
} from "@bluesilodev/timhutcomponents";
import { useFormik } from "formik";
import { formatRupiah } from "utils/numberUtils";
import FileDockSearchSVG from "assets/icons/FileDockSearchSVG";
import FileMinusSVG from "assets/icons/FileMinusSVG";
import Steps from "./steps";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { NavLink } from "react-router-dom";
import { setPayrollReducers } from "store/slice/payrollSlice";

const Review = ({ setSteps, steps, labelSteps }) => {
  const { payrollSlice } = useSelector((state) => state);
  const location = payrollSlice?.dataLocationEmployee?.payload;
  /**
   * @type {import("type/typePayroll").Payroll}
   */
  const resultPayrollProcess = payrollSlice?.payrollProcess?.payload?.data;
  const dispatch = useDispatch();

  const {
    handleBlur,
    handleChange,
    setFieldValue,
    values,
    handleSubmit,
    setValues,
  } = useFormik({
    initialValues: {
      startDate: "",
      endDate: "",
      paymentDate: "",
      location: "",
      employmentStatus: "option2",
      payrollLabel: "",
    },
  });
  const totalDeductions = useMemo(() => {
    return resultPayrollProcess?.data?.reduce((pre, v) => {
      pre += v?.total;
      return pre;
    }, 0);
  }, [resultPayrollProcess]);
  useEffect(() => {
    if (resultPayrollProcess) {
      setValues({
        startDate: dayjs(resultPayrollProcess.startDate).format("DD/MM/YYYY"),
        endDate: dayjs(resultPayrollProcess.endDate).format("DD/MM/YYYY"),
        paymentDate: dayjs(resultPayrollProcess.paymentDate).format(
          "DD/MM/YYYY",
        ),
        employmentStatus: resultPayrollProcess.employmentStatus,
        location: resultPayrollProcess.location?.id,
        payrollLabel: resultPayrollProcess.label,
      });
    }
  }, [resultPayrollProcess, location]);
  return (
    <form onSubmit={handleSubmit}>
      <Steps labelSteps={labelSteps} steps={steps} setSteps={setSteps} />
      <div className="w-full flex mt-5 relative">
        <Accordion
          title={<div className="p-2">Payroll Setup</div>}
          icons={[]}
          children={
            <div className="p-4 w-full grid grid-cols-2 gap-5">
              <div className="grid grid-cols-2 p-1 gap-4 w-full">
                <div className={"w-full"}>
                  <InputText
                    disabled
                    value={values.payrollLabel}
                    onChange={handleChange}
                    name={"payrollLabel"}
                    id={"payrollLabel"}
                    title={"Payroll Label *"}
                    className="w-full flex items-center gap-2"
                  />
                </div>
                <div className="w-full">
                  <InputDate
                    disabled
                    name={"paymentDate"}
                    id={"paymentDate"}
                    label="Payment Schedule *"
                    setFieldValue={setFieldValue}
                    value={values.paymentDate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>

                <div className={"w-full"}>
                  <InputDate
                    disabled
                    id={"startDate"}
                    name="startDate"
                    label="Start Date Period *"
                    setFieldValue={setFieldValue}
                    value={values.startDate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="w-full">
                  <InputDate
                    disabled
                    id={"endDate"}
                    name="endDate"
                    label="End Date Period"
                    setFieldValue={setFieldValue}
                    value={values.endDate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required={true}
                  />
                </div>

                <div className={"w-full mt-3"}>
                  <InputSelect
                    disabled
                    title={"Location *"}
                    id={"location"}
                    name="location"
                    setFieldValue={setFieldValue}
                    value={values.location}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={location}
                  />
                </div>
              </div>
              <div className={"grid grid-cols-2 gap-5 capitalize"}>
                <div
                  className={
                    "border p-4  rounded-md border-black h-[140px] flex flex-col justify-center"
                  }
                >
                  <div>Total Disbursements</div>
                  <div className="font-semibold text-2xl">
                    {formatRupiah(totalDeductions)}
                  </div>
                </div>
                <div
                  className={
                    "border p-4 rounded-md border-black h-[140px] flex flex-col justify-center"
                  }
                >
                  <div>employee</div>
                  <div className={"font-bold text-2xl"}>
                    {resultPayrollProcess?.data?.length} employess
                  </div>
                </div>
              </div>
            </div>
          }
        />
      </div>
      <div className="mt-5">
        <div className="p-4 w-full flex flex-col overflow-auto ">
          <div className={"font-bold capitalize"}>
            payroll calculation is completed
          </div>
          <div>
            Your data to run payroll has been successfully set with the details
            above
          </div>
          <div className={"font-bold"}>Here is your summary report</div>
          <div className={"flex gap-4 mt-5"}>
            <NavLink to={"/payroll-report"}>
              <Button
                className={"w-[200px] "}
                backgroundColor={"#DD7224"}
                label={
                  <div className="px-4 gap-1 text-white flex items-center capitalize">
                    <FileMinusSVG color={"#fff"} />
                    <div>payroll report</div>
                  </div>
                }
              />
            </NavLink>

            <NavLink to={"/employee-payslip"}>
              <Button
                className={"w-[200px] "}
                backgroundColor={"#DD7224"}
                label={
                  <div className="px-4 gap-1 text-white flex items-center capitalize">
                    <FileDockSearchSVG color={"#fff"} />
                    <div>Employee Payslip</div>
                  </div>
                }
              />
            </NavLink>
          </div>
        </div>
      </div>
    </form>
  );
};
export default Review;
