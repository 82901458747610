import { createContext } from "react";

export const IMAGE_PLACEHOLDER_PATH = "/placeholder.svg";

export const ContextReportAndPayslip = createContext({
  setDetail: (data) => data,
  detail: null,
});
export const ContextPayroll = createContext({
  setTotalDisbursements: (data) => data,
  totalDisbursements: 0,
});
