import React from "react";

export const TicketSVG = ({ color, ...otherProps }) => {
  return (
    <svg
      width="24"
      height="24"
      className={`w-6 h-6 ${otherProps.className}`}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.23311 3.33594V4.94927"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.23311 12.3438V13.6931"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.23311 10.0538V6.83984"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.4681 13.8346C13.6828 13.8346 14.6666 12.863 14.6666 11.6634V9.93506C13.8629 9.93506 13.2155 9.29577 13.2155 8.50199C13.2155 7.7082 13.8629 7.06823 14.6666 7.06823L14.666 5.33921C14.666 4.1396 13.6814 3.16797 12.4674 3.16797H3.53259C2.31857 3.16797 1.33401 4.1396 1.33401 5.33921L1.33331 7.12454C2.1371 7.12454 2.78443 7.7082 2.78443 8.50199C2.78443 9.29577 2.1371 9.93506 1.33331 9.93506V11.6634C1.33331 12.863 2.31718 13.8346 3.53189 13.8346H12.4681Z"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
