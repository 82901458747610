import React, { createContext, useContext, useEffect, useState } from "react";
import Setting from "components/payroll-run/setting";
import Review from "components/payroll-run/review";
import Process from "components/payroll-run/process";
import { useSearchParams } from "react-router-dom";
export const ContextSteps = createContext({
  steps: 1,
  setSteps: (data) => data,
});
const RunPayroll = () => {
  const [steps, setSteps] = useState(1);
  const [searchParams] = useSearchParams();

  const status = searchParams.get("status");
  const labelSteps = ["Payroll Settings", "Review", "Process"];
  useEffect(() => {
    if (!status) {
      setSteps(1);
    }
  }, [status]);
  return (
    <ContextSteps.Provider value={{ setSteps, steps }}>
      <div className="w-full flex-col mt-5 ">
        {steps === 1 && (
          <Setting labelSteps={labelSteps} steps={steps} setSteps={setSteps} />
        )}
        {steps === 2 && (
          <Review labelSteps={labelSteps} steps={steps} setSteps={setSteps} />
        )}
        {steps === 3 && (
          <Process labelSteps={labelSteps} steps={steps} setSteps={setSteps} />
        )}
      </div>
    </ContextSteps.Provider>
  );
};

export default RunPayroll;
