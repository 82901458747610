import React from "react";
import PropTypes from "prop-types";

const Index = ({ color, className, ...otherProps }) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.83317 1.33203H4.49984C4.14622 1.33203 3.80708 1.47251 3.55703 1.72256C3.30698 1.9726 3.1665 2.31174 3.1665 2.66536V13.332C3.1665 13.6857 3.30698 14.0248 3.55703 14.2748C3.80708 14.5249 4.14622 14.6654 4.49984 14.6654H12.4998C12.8535 14.6654 13.1926 14.5249 13.4426 14.2748C13.6927 14.0248 13.8332 13.6857 13.8332 13.332V5.33203L9.83317 1.33203Z"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.8335 1.33203V5.33203H13.8335"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.5 10H10.5"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

Index.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

Index.defaultProps = {
  color: "#111111",
  className: "",
};

export default Index;
