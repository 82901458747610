import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Button, TableMenu } from "@bluesilodev/timhutcomponents";
import { ModalDialog } from "@bluesilodev/timhutcomponents";
import UserAddIcon from "../../../assets/icons/UserAddSVG/UserAdd";
import { InputSelect, InputText } from "@bluesilodev/timhutcomponents";
import { useFormik } from "formik";
import { InputDate } from "@bluesilodev/timhutcomponents";
import CloseRoundSvg from "assets/icons/CloseRoundSvg/CloseRoundSvg";
import TimeSquareSvg from "assets/icons/TimeSquareSvg/TimeSquareSvg";
import { UploadImg1 } from "@bluesilodev/timhutcomponents";
import { RequestClaimsSchema } from "type/typePayroll";
import TrashSvg from "assets/icons/TrashSvg/TrashSvg";
import { formatRupiah, currencyToNumber } from "utils/numberUtils";
import { jwtDecode } from "jwt-decode";
import {
  claimsApi,
  useAddClaimsMutation,
  useDeleteClaimsMutation,
  useUpdateClaimsMutation,
  useUploadImageClaimsMutation,
} from "store/api/claimsApi";
import { parse } from "date-fns";
import { v7 } from "uuid";
import { useFetchClaimsTypeQuery } from "store/api/claimsTypeApi";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

/**
 * @function RequestClaimsModal
 * @param {{
 * status:'add'|'edit',
 * dataEdit:import("type/typePayroll").RequestClaims
 * }}
 */

export const RequestClaimsModal = ({ status, dataEdit }) => {
  const { currentUser, currentRole } = useSelector((state) => state.loginSlice);

  const [requestClaims, { isSuccess, isError, data: dataClaims }] =
    useAddClaimsMutation();
  const [requestUpdateClaims] = useUpdateClaimsMutation();
  const [requestDeleteClaims] = useDeleteClaimsMutation();
  const [requestUploadImageClaim] = useUploadImageClaimsMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  /** @type {User} */
  const user = currentUser;
  const [isShow, setIsShow] = useState(false);
  const [photo, setPhoto] = useState(null);
  const [loading, setLoading] = useState(false);
  const role = useMemo(() => {
    return currentRole || "employee";
  }, [currentRole]);

  const { data } = useFetchClaimsTypeQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const [openUrl, setOpenUrl] = useState("");
  const {
    handleBlur,
    handleChange,
    setFieldValue,
    values,
    handleSubmit,
    touched,
    errors,
    resetForm,
    setValues,
  } = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      userId: "",
      claimName: "",
      claimDate: "",
      description: "",
      totalClaim: "",
      isDeleted: false,
      status: {
        bySupervisor: "pending",
        byHr: "pending",
      },
      comments: "",
      attachments: "",
      claimDetails: [
        {
          claimTypeId: "",
          amount: "Rp 0",
          claimName: "",
        },
      ],
    },
    validationSchema: RequestClaimsSchema,
    /**
     * @constructor
     * @param {RequestClaims} values
     */
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("attachments", values?.attachments);
        const imageUpload = await requestUploadImageClaim(formData);
        values.attachments = imageUpload?.data?.data[0]?.Location;
        const parsedDate = parse(values?.claimDate, "dd/MM/yyyy", new Date());

        status === "edit"
          ? await requestUpdateClaims({
              ...values,
              id: values?._id,
              claimDate: parsedDate,
            })
          : await requestClaims({
              ...values,
              claimDate: parsedDate,
            });
        dispatch(claimsApi.util.invalidateTags(["claimFetch"]));
        setLoading(false);
        resetForm();
      } catch (e) {
        setLoading(false);
        console.error(e);
      }
    },
  });
  useEffect(() => {
    if (isSuccess) {
      navigate(`/detail/${dataClaims?.data?._id}`);
    }
  }, [isSuccess, isError, dataClaims]);
  const handleChangeImg = (event) => {
    const file = event.currentTarget.files[0];
    const createUrl = URL.createObjectURL(file);
    if (createUrl) {
      setOpenUrl(createUrl);
    }
    if (file) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        setPhoto(fileReader.result);
      };
      fileReader.readAsDataURL(file);
      setFieldValue("attachments", file);
    }
  };
  const handleDeleteImg = () => {
    setFieldValue("attachments", "");
  };
  const addClaims = () => {
    const temp = [...values.claimDetails];
    temp.push({ amount: "Rp 0", claimTypeId: "" });
    setFieldValue("claimDetails", temp);
  };
  const removeClaims = (i = 0) => {
    const temp = [...values.claimDetails];
    temp.splice(i, 1);
    setFieldValue("claimDetails", temp);
  };
  const deleteClaims = async (id) => {
    await requestDeleteClaims({ id: id });
    dispatch(claimsApi.util.invalidateTags(["claimFetch"]));
  };
  const optionsClaims = useMemo(() => {
    return data?.data?.map((d, i) => {
      return {
        label: d?.title,
        value: d?.uId,
      };
    });
  }, [data]);
  useEffect(() => {
    const totalAmount = values?.claimDetails?.reduce(
      (previousValue, currentValue) => {
        previousValue += parseInt(currencyToNumber(currentValue?.amount));
        return previousValue;
      },
      0,
    );
    setFieldValue("totalClaim", formatRupiah(totalAmount));
  }, [setFieldValue, values?.claimDetails]);
  useEffect(() => {
    if (isShow) {
      setFieldValue("userId", user?.uId);
      setFieldValue("uId", v7());
    } else {
      resetForm();
    }
  }, [user, isShow, setFieldValue]);
  useEffect(() => {
    setOpenUrl("");
    setPhoto(null);
    if (status === "edit" && dataEdit) {
      setValues({
        ...dataEdit,
        claimDate: dayjs(dataEdit.claimDate).format("DD/MM/YYYY"),
      });
    }
  }, [status, dataEdit]);
  return (
    <>
      {status === "edit" ? (
        <>
          {role?.toLocaleLowerCase() !== "admin" && (
            <TableMenu
              show={true}
              onEdit={() => {
                setIsShow(true);
              }}
              onDelete={() => {
                deleteClaims(dataEdit?._id);
              }}
            />
          )}
        </>
      ) : (
        <Button
          onClick={() => setIsShow(!isShow)}
          className={"w-[200px]"}
          backgroundColor={"#DD7224"}
          label={
            <div className="flex px-4 gap-1 text-white">
              <UserAddIcon color={"#fff"} />
              <div>Request Claims</div>
            </div>
          }
        />
      )}

      <ModalDialog
        show={isShow}
        className={"min-w-[1000px] w-[90vw] h-[90vh]"}
        onClose={() => setIsShow(false)}
        title={"Request Claims"}
        children={
          <div className={"flex flex-col gap-4  h-full"}>
            {values?.claimDetails.map((d, i) => {
              return (
                <div className={"grid grid-cols-3 gap-4"} key={i}>
                  <div>
                    <InputSelect
                      classname={"w-full"}
                      title={"Claims Items"}
                      // setFieldValue={setFieldValue}
                      value={d?.claimTypeId || ""}
                      onChange={(d) => {
                        const find = optionsClaims.find(
                          (d2) => d2?.value === d?.target?.value,
                        );
                        setFieldValue(
                          `claimDetails.${i}.claimTypeId`,
                          d?.target?.value,
                        );
                        setFieldValue(
                          `claimDetails.${i}.claimName`,
                          find?.label,
                        );
                      }}
                      onBlur={handleBlur}
                      name={`claimDetails.${i}.claimTypeId`}
                      id={`claimDetails.${i}.claimTypeId`}
                      options={optionsClaims}
                    />
                    {errors?.claimDetails?.length > 0 && (
                      <div className="text-red-400 mt-4 capitalize col-span-full text-center">
                        <div> {errors?.claimDetails[i]?.claimTypeId}</div>
                      </div>
                    )}
                  </div>
                  <div>
                    <InputText
                      value={d?.amount || ""}
                      name={`claimDetails.${i}.amount`}
                      id={`claimDetails.${i}.amount`}
                      onChange={(d) => {
                        setFieldValue(
                          `claimDetails.${i}.amount`,
                          formatRupiah(currencyToNumber(d?.target?.value)),
                        );
                      }}
                      title={"Amount *"}
                      className="w-full flex items-center gap-2"
                    />
                    {errors?.claimDetails?.length > 0 && (
                      <div className="text-red-400 mt-4 capitalize col-span-full text-center">
                        <div> {errors?.claimDetails[i]?.amount}</div>
                      </div>
                    )}
                  </div>

                  {i > 0 && (
                    <button
                      onClick={() => removeClaims(i)}
                      className={"rounded-full"}
                    >
                      <TrashSvg color={"#000"} />
                    </button>
                  )}
                </div>
              );
            })}
            <Button
              onClick={() => addClaims()}
              className={"w-full"}
              backgroundColor={"#DD7224"}
              label={
                <div className="flex p-4 gap-1 capitalize text-white">
                  <UserAddIcon color={"#fff"} />
                  <div>add more claims items</div>
                </div>
              }
            />
            <div className={"grid grid-cols-3 gap-4"}>
              <div>
                <InputText
                  value={values.claimName}
                  onChange={handleChange}
                  name={"claimName"}
                  id={"claimName"}
                  title={"Claims Name *"}
                  className="w-full flex items-center gap-2"
                />
                {errors.claimName && (
                  <div className="text-red-400 mt-4 capitalize">
                    {errors.claimName}
                  </div>
                )}
              </div>
              <div>
                <InputDate
                  name={"claimDate"}
                  id={"claimDate"}
                  label="Date *"
                  setFieldValue={setFieldValue}
                  value={values.claimDate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.claimDate && (
                  <div className="text-red-400 mt-6 capitalize">
                    {errors.claimDate}
                  </div>
                )}
              </div>
              <div>
                <InputText
                  value={values.totalClaim}
                  setFieldValue={setFieldValue}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name={"totalClaim"}
                  id={"totalClaim"}
                  disabled
                  title={"Amount *"}
                  className="w-full flex items-center gap-2"
                />
                {errors.totalClaim && (
                  <div className="text-red-400 mt-4 capitalize">
                    {errors.totalClaim}
                  </div>
                )}
              </div>

              <div className={"col-span-full"}>
                <InputText
                  value={values.label}
                  onChange={handleChange}
                  name={"description"}
                  id={"description"}
                  title={"Description *"}
                  className="w-full flex items-center gap-2 "
                />
                {errors.description && (
                  <div className="text-red-400 mt-4 capitalize">
                    {errors.description}
                  </div>
                )}
              </div>
              <div className={"col-span-full w-full"}>
                {openUrl && (
                  <a
                    className="font-bold"
                    href={openUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    View Image: {openUrl}
                  </a>
                )}
                {values?.attachments?.length > 0 && (
                  <a
                    className="font-bold"
                    href={values?.attachments}
                    target="_blank"
                    rel="noreferrer"
                  >
                    View Image: {values?.attachments}
                  </a>
                )}
              </div>
              <div className={"col-span-full w-full"}>
                <UploadImg1
                  textSizeLimit={
                    "For Best Resolution is 1366 x 1366px, Max upload image size is 2MB, Supported files: .jpg, .png "
                  }
                  title={"Attachments"}
                  id="attachments"
                  name={"attachments"}
                  image={values.attachments}
                  onChange={handleChangeImg}
                  handleDeleteImg={handleDeleteImg}
                  accept="image/jpeg, image/jpg, image/png"
                  onBlur={handleBlur}
                  error={
                    <div className="text-red-500">
                      {errors.photo && touched.photo && errors.photo}
                    </div>
                  }
                />
                {errors.attachments && (
                  <div className="text-red-400 mt-4 capitalize">
                    {errors.attachments}
                  </div>
                )}
              </div>
              <div className={"grid grid-cols-2 gap-4 col-span-full mb-4"}>
                <Button
                  onClick={() => setIsShow(false)}
                  className={"w-full"}
                  backgroundColor={"#fff"}
                  label={
                    <div className="flex p-4 gap-1 capitalize items-center text-secondary">
                      <CloseRoundSvg color={"#DD7224"} />
                      <div>Cancel</div>
                    </div>
                  }
                />
                <div className="relative">
                  {loading && (
                    <div
                      className="absolute top-0 right-0 bottom-0 left-0 bg-secondary z-10 
                  rounded-md p-4 text-white flex justify-center items-center"
                    >
                      <div>loading...</div>
                    </div>
                  )}
                  <Button
                    className={"w-full"}
                    onClick={() => handleSubmit()}
                    backgroundColor={"#DD7224"}
                    label={
                      <div className="flex p-4 gap-1 capitalize text-white items-center">
                        <TimeSquareSvg color={"#fff"} />
                        <div>request claims</div>
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        }
      />
    </>
  );
};
