import React from "react";

const RunPayrollSVG = ({ color, ...otherProps }) => {
  return (
    <svg
      width="24"
      height="24"
      className={`w-6 h-6 ${otherProps.className}`}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.82439 2.33986H5.38972C4.00306 2.33453 2.86706 3.4392 2.83372 4.82586V11.9839C2.80372 13.3852 3.91572 14.5452 5.31639 14.5752C5.34106 14.5752 5.36572 14.5759 5.38972 14.5752H10.7151C12.1084 14.5259 13.2097 13.3779 13.2017 11.9839V5.8572L9.82439 2.33986Z"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.6499 2.33203V4.27136C9.6499 5.21803 10.4159 5.98536 11.3626 5.98803H13.1986"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.76058 7.10547V11.1328"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.32436 8.67547L7.76103 7.10547L6.19769 8.67547"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default RunPayrollSVG;
