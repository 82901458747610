import React from "react";

const ClaimsTypeSVG = ({ color, ...otherProps }) => {
  return (
    <svg
      width="24"
      height="24"
      className={`w-6 h-6 ${otherProps.className}`}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.66669 1.83203H4.00002C3.6464 1.83203 3.30726 1.97251 3.05721 2.22256C2.80716 2.4726 2.66669 2.81174 2.66669 3.16536V13.832C2.66669 14.1857 2.80716 14.5248 3.05721 14.7748C3.30726 15.0249 3.6464 15.1654 4.00002 15.1654H12C12.3536 15.1654 12.6928 15.0249 12.9428 14.7748C13.1929 14.5248 13.3334 14.1857 13.3334 13.832V6.4987L8.66669 1.83203Z"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.66669 1.83203V6.4987H13.3334"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ClaimsTypeSVG;
