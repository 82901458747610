import * as Yup from "yup";

/**
 * @typedef {Object} ClaimType
 * @property {string|null} id - Unique identifier for the state.
 * @property {string} title - Title of the state.
 * @property {string} description - Description of the state.
 * @property {boolean} isActive - Indicates if the state is active.
 * @property {string} uId - User ID associated with the state.
 * @property {boolean} isEdit - Flag indicating whether the state is being edited.
 */

/**
 * Yup validation schema for the state object.
 * @type {Yup.ObjectSchema<ClaimType>}
 */
export const validationClaimType = Yup.object().shape({
  id: Yup.string(),
  title: Yup.string().required("Title is required"),
  description: Yup.string().required("Description is required"),
  isActive: Yup.boolean().required("isActive is required"),
  uId: Yup.string().required("User ID is required"),
  isEdit: Yup.boolean(),
});
