import React, { createContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  InputSelect,
  Pagination,
  SearchBox,
} from "@bluesilodev/timhutcomponents";
import UserAddIcon from "assets/icons/UserAddSVG/UserAdd";
import CalendarIconSVG from "assets/icons/CalendarIconSVG/CalendarIconSVG";
import CardWidget from "components/common/cardWidget";
import {
  PayrollTotalDataTable,
  TotalClaimsDataTable,
  UpcommingPaymentDataTable,
  PayrollSummaryDataTable,
} from "components/common/customTables";
import { RequestClaimsModal } from "components/payroll-run/modal/requestClaimsModal";
import { useFetchClaimsQuery } from "store/api/claimsApi";
import { useFetchEmployeeLocationQuery } from "store/api/employeeComponentApi";
import dayjs from "dayjs";
import { formatRupiah, currencyToNumber } from "utils/numberUtils";
import { InputDateRange } from "@bluesilodev/timhutcomponents";
import { useFormik } from "formik";
import { ContextPayroll } from "utils/constants";
import {
  useFetchPayrollQuery,
  useGenerateExcelMutation,
} from "store/api/payrollApi";
const Payroll = () => {
  const { currentUser, currentRole } = useSelector((state) => state.loginSlice);
  const [search, setSearch] = useState("");

  /**
   * Information detail user
   *  @type {User} */
  const user = useMemo(() => {
    return currentUser;
  }, [currentUser]);
  const role = useMemo(() => {
    return currentRole || "employee";
  }, [currentRole]);

  /**
   * Option Department
   * @namespace
   * @typedef {Object} TypeOptionDepartment
   * @property {string} label
   * @property {string} value
   * */
  /**
   * @type {ReturnType<typeof useState<TypeOptionDepartment[]>>}
   * */
  const [optionDepartment, setOptionDepartment] = useState([]);

  const [isEnter, setEnter] = useState("#DD7224");
  /**
   * @typedef {import("type/typePayroll").RequestClaims} TypeRequestClaims
   *
   * Information a variable claims.
   * @typedef {Object} Claim
   * @property {Object} data
   * @property {TypeRequestClaims[]} data.data
   *
   * */

  /**
   * @typedef {Object} TypeValues
   * @property {string} rangeDate
   * @property {string} location
   * @property {string} department
   *
   * Register a variable Payroll.
   * @typedef {Object} FormikJsDoc
   * @property {TypeValues} values - Object containing the current form values.
   *
   * */
  /**
   * @type {FormikJsDoc}
   */
  const { handleBlur, handleChange, setFieldValue, values } = useFormik({
    initialValues: {},
  });
  const [rangeDate, setRangeDate] = useState(null);
  const [paginate, setPaginate] = useState({
    totalData: 0,
    rowsPerPage: 10,
    currentPage: 1,
  });

  const parseLocation = useMemo(() => {
    setFieldValue("department", null);
    return values?.location ? JSON.parse(values?.location) : null;
  }, [values?.location]);
  const [tabs, setTabs] = useState(
    role.toLowerCase() === "employee" ? "T_C" : "T_D",
  );
  const [totalDisbursements, setTotalDisbursements] = useState(0);
  const valuesRequest = useMemo(() => {
    let result = {
      startDate: rangeDate?.startDate,
      endDate: rangeDate?.endDate,
      location: parseLocation?.locationName,
      department: values?.department,
      limit: paginate.rowsPerPage,
      search,
    };
    Object.assign(
      result,
      tabs === "C_A"
        ? {
            bySupervisor: "approved",
            byHr: "approved",
          }
        : {},
    );
    Object.assign(
      result,
      tabs === "U_PC"
        ? {
            startDate: dayjs().add(1, "month").startOf("month").toDate(),
            endDate: dayjs().add(1, "month").endOf("month").toDate(),
          }
        : {},
    );
    if (role?.toLowerCase() === "employee") {
      Object.assign(result, {
        userId: user?.uId,
      });
    }
    result = Object.keys(result).reduce((previousValue, currentValue) => {
      if (result[currentValue]) {
        previousValue[currentValue] = result[currentValue];
      }

      return previousValue;
    }, {});

    return result;
  }, [
    rangeDate?.startDate,
    rangeDate?.endDate,
    parseLocation?.locationName,
    values?.department,
    paginate.rowsPerPage,
    search,
    tabs,
    role,
    user?.uId,
  ]);
  /**
   * @type {Claim}
   */
  const { data } = useFetchClaimsQuery(
    {
      ...valuesRequest,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  /**
   * Detail data location
   * @typedef {Object} GetLocation
   * @property {Object} data
   * @property {DataLocationEmployee[]} data.data
   * */

  /**
   * @type {GetLocation}
   */
  const getLocation = useFetchEmployeeLocationQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  const exportToExcel = async (dataExcel) => {
    try {
      // send data to server
      const responseData = await requestExcel(dataExcel);
      const url = window.URL.createObjectURL(responseData?.data);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Timhut_payroll.xlsx";
      document.body.appendChild(a);
      a?.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      // ask to user for choice location directory
      /*const fileHandle = await window?.showSaveFilePicker({
        suggestedName: "Timhut_payroll.xlsx",
        types: [
          {
            description: "Excel files",
            accept: {
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                [".xlsx"],
            },
          },
        ],
      });
      // write data to file you choice
      const writable = await fileHandle.createWritable();
      await writable.write(responseData?.data); // write blob to the file
      await writable.close();*/
    } catch (error) {
      console.log("tes", error);
      return;
    }
  };
  /**
   * @type {[import("type/typePayroll").Claim, React.Dispatch<import("type/typePayroll").Claim>]}
   * */
  const [dataClaims, setDataClaims] = useState([]);
  const [total, setTotal] = useState(0);
  const [totalApprovalClaims, setTotalApprovalClaims] = useState(0);

  const [requestExcel] = useGenerateExcelMutation();
  const dataLocationEmployee = useMemo(() => {
    const result = getLocation?.data?.data?.map((d) => {
      return {
        label: d?.locationName,
        value: JSON.stringify(d),
      };
    });
    return result || [];
  }, [getLocation]);

  const funClaimRequest = () => {
    setPaginate({
      currentPage: data?.current,
      totalData: data?.totalData,
      rowsPerPage: 10,
    });

    const result = data?.data?.map((d) => {
      /**
       * Detail information employee
       * @type {Employee}
       * */

      return {
        allData: d,
        name: `${d?.user?.firstName} ${d?.user?.lastName}`,
        img: d?.user?.photo[0]?.link || "",
        employeeId: user.employeeID,
        position: d?.user?.userInformation?.employeementDetail?.jobPosition,
        requestDate: dayjs(d.claimDate).format("DD MMMM YYYY"),
        claimName: d.claimName,
        totalClaim: d.totalClaim,
        bySupervisor: d?.status?.bySupervisor,
        byHRAdmin: d?.status?.byHr,
      };
    });

    setDataClaims(result);
    // total pay
    const totalPay = result?.reduce((pre, value) => {
      pre += currencyToNumber(value?.totalClaim) || 0;
      return pre;
    }, 0);
    // claims approval
    const totalClaims = result?.reduce((pre, value) => {
      pre +=
        value?.bySupervisor !== "approved" || value?.byHRAdmin !== "approved"
          ? 0
          : 1;
      return pre;
    }, 0);
    setTotal(totalPay || 0);
    setTotalApprovalClaims(totalClaims || 0);
  };

  useEffect(() => {
    funClaimRequest();
  }, [data?.data, user]);
  useEffect(() => {
    if (values?.rangeDate) {
      setRangeDate({
        startDate:
          values?.rangeDate?.length > 0 ? values?.rangeDate[0] : new Date(),
        endDate:
          values?.rangeDate?.length > 0
            ? values?.rangeDate[values?.rangeDate?.length - 1]
            : new Date(),
      });
    }
  }, [values?.rangeDate]);
  useEffect(() => {
    if (!values?.location) {
      return;
    }

    const parseValues = JSON.parse(values?.location);
    if (parseValues?.departments?.length > 0) {
      setOptionDepartment(
        parseValues?.departments?.map((d) => ({
          label: d?.department,
          value: d?.department,
        })),
      );
    } else {
      setOptionDepartment([]);
    }
  }, [values?.location]);
  // totalDisbursements

  const { data: dataDisbursement } = useFetchPayrollQuery(valuesRequest, {
    refetchOnMountOrArgChange: true,
  });
  useEffect(() => {
    if (dataDisbursement?.data?.length > 0) {
      const total = dataDisbursement?.data?.reduce(
        (totalAmount, disbursement) =>
          totalAmount +
          disbursement?.data.reduce(
            (subTotal, item) => subTotal + item?.total,
            0,
          ),
        0,
      );

      setTotalDisbursements(total);
    }
  }, [dataDisbursement?.data]);

  return (
    <div className="h-max w-full mt-5 space-y-5">
      <div className="w-full flex justify-between gap-5">
        <div
          className={`${
            role.toLowerCase() === "employee" ? "grid-cols-2" : "grid-cols-3"
          } grid   gap-5 justify-between  `}
        >
          <div>
            <InputDateRange
              name={"rangeDate"}
              id={"rangeDate"}
              label="Time Range *"
              setFieldValue={setFieldValue}
              value={values.rangeDate}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <InputSelect
            classname={"w-[200px]"}
            name={"location"}
            id={"location"}
            title={"Location"}
            setFieldValue={setFieldValue}
            value={values.location}
            onChange={handleChange}
            onBlur={handleBlur}
            options={dataLocationEmployee}
          />
          <InputSelect
            title={"Department"}
            classname={"w-[200px]"}
            options={optionDepartment}
            name={"department"}
            id={"department"}
            disabled={optionDepartment.length === 0}
            setFieldValue={setFieldValue}
            value={values.department}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>

        <div className="flex flex-row gap-5">
          <SearchBox
            onChange={(d) => {
              setSearch(d?.target?.value);
            }}
            className={"w-[308px] h-fit"}
            placeholder="Search"
          />
          <Button
            className={"w-[200px]"}
            onMouseEnter={() => {
              setEnter("white");
            }}
            onMouseLeave={() => {
              setEnter("#DD7224");
            }}
            onClick={() =>
              tabs === "T_C"
                ? exportToExcel(dataClaims)
                : exportToExcel(dataDisbursement?.data)
            }
            label={
              <div className="flex px-4 gap-1">
                <UserAddIcon color={isEnter} />
                <div>Export</div>
              </div>
            }
          />
          {role.toLowerCase() === "employee" && <RequestClaimsModal />}
        </div>
      </div>
      <div className="mt-5 inline-flex gap-4 ">
        {role.toLowerCase() === "employee" ? (
          <>
            <CardWidget
              icon={<CalendarIconSVG color="orange" />}
              title={"Total Claims"}
              cardData={formatRupiah(total)}
              handleClick={() => setTabs("T_C")}
              isActive={tabs === "T_C" ?? true}
            />
            <CardWidget
              icon={<CalendarIconSVG color="orange" />}
              title={"Claims Approval"}
              cardData={`${totalApprovalClaims} Claims`}
              handleClick={() => setTabs("C_A")}
              isActive={tabs === "C_A" ?? true}
            />
          </>
        ) : (
          <>
            <CardWidget
              icon={<CalendarIconSVG color="orange" />}
              title={"Total Disbursements"}
              cardData={formatRupiah(totalDisbursements)}
              handleClick={() => setTabs("T_D")}
              isActive={tabs === "T_D" ?? true}
            />
            <CardWidget
              icon={<CalendarIconSVG color="orange" />}
              title={"Total Claims"}
              cardData={formatRupiah(total)}
              handleClick={() => setTabs("T_C")}
              isActive={tabs === "T_C" ?? true}
            />
            <CardWidget
              icon={<CalendarIconSVG color="orange" />}
              title={"Claims Approval"}
              cardData={`${totalApprovalClaims} Claims`}
              handleClick={() => setTabs("C_A")}
              isActive={tabs === "C_A" ?? true}
            />
            <CardWidget
              icon={<CalendarIconSVG color="orange" />}
              title={"Upcoming Payment Schedule"}
              cardData={dayjs()
                .add(1, "month")
                .startOf("month")
                .format("DD MMMM YYYY")}
              handleClick={() => setTabs("U_PC")}
              isActive={tabs === "U_PC" ?? true}
            />
            <CardWidget
              icon={<CalendarIconSVG color="orange" />}
              title={"Payroll Summary"}
              cardData={"Dashboard"}
              handleClick={() => setTabs("P_S")}
              isActive={tabs === "P_S" ?? true}
            />
          </>
        )}
      </div>
      <ContextPayroll.Provider
        value={{ setTotalDisbursements, totalDisbursements }}
      >
        <div>
          {role !== "Employee" && tabs === "T_D" ? (
            <>
              <div className={"w-full "}>
                <div>
                  <PayrollTotalDataTable
                    dataTable={dataDisbursement?.data || []}
                  />
                </div>
              </div>
              <div className={"w-[100%]"}>
                <Pagination
                  totalData={paginate.totalData}
                  rowsPerPage={paginate?.rowsPerPage}
                  currentPage={paginate?.currentPage}
                  onChangeCurrentPage={(val) =>
                    setPaginate((oldData) => ({
                      ...oldData,
                      currentPage: val + 1,
                    }))
                  }
                  onChangeRowsPerPage={(val) =>
                    setPaginate((oldData) => ({ ...oldData, rowsPerPage: val }))
                  }
                />
              </div>
            </>
          ) : tabs === "T_C" ? (
            <>
              <div className={"w-full overflow-auto"}>
                <div className={"w-[100%]"}>
                  <TotalClaimsDataTable dataTable={dataClaims || []} />
                </div>
              </div>
              <div className={"w-[100%]"}>
                <Pagination
                  totalData={paginate.totalData}
                  rowsPerPage={paginate?.rowsPerPage}
                  currentPage={paginate?.currentPage}
                  onChangeCurrentPage={(val) =>
                    setPaginate((oldData) => ({
                      ...oldData,
                      currentPage: val + 1,
                    }))
                  }
                  onChangeRowsPerPage={(val) =>
                    setPaginate((oldData) => ({ ...oldData, rowsPerPage: val }))
                  }
                />
              </div>
            </>
          ) : tabs === "C_A" ? (
            <>
              <div className={"w-full overflow-auto"}>
                <div className={"w-[120%]"}>
                  <TotalClaimsDataTable dataTable={dataClaims || []} />
                </div>
              </div>
              <div className={"w-[100%]"}>
                <Pagination
                  totalData={paginate.totalData}
                  rowsPerPage={paginate?.rowsPerPage}
                  currentPage={paginate?.currentPage}
                  onChangeCurrentPage={(val) =>
                    setPaginate((oldData) => ({
                      ...oldData,
                      currentPage: val + 1,
                    }))
                  }
                  onChangeRowsPerPage={(val) =>
                    setPaginate((oldData) => ({ ...oldData, rowsPerPage: val }))
                  }
                />
              </div>
            </>
          ) : role !== "Employee" && tabs === "U_PC" ? (
            <div className={"w-full overflow-auto"}>
              <div className={"w-[120%]"}>
                <PayrollTotalDataTable
                  dataTable={dataDisbursement?.data || []}
                />
              </div>
            </div>
          ) : role !== "Employee" && tabs === "P_S" ? (
            <PayrollSummaryDataTable
              dataTable={{
                label: "2023_8_Halim Cafe",
                paymentSchedule: "1 September 2023",
                startDate: "1 August 2023",
                endDate: "31 August 2023",
                location: "Halim Cafe",
                totalEmployee: "8 Employees",
                totalPay: "Rp 46.949.496",
                status: "Draft",
                uId: "12345",
              }}
            />
          ) : (
            ""
          )}
        </div>
      </ContextPayroll.Provider>
    </div>
  );
};

export default Payroll;
