import React from "react";

const TickCircleSVG = ({ color }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_5995_44947)">
        <path
          d="M14.6666 7.38527V7.99861C14.6658 9.43622 14.2003 10.8351 13.3395 11.9865C12.4787 13.1379 11.2688 13.9803 9.89016 14.3879C8.51154 14.7955 7.03809 14.7465 5.68957 14.2483C4.34104 13.7501 3.18969 12.8293 2.40723 11.6233C1.62476 10.4173 1.25311 8.99065 1.3477 7.55615C1.44229 6.12165 1.99806 4.75616 2.93211 3.66332C3.86615 2.57049 5.12844 1.80886 6.53071 1.49204C7.93298 1.17521 9.4001 1.32017 10.7133 1.90527"
          stroke={color}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.6667 2.66797L8 9.3413L6 7.3413"
          stroke={color}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5995_44947">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TickCircleSVG;
