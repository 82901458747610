import React, { createContext, useEffect, useMemo, useState } from "react";
import { ReviewEmployeeDataTable } from "../common/customTables";
import {
  Accordion,
  InputDate,
  InputSelect,
  InputText,
} from "@bluesilodev/timhutcomponents";
import { useFormik } from "formik";
import Steps from "./steps";
import { useDispatch, useSelector } from "react-redux";
import { formatRupiah, findPartPercentage } from "utils/numberUtils";
import {
  useCreatePayrollMutation,
  useFetchPayrollOptionMutation,
  useGeneratePayrollRunnerMutation,
  useUpdatePayrollMutation,
} from "store/api/payrollApi";
import { parse } from "date-fns";
import { payrollSchema } from "type/typePayroll";
import {
  setPayrollProcessReducers,
  setPayrollReducers,
} from "store/slice/payrollSlice";
import { useNavigate, useParams } from "react-router-dom";
export const ContextReview = createContext({
  setAmount: (data) => data,
  total: 0,
  amount: [],
  setAdditionalEarning: (data) => data,
  setTotalPayroll: (data) => data,
  setAdditionalComponents: (data) => data,
  approvedOOT: [],
  additionalEarning: [],
  deduction: [],
  totalPayroll: [],
  additionalComponents: [],
  dataEdit: [],
});
const Review = ({ setSteps, steps, labelSteps }) => {
  const { payrollSlice } = useSelector((state) => state);
  const [amount, setAmount] = useState([]);
  const [requestPayroll, resultPayroll] = useCreatePayrollMutation();
  const [requestUpdatePayroll, resulUpdatePayroll] = useUpdatePayrollMutation();
  const location = payrollSlice?.dataLocationEmployee?.payload;
  const [approvedOOT, setApprovedOOT] = useState([]);
  const [additionalEarning, setAdditionalEarning] = useState([]);
  const [deduction, setDeduction] = useState([]);
  const [totalPayroll, setTotalPayroll] = useState([]);
  const [additionalComponents, setAdditionalComponents] = useState([]);
  const [dataEdit, setDataEdit] = useState([]);
  const [status, setStatus] = useState("draft");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [requestGeneratePayroll, { data }] = useGeneratePayrollRunnerMutation();
  const [requestPayrollOptional] = useFetchPayrollOptionMutation();

  const {
    handleBlur,
    handleChange,
    setFieldValue,
    values,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: {
      selectedEmployees: [],
    },
    onSubmit: async (values) => {
      try {
        /**
         * Yup validation schema for Payroll.
         * @type {import("type/typePayroll").Payroll}
         */
        const parseValues = { ...values };

        delete parseValues.selectedEmployees;
        setLoading(true);
        parseValues.startDate = parse(
          parseValues.startDate,
          "dd/MM/yyyy",
          new Date(),
        ).toString();
        parseValues.paymentDate = parse(
          parseValues.paymentDate,
          "dd/MM/yyyy",
          new Date(),
        ).toString();
        parseValues.endDate = parse(
          parseValues.endDate,
          "dd/MM/yyyy",
          new Date(),
        ).toString();
        parseValues.status = status;
        const findLocation = location?.find(
          (d) => d?.value === parseValues?.location,
        );
        const userIds = values.selectedEmployees?.map((d) => d?.uId);

        const result = {
          ...parseValues,
          location: { name: findLocation?.label, id: parseValues?.location },
          appendAdditionalOptional: additionalComponents?.flat(),
          userIds: userIds,
        };
        await payrollSchema.validateSync(result);
        setLoading(false);
        let data = null;
        if (id) {
          data = await requestUpdatePayroll({ id: id, ...result });
          dispatch(setPayrollProcessReducers(data));
        } else {
          data = await requestPayroll(result);
          dispatch(setPayrollProcessReducers(data));
        }
        setLoading(false);
        if (id) {
          navigate(`/run-payroll/${id}?status=success`);
        } else {
          navigate("/run-payroll?status=success");
        }
      } catch (error) {
        console.warn("payroll", error);
      }
    },
  });
  const totalDeductions = useMemo(() => {
    return data?.data?.reduce((pre, v) => {
      pre += v?.total;
      return pre;
    }, 0);
  }, [data?.data]);
  useEffect(() => {
    if (resultPayroll?.isSuccess) {
      setSteps((prev) => prev + 1);
      resetForm();
    }
  }, [resultPayroll]);
  useEffect(() => {
    if (resulUpdatePayroll?.isSuccess) {
      setSteps((prev) => prev + 1);
      resetForm();
    }
  }, [resulUpdatePayroll]);

  useEffect(() => {
    const result = payrollSlice?.payrollSetting?.payload;
    if (result?.startDate) {
      resetForm({ values: result });
    }
  }, [payrollSlice?.payrollSetting?.payload]);
  const calculatePayroll = async () => {
    // Reset state before processing
    const userIds = values.selectedEmployees?.map((d) => d?.uId);
    requestGeneratePayroll({
      userIds: userIds,
      startDate: parse(values.startDate, "dd/MM/yyyy", new Date()).toString(),
      endDate: parse(values.endDate, "dd/MM/yyyy", new Date()).toString(),
      appendAdditionalOptional: additionalComponents?.flat() || [],
    });
  };
  useEffect(() => {
    if (values?.selectedEmployees.length > 0) {
      calculatePayroll();
    }
  }, [
    values?.selectedEmployees,
    values.startDate,
    values.endDate,
    additionalComponents,
  ]);
  useEffect(() => {
    setAdditionalEarning((pre) => {
      let tempData = pre;
      values?.selectedEmployees.forEach((e, i) => {
        tempData[i] = [];
      });
      return tempData;
    });
  }, [values?.selectedEmployees]);
  useEffect(() => {
    if (id) {
      requestPayrollOptional({ id }).then((r) => {
        const data = r?.data?.data[0];

        let additional = data?.data?.reduce((pre, v, i) => {
          const basicSalary =
            v?.user?.userInformation?.paymentDetail?.basicSalary;
          const deductions = v?.deductions?.data
            ?.filter(
              (d) =>
                d?.status === "deduction-fixed" ||
                d?.status === "deduction-percent",
            )
            ?.map((d) => {
              return {
                idUser: v?.userId,
                type: d?.status?.split("-")[0],
                status: d?.status?.split("-")[1],
                name: d?.label,
                amount:
                  d?.status?.split("-")[1] === "percent"
                    ? findPartPercentage(d?.price, basicSalary)
                    : d?.price,
              };
            });
          const approvedEarning = v?.approvedEarning?.data
            ?.filter(
              (d) =>
                d?.status === "earning-fixed" ||
                d?.status === "earning-percent",
            )
            ?.map((d) => {
              return {
                idUser: v?.userId,
                type: d?.status?.split("-")[0],
                status: d?.status?.split("-")[1],
                name: d?.label,
                amount:
                  d?.status?.split("-")[1] === "percent"
                    ? findPartPercentage(d?.price, basicSalary)
                    : d?.price,
              };
            });
          const merge = approvedEarning.concat(deductions);

          /*  merge?.forEach((element) => {
            pre.push(element);
          }); */
          pre[i] = merge;
          return pre;
        }, []);
        if (additional?.length > 0) {
          setAdditionalComponents(additional);
        }
      });
    }
  }, [id, requestPayrollOptional, location]);
  return (
    <div>
      <Steps
        loading={loading}
        labelSteps={labelSteps}
        steps={steps}
        setSteps={setSteps}
        handleSubmit={handleSubmit}
        setStatus={setStatus}
      />

      <div className="w-full flex mt-5 ">
        <Accordion
          title={<div className="p-2">Payroll Setup</div>}
          icons={[]}
          children={
            <div className="p-4 w-full grid grid-cols-2 gap-5">
              <div className="grid grid-cols-2 p-1 gap-4 w-full">
                <div className={"w-full"}>
                  <InputText
                    disabled
                    value={values.label}
                    onChange={handleChange}
                    name={"label"}
                    id={"label"}
                    title={"Payroll Label *"}
                    className="w-full flex items-center gap-2"
                  />
                </div>

                <div className="w-full">
                  <InputDate
                    disabled
                    name={"paymentDate"}
                    id={"paymentDate"}
                    label="Payment Schedule *"
                    setFieldValue={setFieldValue}
                    value={values.paymentDate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>

                <div className={"w-full"}>
                  <InputDate
                    disabled
                    id={"startDate"}
                    name="startDate"
                    label="Start Date Period *"
                    setFieldValue={setFieldValue}
                    value={values.startDate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="w-full">
                  <InputDate
                    disabled
                    id={"endDate"}
                    name="endDate"
                    label="End Date Period"
                    setFieldValue={setFieldValue}
                    value={values.endDate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required={true}
                  />
                </div>

                <div className={"w-full mt-3"}>
                  <InputSelect
                    disabled
                    title={"Location *"}
                    id={"location"}
                    name="location"
                    setFieldValue={setFieldValue}
                    value={values.location}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={location}
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div className="capitalize grid grid-rows-[1fr_auto] h-[100px] border border-black rounded-md p-4">
                  <div>Total Disbursements</div>
                  <div className="font-semibold text-2xl">
                    {formatRupiah(totalDeductions)}
                  </div>
                </div>
                <div className="capitalize grid grid-rows-[1fr_auto] h-[100px] border border-black rounded-md p-4">
                  <div>employee</div>
                  <div className="font-semibold text-2xl">
                    {values?.selectedEmployees?.length} employee
                  </div>
                </div>
              </div>
            </div>
          }
        />
      </div>
      <div className="mt-5">
        <ContextReview.Provider
          value={{
            amount,
            setAmount,
            setApprovedOOT,
            approvedOOT,
            additionalEarning,
            setAdditionalEarning,
            deduction,
            setDeduction,
            totalPayroll,
            setTotalPayroll,
            additionalComponents,
            setAdditionalComponents,
            dataEdit,
            setDataEdit,
          }}
        >
          <Accordion
            title={<div className="p-2">Employee</div>}
            icons={[]}
            children={
              <div className="p-4 w-full flex flex-col overflow-auto ">
                <ReviewEmployeeDataTable dataTable={data?.data || []} />
              </div>
            }
          />
        </ContextReview.Provider>
      </div>
    </div>
  );
};
export default Review;
