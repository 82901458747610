import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Accordion,
  InputText,
  InputDate,
  ToggleSwitch,
  CheckBox,
} from "@bluesilodev/timhutcomponents";
import SaveIconSVG from "assets/icons/SaveIconSVG/SaveIconSVG";

import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useAlertApi } from "services/alert-api";
import { ArrowRightSVG } from "assets/icons/ArrowRightSVG";
import { EqualSVG } from "assets/icons/EqualSVG";
import TrashSvg from "assets/icons/TrashSvg/TrashSvg";
import UserAddIcon from "assets/icons/UserAddSVG/UserAdd";
import { currencyToNumber, formatRupiah } from "utils/numberUtils";
import { parse } from "date-fns";
import dayjs from "dayjs";
import { validationSchemaSalaryCeilings } from "type/typeSalaryCeilings";
import {
  useAddSalaryCeilingsMutation,
  useUpdateSalaryCeilingsMutation,
} from "store/api/salaryCeilingsApi";
/**
 * Renders a component to manage and display settings for gross wages and minimum contributions.
 *
 * @param {Object} params - The parameters for the function.
 * @param {number} params.i - The index of the current item in the list.
 * @param {Object} params.d - The data object containing values for gross wages and minimum contributions.
 * @param {Object} params.errors - The errors object for form validation.
 * @param {Function} params.setFieldValue - The function to set form field values.
 * @param {Object} params.values - The current values of the form.
 * @param {string} [params.name="salaryCeilingList"] - The base name for the form field names.
 * @param {string} [params.nameSelect="salaryCeilingMaxSelect"] - The name of the checkbox field to select maximum value.
 * @param {Function} params.removeShg - The function to remove an item from the list.
 * @param {Object[]} params.salaryCeilingList - The data object list.
 *
 * @returns {JSX.Element} The rendered component.
 */
const ComList = ({
  i,
  d,
  errors,
  setFieldValue,
  values,
  name = "salaryCeilingList",
  nameSelect = "salaryCeilingMaxSelect",
  removeShg,
}) => {
  return (
    <div key={`${i}-${name}`} className="my-5 flex gap-4 items-center">
      <InputDate
        name={`${name}.${i}.fromDate`}
        id={`${name}.${i}.fromDate`}
        label="From (Date)"
        setFieldValue={setFieldValue}
        value={d.fromDate}
        disabled={i > 0}
        error={errors.fromDate}
        onChange={(event) => {
          const value = event.target.value || "";
          setFieldValue(`${name}.${i}.fromDate`, value);
        }}
      />
      <div className="border rounded-full p-4 h-[30px] w-[30px] flex items-center justify-center">
        <div>
          <ArrowRightSVG color={"#000"} />
        </div>
      </div>
      {values[nameSelect] && i === values[name]?.length - 1 ? (
        <InputText value="Max" disabled title="To (Date)" />
      ) : (
        <InputDate
          value={d.toDate}
          error={errors.toDate}
          setFieldValue={setFieldValue}
          disabled={d.toDate > 0}
          onChange={(event) => {
            const value = event.target.value || "";
            setFieldValue(`${name}.${i}.toDate`, value);
          }}
          name={`${name}.${i}.toDate`}
          id={`${name}.${i}.toDate`}
          label="To (Date)"
        />
      )}
      <div className="border rounded-full p-4 h-[30px] w-[30px] flex items-center justify-center">
        <div>
          <EqualSVG color={"#000"} />
        </div>
      </div>
      <InputText
        value={d.CPFOrdinaryWageCeiling}
        error={errors.CPFOrdinaryWageCeiling}
        onChange={(event) => {
          let value = currencyToNumber(event.target.value);
          value = formatRupiah(value);
          setFieldValue(`${name}.${i}.CPFOrdinaryWageCeiling`, value || "0");
        }}
        name={`${name}.${i}.CPFOrdinaryWageCeiling`}
        id={`${name}.${i}.CPFOrdinaryWageCeiling`}
        title="CPF Ordinary Wage Ceiling"
      />
      <InputText
        value={d.CPFAnnualSalaryCeiling}
        error={errors.CPFAnnualSalaryCeiling}
        onChange={(event) => {
          let value = currencyToNumber(event.target.value);
          value = formatRupiah(value);
          setFieldValue(`${name}.${i}.CPFAnnualSalaryCeiling`, value || "0");
        }}
        name={`${name}.${i}.CPFAnnualSalaryCeiling`}
        id={`${name}.${i}.CPFAnnualSalaryCeiling`}
        title="CPF Annual Salary Ceiling"
      />
      <div className="flex min-w-[200px] gap-4">
        {i === values[name].length - 1 && (
          <div className="min-w-[110px]">
            <CheckBox
              onChange={() => {
                setFieldValue(nameSelect, !values[nameSelect]);
              }}
              key={nameSelect}
              value={values[nameSelect]}
              label="Set To Max"
            />
          </div>
        )}
        {i > 0 && (
          <button
            onClick={() => removeShg(i, name)}
            className="rounded-full w-[200px]"
          >
            <TrashSvg color="#000" />
          </button>
        )}
      </div>
    </div>
  );
};

const AddEditShg = () => {
  const location = useLocation();
  const { state } = location;

  const params = useParams();
  /**
   * @typedef {Object} PayrollShgSchema
   * @property {import("type/typeCpf").PayrollShgSchema} data
   */
  /**
   * @type {PayrollShgSchema}
   */
  const shg = useSelector((state) => state.cpfbSlice);
  const [request, result] = useAddSalaryCeilingsMutation();
  const [requestUpdate, resultUpdate] = useUpdateSalaryCeilingsMutation();

  const navigate = useNavigate();

  const {
    handleChange,
    setFieldValue,
    values,
    handleSubmit,
    errors,
    setValues,
    handleBlur,
  } = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      salaryCeilingName: "",
      salaryCeilingStartDate: "",
      salaryCeilingEndDate: "",
      salaryCeilingStatus: false,
      salaryCeilingMaxSelect: false,
      salaryCeilingList: [
        {
          fromDate: dayjs().startOf("year").format("DD/MM/YYYY"),
          toDate: dayjs().add(1, "year").startOf("year").format("DD/MM/YYYY"),
          CPFOrdinaryWageCeiling: 0,
          CPFAnnualSalaryCeiling: 0,
        },
        {
          fromDate: dayjs().add(1, "year").startOf("year").format("DD/MM/YYYY"),
          toDate: dayjs().add(2, "year").startOf("year").format("DD/MM/YYYY"),
          CPFOrdinaryWageCeiling: 0,
          CPFAnnualSalaryCeiling: 0,
        },
      ],
    },
    validationSchema: validationSchemaSalaryCeilings,
    onSubmit: async (values) => {
      try {
        const parseValues = { ...values };

        if (
          parseValues?.__v !== "" &&
          parseValues?.__v !== undefined &&
          params?.id
        ) {
          delete parseValues.__v;
        }
        if (parseValues?.createdAt && params?.id) {
          delete parseValues.createdAt;
        }
        if (parseValues?.updatedAt && params?.id) {
          delete parseValues.updatedAt;
        }
        parseValues.salaryCeilingStartDate = parse(
          parseValues?.salaryCeilingStartDate,
          "dd/MM/yyyy",
          new Date(),
        );
        parseValues.salaryCeilingEndDate = parse(
          parseValues?.salaryCeilingEndDate,
          "dd/MM/yyyy",
          new Date(),
        );
        const settingsKeys = ["salaryCeilingList"];
        settingsKeys.forEach((key) => {
          parseValues[key] = parseValues[key].map((d) => ({
            CPFOrdinaryWageCeiling: parseFloat(
              currencyToNumber(d.CPFOrdinaryWageCeiling),
            ),
            CPFAnnualSalaryCeiling: parseFloat(
              currencyToNumber(d.CPFAnnualSalaryCeiling),
            ),
            fromDate: parse(d?.fromDate, "dd/MM/yyyy", new Date()),
            toDate: parse(d?.toDate, "dd/MM/yyyy", new Date()),
          }));
        });

        params?.id
          ? await requestUpdate({ id: params?.id, ...parseValues })
          : await request(parseValues);
        navigate("/salary-ceilings");
      } catch (error) {
        console.error("error", error);
      }
    },
  });
  /**
   * @param {'salaryCeilingList' } status
   */
  const addShg = (status) => {
    switch (status) {
      case "salaryCeilingList":
        {
          const length = values.salaryCeilingList?.length;
          const temp = [...values.salaryCeilingList];
          temp.push({
            fromDate: dayjs(
              parse(
                values.salaryCeilingList[length - 1]?.toDate,
                "dd/MM/yyyy",
                new Date(),
              ).toString(),
            ).format("DD/MM/YYYY"),
            toDate: dayjs(
              parse(
                values.salaryCeilingList[length - 1]?.toDate,
                "dd/MM/yyyy",
                new Date(),
              ).toString(),
            )
              .add(values.salaryCeilingList?.length + 1, "year")
              .format("DD/MM/YYYY"),
            CPFOrdinaryWageCeiling: 0,
            CPFAnnualSalaryCeiling: 0,
          });
          setFieldValue("salaryCeilingList", temp);
        }
        break;
      default:
        break;
    }
  };

  /**
   * @param { 'salaryCeilingList' } status
   * @param {number} i
   */
  const removeShg = (i = 0, status) => {
    switch (status) {
      case "salaryCeilingList":
        {
          const temp = [...values.salaryCeilingList];
          temp.splice(i, 1);
          setFieldValue("salaryCeilingList", temp);
        }
        break;

      default:
        break;
    }
  };

  useAlertApi(params?.id ? resultUpdate : result);
  useEffect(() => {
    if (params?.id) {
      setFieldValue("_id", params?.id);
    }
  }, [params]);
  useEffect(() => {
    if (state) {
      const data = state;
      const date = {
        salaryCeilingStartDate: dayjs(data.salaryCeilingStartDate).format(
          "DD/MM/YYYY",
        ),
        salaryCeilingEndDate: dayjs(data.salaryCeilingEndDate).format(
          "DD/MM/YYYY",
        ),
      };
      const salaryCeilingList = data?.salaryCeilingList?.map((d) => {
        return {
          ...d,
          fromDate: dayjs(d?.fromDate).format("DD/MM/YYYY"),
          toDate: dayjs(d?.toDate).format("DD/MM/YYYY"),
          CPFAnnualSalaryCeiling: formatRupiah(d?.CPFAnnualSalaryCeiling),
          CPFOrdinaryWageCeiling: formatRupiah(d?.CPFOrdinaryWageCeiling),
        };
      });
      setValues({
        ...data,
        salaryCeilingList: salaryCeilingList,
        ...date,
      });
    }
  }, [state]);

  return (
    <div className="w-full space-y-4 mt-5">
      <div className="w-[100%]  flex justify-end">
        <Button
          style="solid"
          onClick={() => handleSubmit()}
          label={
            <div className="flex px-4 gap-1">
              <SaveIconSVG color={"white"} />
              <div>{params?.id ? "Update" : "Save"}</div>
            </div>
          }
        />
      </div>
      <div className="w-full flex mt-5 ">
        <Accordion
          title={`Salary Ceilings Details`}
          children={
            <div className="mt-5 grid grid-cols-2 gap-4">
              <div className="col-span-full">
                <InputText
                  value={values.salaryCeilingName}
                  error={errors.salaryCeilingName}
                  setFieldValue={setFieldValue}
                  onChange={handleChange}
                  name={"salaryCeilingName"}
                  id={"salaryCeilingName"}
                  title="Salary Ceilings Name"
                />
              </div>

              <InputDate
                name={"salaryCeilingStartDate"}
                id={"salaryCeilingStartDate"}
                label="Start Date Period*"
                setFieldValue={setFieldValue}
                value={values.salaryCeilingStartDate}
                errors={errors.salaryCeilingStartDate}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <InputDate
                name={"salaryCeilingEndDate"}
                id={"salaryCeilingEndDate"}
                label="End Date Period*"
                errors={errors.salaryCeilingEndDate}
                setFieldValue={setFieldValue}
                value={values.salaryCeilingEndDate}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <ToggleSwitch
                value={values?.salaryCeilingStatus}
                onChange={(e) => {
                  setFieldValue(
                    "salaryCeilingStatus",
                    !values.salaryCeilingStatus,
                  );
                }}
                name="Active"
                label={values?.salaryCeilingStatus ? "Active" : "Disabled"}
              />
            </div>
          }
        />
      </div>
      <div>
        <Accordion
          title={`CPF Ordinary Wage ceiling`}
          children={
            <div>
              <div>
                {values?.salaryCeilingList?.map((d, i) => {
                  return (
                    <ComList
                      d={d}
                      errors={errors}
                      i={i}
                      removeShg={removeShg}
                      setFieldValue={setFieldValue}
                      values={values}
                      salaryCeilingList={values?.salaryCeilingList}
                      name={"salaryCeilingList"}
                      nameSelect="salaryCeilingMaxSelect"
                    />
                  );
                })}
              </div>

              <br />
              <Button
                onClick={() => addShg("salaryCeilingList")}
                className={"w-full"}
                backgroundColor={"#DD7224"}
                label={
                  <div className="flex p-4 gap-1 capitalize text-white">
                    <UserAddIcon color={"#fff"} />
                    <div>
                      <div>Add CPF Ordinary Wage ceiling</div>
                    </div>
                  </div>
                }
              />
            </div>
          }
        />
      </div>
    </div>
  );
};

export default AddEditShg;
