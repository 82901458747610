import * as Yup from "yup";

/**
 * Schema for validating salary ceiling data.
 *
 * @typedef {Object} SalaryCeilingListItem
 * @property {Date} fromDate - The start date of the salary ceiling period.
 * @property {number} toDate - The end date of the salary ceiling period.
 * @property {number} CPFOrdinaryWageCeiling - The ceiling for CPF Ordinary Wage.
 * @property {number} CPFAnnualSalaryCeiling - The ceiling for CPF Annual Salary.
 */

/**
 * Validation schema for the salary ceiling form.
 *
 * @type {Yup.ObjectSchema<{
 *   organizationID: string,
 *   salaryCeilingName: string,
 *   salaryCeilingStartDate: Date,
 *   salaryCeilingEndDate: Date,
 *   salaryCeilingStatus: boolean,
 *   salaryCeilingMaxSelect: boolean,
 *   salaryCeilingList: SalaryCeilingListItem[]
 * }>}
 */
export const validationSchemaSalaryCeilings = Yup.object().shape({
  salaryCeilingName: Yup.string().required("Salary Ceiling Name is required"),
  salaryCeilingStartDate: Yup.string().required(
    "Salary Ceiling Start Date is required",
  ),
  salaryCeilingEndDate: Yup.string().required(
    "Salary Ceiling End Date is required",
  ),
  salaryCeilingStatus: Yup.boolean().required(
    "Salary Ceiling Status is required",
  ),
  salaryCeilingMaxSelect: Yup.boolean().required(
    "Salary Ceiling Max Select is required",
  ),
  salaryCeilingList: Yup.array()
    .of(
      Yup.object().shape({
        fromDate: Yup.string().required("From Date is required"),
        toDate: Yup.string().required("To Date is required"),
        CPFOrdinaryWageCeiling: Yup.string().required(
          "CPF Ordinary Wage Ceiling is required",
        ),
        CPFAnnualSalaryCeiling: Yup.string().required(
          "CPF Annual Salary Ceiling is required",
        ),
      }),
    )
    .required("Salary Ceiling List is required"),
});
