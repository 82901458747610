import React from "react";

export const AlertPayrollSVG = ({ color, ...otherProps }) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.57121 14.6654C12.2531 14.6654 15.2379 11.6806 15.2379 7.9987C15.2379 4.3168 12.2531 1.33203 8.57121 1.33203C4.88931 1.33203 1.90454 4.3168 1.90454 7.9987C1.90454 11.6806 4.88931 14.6654 8.57121 14.6654Z"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.57129 10.6667V8"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.57129 5.33203H8.57796"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
