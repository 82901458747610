import React from "react";
import PropTypes from "prop-types";

const Index = ({ color, className, ...otherProps }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_7116_140825)">
        <path
          d="M14 5.33203V13.9987H2V5.33203"
          stroke={color}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.66675 8H9.33341"
          stroke={color}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15.3334 2H0.666748V5.33333H15.3334V2Z"
          stroke={color}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_7116_140825">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

Index.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

Index.defaultProps = {
  color: "#111111",
  className: "",
};

export default Index;
