import React from "react";

const DocumentIconSVG = ({ color, ...otherProps }) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.16669 1.33203H4.50002C4.1464 1.33203 3.80726 1.47251 3.55721 1.72256C3.30716 1.9726 3.16669 2.31174 3.16669 2.66536V13.332C3.16669 13.6857 3.30716 14.0248 3.55721 14.2748C3.80726 14.5249 4.1464 14.6654 4.50002 14.6654H12.5C12.8536 14.6654 13.1928 14.5249 13.4428 14.2748C13.6929 14.0248 13.8334 13.6857 13.8334 13.332V5.9987L9.16669 1.33203Z"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.16669 1.33203V5.9987H13.8334"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default DocumentIconSVG;
