import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { DataTable, TableMenu } from "@bluesilodev/timhutcomponents";
import { useNavigate } from "react-router-dom";
import { CheckBox } from "@bluesilodev/timhutcomponents";
import CustomCheckbox from "../checkbox";
import { findPartPercentage, formatRupiah } from "utils/numberUtils";
import { useDispatch, useSelector } from "react-redux";
import { ReviewModal } from "components/payroll-run/modal/reviewModal";
import { RequestClaimsModal } from "components/payroll-run/modal/requestClaimsModal";
import dayjs from "dayjs";
import {
  ContextReportAndPayslip,
  IMAGE_PLACEHOLDER_PATH,
} from "utils/constants";
import {
  PayrollApi,
  useDeletePayrollMutation,
  useFetchPayrollAttendanceSettingLeaveQuery,
} from "store/api/payrollApi";
import {
  calculateEarningsForHours,
  convertHoursToFullDuration,
} from "utils/calculate";
import { Deduction } from "components/payroll-run/modal/deduction";

import { AdditionalModal } from "components/payroll-run/modal/additionalModal";
import { DotThreeSVG } from "assets/icons/DotThreeSVG";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { ModalConfirmationV2 } from "@bluesilodev/timhutcomponents";

// Extend Day.js dengan plugin customParseFormat
dayjs.extend(customParseFormat);

/**
 * @param {import("type/typePayroll").Claim} dataTable
 * */
export const PayrollTotalDataTable = ({ dataTable }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [requestPayroll] = useDeletePayrollMutation();
  const [isShow, setIsShow] = useState(false);
  const cell = (status, navigatePath) => {
    return (
      <div className="w-full cursor-pointer flex justify-start items-center">
        <h1 className="">{status?.getValue()}</h1>
      </div>
    );
  };

  const columns = useMemo(() => {
    return [
      {
        id: "label",
        accessorFn: (row) => <span className="m-2 ">{row?.label}</span>,
        enableSorting: true,
        header: () => (
          <span className="m-2 flex justify-start">Payroll Label</span>
        ),
        cell: (status) => {
          return cell(status);
        },
      },
      {
        id: "paymentDate",
        accessorFn: (row) => (
          <span className="m-2">
            {dayjs(row?.paymentDate).format("DD MMMM YYYY")}
          </span>
        ),
        header: () => (
          <span className="m-2 flex justify-start">Payment Schedule</span>
        ),
        enableSorting: true,
        cell: (status) => {
          return cell(status);
        },
      },
      {
        id: "startDate",
        accessorFn: (row) => (
          <span className="m-2">
            {dayjs(row?.startDate).format("DD MMMM YYYY")}
          </span>
        ),
        header: () => (
          <span className="m-2 flex justify-start">Start Date Period</span>
        ),
        enableSorting: true,
        cell: (status) => {
          return cell(status);
        },
      },
      {
        id: "endDate",
        accessorFn: (row) => (
          <span className="m-2">
            {dayjs(row?.endDate).format("DD MMMM YYYY")}
          </span>
        ),
        header: () => (
          <span className="m-2 flex justify-start">End Date Period</span>
        ),
        enableSorting: true,
        cell: (status) => {
          return cell(status);
        },
      },
      {
        id: "location",
        accessorFn: (row) => {
          return <span className="m-2">{row.location?.name}</span>;
        },
        header: () => <span className="m-2 flex justify-start">Locations</span>,
        enableSorting: true,
        cell: (status) => {
          return cell(status);
        },
      },
      {
        id: "total_employee",
        accessorFn: (row) => row,
        header: () => (
          <span className="m-2 flex justify-start">Total Employee</span>
        ),
        enableSorting: true,
        cell: (status) => {
          const row = status?.row.original;
          return (
            <div className="flex flex-row gap-2 capitalize justify-center items-center cursor-pointer">
              {row?.data?.length} employees
            </div>
          );
        },
      },
      {
        id: "total_pay",
        accessorFn: (row) => row,
        header: () => <span className="m-2 flex justify-start">Total Pay</span>,
        enableSorting: true,
        cell: (status) => {
          const row = status?.row.original;
          const total = row?.data?.reduce((pre, v) => {
            pre += v?.total;
            return pre;
          }, 0);

          return <div>{formatRupiah(total)}</div>;
        },
      },
      {
        id: "status",
        accessorFn: (row) => row.status,
        header: () => <span className="m-2 flex justify-start">Status</span>,
        enableSorting: true,
        cell: (status) => {
          return (
            <div
              className={`flex w-[167px] h-[35px] rounded-[57px] capitalize font-bold ${
                status.getValue() === "paid" ? "bg-[#A5F2CE]" : "bg-[#D4D4D4] "
              } justify-center items-center`}
            >
              {status.getValue()}
            </div>
          );
        },
      },
      {
        accessorFn: (row) => row.uId,
        disableSorting: true,
        header: () => {},
        id: "action",
        cell: (status) => {
          const original = status?.row?.original;
          return (
            <div className="cursor-pointer w-fit relative">
              {isShow && (
                <ModalConfirmationV2
                  className={"min-w-[600px]"}
                  title={"Delete Payroll"}
                  headMessage={""}
                  message={
                    <div className="text-left w-full text-lg font-light">
                      Do you want to delete this item?
                    </div>
                  }
                  onClose={() => setIsShow(false)}
                  onConfirm={() => {
                    requestPayroll({ id: original?._id });
                    dispatch(PayrollApi.util.invalidateTags(["payroll"]));
                    setIsShow(false);
                  }}
                />
              )}
              {original?.status === "draft" && (
                <TableMenu
                  show={true}
                  onEdit={() => {
                    navigate(`/run-payroll/${original?._id}`);
                  }}
                  onDelete={() => {
                    setIsShow(true);
                  }}
                />
              )}
            </div>
          );
        },
      },
    ];
  }, [dataTable, isShow]);

  return (
    <React.Fragment>
      <DataTable columns={columns} data={dataTable} pagination={false} />
    </React.Fragment>
  );
};

/**
 * @param {import("type/typePayroll").Claim} dataTable
 * */
export const TotalClaimsDataTable = ({ dataTable }) => {
  const cell = (status) => {
    return (
      <div className="w-full cursor-pointer flex justify-start items-center">
        <h1 className="">{status.getValue()}</h1>
      </div>
    );
  };
  const navigate = useNavigate();
  const login = useSelector((state) => state?.loginSlice);

  return (
    <div className={"w-full "}>
      <DataTable
        title="Leave Data"
        columns={[
          {
            id: "employee",
            accessorFn: (row) => row,
            enableSorting: true,
            header: () => (
              <span className="m-2   flex justify-start">Employee</span>
            ),
            cell: (status) => {
              const row = status.getValue();
              return (
                <div className="flex flex-row gap-2 justify-center items-center cursor-pointer">
                  <img
                    src={row.img}
                    alt="ProfileUser"
                    className="w-6 h-6 my-auto ml-4 rounded-full"
                  />
                  <div className="m-2 ">{row.name}</div>
                </div>
              );
            },
          },
          {
            id: "employeeId",
            accessorFn: (row) => <span className="m-2">{row?.employeeId}</span>,
            header: () => (
              <span className="m-2  flex justify-start">Employee Id</span>
            ),
            enableSorting: true,
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "position",
            accessorFn: (row) => <span className="m-2">{row.position}</span>,
            header: () => (
              <span className="m-2 ] flex justify-start">Job Position</span>
            ),
            enableSorting: true,
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "requestDate",
            accessorFn: (row) => <span className="m-2">{row.requestDate}</span>,
            header: () => (
              <span className="m-2  flex justify-start">Request Date</span>
            ),
            enableSorting: true,
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "claimName",
            accessorFn: (row) => <span className="m-2">{row.claimName}</span>,
            header: () => (
              <span className="m-2  flex justify-start">Claims Name</span>
            ),
            enableSorting: true,
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "totalClaim",
            accessorFn: (row) => <span className="m-2">{row.totalClaim}</span>,
            header: () => (
              <span className="m-2  flex justify-start">Total Claims </span>
            ),
            enableSorting: true,
            cell: (status) => {
              return cell(status);
            },
          },

          {
            id: "bySupervisor",
            accessorFn: (row) => row.bySupervisor,
            header: () => (
              <span className="m-2  flex justify-start">
                Approval by Supervisor
              </span>
            ),
            enableSorting: true,
            cell: (status) => {
              const original = status?.row?.original;
              const id = original.allData._id;
              return (
                <div
                  onClick={() => navigate(`/detail/${id}`)}
                  className={`flex w-[167px] h-[35px] rounded-[57px] cursor-pointer capitalize ${
                    status.getValue() === "approved"
                      ? "bg-[#4BD394] text-white"
                      : status.getValue() === "rejected"
                      ? "bg-[#B83C3C] text-white"
                      : "bg-[#A3A3AB] text-white"
                  } justify-center items-center`}
                >
                  {status.getValue() === "" ? "Pending" : status.getValue()}
                </div>
              );
            },
          },
          {
            id: "byHRAdmin",
            accessorFn: (row) => row.byHRAdmin,
            header: () => (
              <span className="m-2 w-[144px] flex justify-start">
                Approval by HR Admin
              </span>
            ),
            enableSorting: true,
            cell: (status) => {
              const original = status?.row?.original;
              const id = original.allData._id;

              return (
                <div
                  onClick={() => navigate(`/detail/${id}`)}
                  className={`flex w-[167px] h-[35px] rounded-[57px] cursor-pointer capitalize  ${
                    status.getValue() === "approved"
                      ? "bg-[#4BD394] text-white"
                      : status.getValue() === "rejected"
                      ? "bg-[#B83C3C] text-white"
                      : "bg-[#A3A3AB] text-white"
                  } justify-center items-center`}
                >
                  {status.getValue() === "" ? "Pending" : status.getValue()}
                </div>
              );
            },
          },
          {
            accessorFn: (row) => row.uId,
            disableSorting: true,
            header: () => {},
            id: "action",
            cell: (status) => {
              const allData = status?.row?.original?.allData;
              const validation =
                login?.currentRole?.toLocaleLowerCase() === "employee" ||
                login?.currentRole?.toLocaleLowerCase() === "admin";

              return (
                validation && (
                  <div className="cursor-pointer w-fit relative">
                    <RequestClaimsModal status={"edit"} dataEdit={allData} />
                  </div>
                )
              );
            },
          },
        ]}
        data={dataTable}
        pagination={false}
      />
    </div>
  );
};
export const UpcommingPaymentDataTable = ({ dataTable }) => {
  const navigate = useNavigate();
  const cell = (status, navigatePath) => {
    return (
      <div className="w-full cursor-pointer flex justify-start items-center">
        <h1 className="">{status.getValue()}</h1>
      </div>
    );
  };
  return (
    <React.Fragment>
      <DataTable
        title="Leave Data"
        columns={[
          {
            id: "label",
            accessorFn: (row) => <span className="m-2">{row?.label}</span>,
            enableSorting: true,
            header: () => (
              <span className="m-2 w-[144px] flex justify-start">
                Payroll Label
              </span>
            ),
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "payment_schedule",
            accessorFn: (row) => (
              <span className="m-2">{row?.paymentSchedule}</span>
            ),
            header: () => (
              <span className="m-2 w-[144px] flex justify-start">
                Payment Schedule
              </span>
            ),
            enableSorting: true,
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "start_date",
            accessorFn: (row) => <span className="m-2">{row.startDate}</span>,
            header: () => (
              <span className="m-2 w-[144px] flex justify-start">
                Start Date Period
              </span>
            ),
            enableSorting: true,
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "end_date",
            accessorFn: (row) => <span className="m-2">{row.endDate}</span>,
            header: () => (
              <span className="m-2 w-[144px] flex justify-start">
                End Date Period
              </span>
            ),
            enableSorting: true,
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "location",
            accessorFn: (row) => <span className="m-2">{row.location}</span>,
            header: () => (
              <span className="m-2 w-[144px] flex justify-start">
                Locations
              </span>
            ),
            enableSorting: true,
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "total_employee",
            accessorFn: (row) => (
              <span className="m-2">{row.totalEmployee}</span>
            ),
            header: () => (
              <span className="m-2 w-[144px] flex justify-start">
                Total Employee
              </span>
            ),
            enableSorting: true,
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "total_pay",
            accessorFn: (row) => <span className="m-2">{row.totalPay}</span>,
            header: () => (
              <span className="m-2 w-[144px] flex justify-start">
                Total Pay
              </span>
            ),
            enableSorting: true,
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "status",
            accessorFn: (row) => row.status,
            header: () => (
              <span className="m-2 w-[144px] flex justify-start">Status</span>
            ),
            enableSorting: true,
            cell: (status) => {
              return (
                <div
                  className={`flex w-[167px] h-[35px] rounded-[57px] ${
                    status.getValue() === "Paid"
                      ? "bg-[#A5F2CE]"
                      : "bg-[#D4D4D4] "
                  } justify-center items-center`}
                >
                  {status.getValue()}
                </div>
              );
            },
          },
          {
            accessorFn: (row) => row.uId,
            disableSorting: true,
            header: () => {},
            id: "action",
            cell: (status) => {
              return (
                <div className="cursor-pointer w-fit">
                  <TableMenu
                    show={true}
                    onEdit={() => {}}
                    onDelete={() => {}}
                  />
                </div>
              );
            },
          },
        ]}
        data={dataTable}
        pagination={true}
      />
    </React.Fragment>
  );
};
export const PayrollSummaryDataTable = ({ dataTable }) => {
  const navigate = useNavigate();
  const cell = (status, navigatePath) => {
    return (
      <div className="w-full cursor-pointer flex justify-start items-center">
        <h1 className="">{status.getValue()}</h1>
      </div>
    );
  };
  return (
    <React.Fragment>
      <DataTable
        title="Leave Data"
        columns={[
          {
            id: "label",
            accessorFn: (row) => <span className="m-2">{row?.label}</span>,
            enableSorting: true,
            header: () => (
              <span className="m-2 w-[144px] flex justify-start">
                Payroll Label
              </span>
            ),
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "payment_schedule",
            accessorFn: (row) => (
              <span className="m-2">{row?.paymentSchedule}</span>
            ),
            header: () => (
              <span className="m-2 w-[144px] flex justify-start">
                Payment Schedule
              </span>
            ),
            enableSorting: true,
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "start_date",
            accessorFn: (row) => <span className="m-2">{row.startDate}</span>,
            header: () => (
              <span className="m-2 w-[144px] flex justify-start">
                Start Date Period
              </span>
            ),
            enableSorting: true,
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "end_date",
            accessorFn: (row) => <span className="m-2">{row.endDate}</span>,
            header: () => (
              <span className="m-2 w-[144px] flex justify-start">
                End Date Period
              </span>
            ),
            enableSorting: true,
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "location",
            accessorFn: (row) => <span className="m-2">{row.location}</span>,
            header: () => (
              <span className="m-2 w-[144px] flex justify-start">
                Locations
              </span>
            ),
            enableSorting: true,
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "total_employee",
            accessorFn: (row) => (
              <span className="m-2">{row.totalEmployee}</span>
            ),
            header: () => (
              <span className="m-2 w-[144px] flex justify-start">
                Total Employee
              </span>
            ),
            enableSorting: true,
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "total_pay",
            accessorFn: (row) => <span className="m-2">{row.totalPay}</span>,
            header: () => (
              <span className="m-2 w-[144px] flex justify-start">
                Total Pay
              </span>
            ),
            enableSorting: true,
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "status",
            accessorFn: (row) => row.status,
            header: () => (
              <span className="m-2 w-[144px] flex justify-start">Status</span>
            ),
            enableSorting: true,
            cell: (status) => {
              return (
                <div
                  className={`flex w-[167px] h-[35px] rounded-[57px] ${
                    status.getValue() === "Paid"
                      ? "bg-[#A5F2CE]"
                      : "bg-[#D4D4D4] "
                  } justify-center items-center`}
                >
                  {status.getValue()}
                </div>
              );
            },
          },
          {
            accessorFn: (row) => row.uId,
            disableSorting: true,
            header: () => {},
            id: "action",
            cell: (status) => {
              return (
                <div className="cursor-pointer w-fit">
                  <TableMenu
                    show={true}
                    onEdit={() => {}}
                    onDelete={() => {}}
                  />
                </div>
              );
            },
          },
        ]}
        data={dataTable}
        pagination={true}
      />
    </React.Fragment>
  );
};
export const PayrollComponentDataTable = ({
  dataTable,
  handleEdit,
  handleDelete,
  status: statusAdmin,
}) => {
  const [isShow, setIsShow] = useState(false);

  const cell = (status, navigatePath) => {
    return (
      <div className="w-full cursor-pointer flex justify-start items-center">
        <h1 className="">{status.getValue()}</h1>
      </div>
    );
  };
  return (
    <React.Fragment>
      <DataTable
        title="Leave Data"
        columns={[
          {
            id: "name",
            accessorFn: (row) => <span className="m-2">{row?.name}</span>,
            enableSorting: true,
            header: () => (
              <span className="m-2 w-[250px] flex justify-start">
                Component Name
              </span>
            ),
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "compnent_type",
            accessorFn: (row) => (
              <span className="m-2 capitalize">{row?.type}</span>
            ),
            header: () => (
              <span className="m-2 w-[220px] flex justify-start">
                Component Type{" "}
              </span>
            ),
            enableSorting: true,
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "component_amount",
            accessorFn: (row) => (
              <span className="m-2 capitalize">{row.amountType}</span>
            ),
            header: () => (
              <span className="m-2 w-[250px] flex justify-start">
                Amount Type{" "}
              </span>
            ),
            enableSorting: true,
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "amount",
            accessorFn: (row) => (
              <span className="m-2">
                {row?.amountType === "fixed"
                  ? formatRupiah(row.amount)
                  : row.amount + " %"}
              </span>
            ),
            header: () => (
              <span className="m-2  flex justify-start">Amount </span>
            ),
            enableSorting: true,
            cell: (status) => {
              return cell(status);
            },
          },

          {
            accessorFn: (row) => row,
            disableSorting: true,
            header: () => {},
            id: "action",
            cell: (status) => {
              return (
                statusAdmin === "admin" && (
                  <div className="cursor-pointer relative w-fit">
                    {isShow && (
                      <ModalConfirmationV2
                        className={"min-w-[600px]"}
                        title={"Delete Payroll Component"}
                        onClose={() => setIsShow(false)}
                        onConfirm={() => {
                          handleDelete(status.getValue());
                          setIsShow(false);
                        }}
                        headMessage={""}
                        message={
                          <div className="text-left w-full text-lg font-light">
                            Do you want to delete this item?
                          </div>
                        }
                      />
                    )}
                    <TableMenu
                      show={true}
                      onEdit={() => {
                        handleEdit(status.getValue());
                      }}
                      onDelete={() => setIsShow(true)}
                    />
                  </div>
                )
              );
            },
          },
        ]}
        data={dataTable}
        pagination={true}
      />
    </React.Fragment>
  );
};
export const ClaimsTypeDataTable = ({
  dataTable,
  handleDelete,
  handleEdit,
}) => {
  const [isShow, setIsShow] = useState(false);

  const cell = (status, navigatePath) => {
    return (
      <div className="w-full cursor-pointer flex justify-start items-center">
        <h1 className="">{status.getValue()}</h1>
      </div>
    );
  };
  return (
    <React.Fragment>
      <DataTable
        title="Leave Data"
        columns={[
          {
            id: "claims_type",
            accessorFn: (row) => <span className="m-2">{row?.title}</span>,
            enableSorting: true,
            header: () => (
              <span className="m-2 flex justify-start">Claims Type</span>
            ),
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "description",
            accessorFn: (row) => (
              <span className="m-2">{row?.description}</span>
            ),
            header: () => (
              <span className="m-2 w-[600px] flex justify-start">
                Type Description{" "}
              </span>
            ),
            enableSorting: true,
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "status",
            accessorFn: (row) => <span className="m-2">{row.isActive}</span>,
            header: () => (
              <span className="m-2  flex justify-start">Status </span>
            ),
            enableSorting: true,
            cell: (status) => {
              return cell(status);
            },
          },

          {
            accessorFn: (row) => row._id,
            disableSorting: true,
            header: () => {},
            id: "action",
            cell: (status) => {
              return (
                <div className="cursor-pointer w-fit">
                  {isShow && (
                    <ModalConfirmationV2
                      className={"min-w-[600px]"}
                      title={"Delete Claims Type"}
                      onClose={() => setIsShow(false)}
                      onConfirm={() => {
                        handleDelete(status.getValue());
                        setIsShow(false);
                      }}
                      headMessage={""}
                      message={
                        <div className="text-left w-full text-lg font-light">
                          Do you want to delete this item?
                        </div>
                      }
                    />
                  )}
                  <TableMenu
                    show={true}
                    onEdit={() => handleEdit(status.getValue())}
                    onDelete={() => setIsShow(true)}
                  />
                </div>
              );
            },
          },
        ]}
        data={dataTable}
        pagination={true}
      />
    </React.Fragment>
  );
};
export const CPFConfigurationDataTable = ({
  dataTable,
  handleDelete,
  handleEdit,
}) => {
  const navigate = useNavigate();
  const [isShow, setIsShow] = useState(false);

  const cell = (status, navigatePath) => {
    return (
      <div className="w-full cursor-pointer flex justify-start items-center">
        <h1 className="">{status.getValue()}</h1>
      </div>
    );
  };
  return (
    <React.Fragment>
      <DataTable
        title="Leave Data"
        columns={[
          {
            id: "cpfName",
            accessorFn: (row) => <span className="m-2">{row?.cpfName}</span>,
            enableSorting: true,
            header: () => (
              <span className="m-2 flex justify-start capitalize">
                CPF Contribution Name Secondary
              </span>
            ),
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "cpfStartDate",
            accessorFn: (row) => (
              <span className="m-2">
                {dayjs(row?.cpfStartDate).format("DD MMMM YYYY")}
              </span>
            ),
            enableSorting: true,
            header: () => (
              <span className="m-2 flex justify-start capitalize">
                Start Date Period Secondary
              </span>
            ),
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "cpfEndDate",
            accessorFn: (row) => (
              <span className="m-2">
                {dayjs(row?.cpfEndDate).format("DD MMMM YYYY")}
              </span>
            ),
            enableSorting: true,
            header: () => (
              <span className="m-2 flex justify-start capitalize">
                End Date Period Secondary
              </span>
            ),
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "cpfStatus",
            accessorFn: (row) => (
              <span className="m-2">
                {row?.cpfStatus ? "Active" : "Not Active"}
              </span>
            ),
            enableSorting: true,
            header: () => (
              <span className="m-2 flex justify-start capitalize">status</span>
            ),
            cell: (status) => {
              return cell(status);
            },
          },
          {
            accessorFn: (row) => row,
            disableSorting: true,
            header: () => {},
            id: "action",
            cell: (status) => {
              return (
                <div className="cursor-pointer w-fit">
                  {isShow && (
                    <ModalConfirmationV2
                      className={"min-w-[600px]"}
                      title={"Delete Central Provident Fund Board (CPFB)"}
                      onClose={() => setIsShow(false)}
                      onConfirm={() => {
                        handleDelete(status.getValue());
                        setIsShow(false);
                      }}
                      headMessage={""}
                      message={
                        <div className="text-left w-full text-lg font-light">
                          Do you want to delete this item?
                        </div>
                      }
                    />
                  )}
                  <TableMenu
                    show={true}
                    onEdit={() => handleEdit(status.getValue())}
                    onDelete={() => setIsShow(true)}
                  />
                </div>
              );
            },
          },
        ]}
        data={dataTable}
        pagination={true}
      />
    </React.Fragment>
  );
};
export const SHGConfigurationDataTable = ({
  dataTable,
  handleDelete,
  handleEdit,
}) => {
  const [isShow, setIsShow] = useState(false);
  const cell = (status, navigatePath) => {
    return (
      <div className="w-full cursor-pointer flex justify-start items-center">
        <h1 className="">{status.getValue()}</h1>
      </div>
    );
  };
  return (
    <React.Fragment>
      <DataTable
        title="Shg"
        columns={[
          {
            id: "shgName",
            accessorFn: (row) => <span className="m-2">{row?.shgName}</span>,
            enableSorting: true,
            header: () => (
              <span className="m-2 flex justify-start capitalize">
                SHG funds name
              </span>
            ),
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "shgStartDate",
            accessorFn: (row) => (
              <span className="m-2">
                {dayjs(row?.shgStartDate).format("DD MMMM YYYY")}
              </span>
            ),
            enableSorting: true,
            header: () => (
              <span className="m-2 flex justify-start capitalize">
                Start Date Period
              </span>
            ),
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "shgEndDate",
            accessorFn: (row) => (
              <span className="m-2">
                {dayjs(row?.shgEndDate).format("DD MMMM YYYY")}
              </span>
            ),
            enableSorting: true,
            header: () => (
              <span className="m-2 flex justify-start capitalize">
                End Date Period
              </span>
            ),
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "shgStatus",
            accessorFn: (row) => (
              <span className="m-2">
                {row?.shgStatus ? "Active" : "Not Active"}
              </span>
            ),
            enableSorting: true,
            header: () => (
              <span className="m-2 flex justify-start capitalize">status</span>
            ),
            cell: (status) => {
              return cell(status);
            },
          },
          {
            accessorFn: (row) => row,
            disableSorting: true,
            header: () => {},
            id: "action",
            cell: (status) => {
              return (
                <div className="cursor-pointer w-fit">
                  {isShow && (
                    <ModalConfirmationV2
                      className={"min-w-[600px]"}
                      title={"Delete SHG Funds"}
                      onClose={() => setIsShow(false)}
                      onConfirm={() => {
                        handleDelete(status.getValue());
                        setIsShow(false);
                      }}
                      headMessage={""}
                      message={
                        <div className="text-left w-full text-lg font-light">
                          Do you want to delete this item?
                        </div>
                      }
                    />
                  )}
                  <TableMenu
                    show={true}
                    onEdit={() => handleEdit(status.getValue())}
                    onDelete={() => setIsShow(true)}
                  />
                </div>
              );
            },
          },
        ]}
        data={dataTable}
        pagination={true}
      />
    </React.Fragment>
  );
};
export const SalaryCeilingsDataTable = ({
  dataTable,
  handleDelete,
  handleEdit,
}) => {
  const [isShow, setIsShow] = useState(false);
  const navigate = useNavigate();
  const cell = (status, navigatePath) => {
    return (
      <div className="w-full cursor-pointer flex justify-start items-center">
        <h1 className="">{status.getValue()}</h1>
      </div>
    );
  };
  return (
    <React.Fragment>
      <DataTable
        title="SalaryCeilingsName"
        columns={[
          {
            id: "salaryCeilingName",
            accessorFn: (row) => (
              <span className="m-2">{row?.salaryCeilingName}</span>
            ),
            enableSorting: true,
            header: () => (
              <span className="m-2 flex justify-start capitalize">
                Salary Ceilings name
              </span>
            ),
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "SalaryCeilingsStartDate",
            accessorFn: (row) => (
              <span className="m-2">
                {dayjs(row?.salaryCeilingStartDate).format("DD MMMM YYYY")}
              </span>
            ),
            enableSorting: true,
            header: () => (
              <span className="m-2 flex justify-start capitalize">
                Start Date Period
              </span>
            ),
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "SalaryCeilingsEndDate",
            accessorFn: (row) => (
              <span className="m-2">
                {dayjs(row?.salaryCeilingEndDate).format("DD MMMM YYYY")}
              </span>
            ),
            enableSorting: true,
            header: () => (
              <span className="m-2 flex justify-start capitalize">
                End Date Period
              </span>
            ),
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "salaryCeilingStatus",
            accessorFn: (row) => (
              <span className="m-2">
                {row?.salaryCeilingStatus ? "Active" : "Not Active"}
              </span>
            ),
            enableSorting: true,
            header: () => (
              <span className="m-2 flex justify-start capitalize">status</span>
            ),
            cell: (status) => {
              return cell(status);
            },
          },
          {
            accessorFn: (row) => row,
            disableSorting: true,
            header: () => {},
            id: "action",
            cell: (status) => {
              return (
                <div className="cursor-pointer w-fit">
                  {isShow && (
                    <ModalConfirmationV2
                      className={"min-w-[600px]"}
                      title={"Delete Salary Ceilings"}
                      onClose={() => setIsShow(false)}
                      onConfirm={() => {
                        handleDelete(status.getValue());
                        setIsShow(false);
                      }}
                      headMessage={""}
                      message={
                        <div className="text-left w-full text-lg font-light">
                          Do you want to delete this item?
                        </div>
                      }
                    />
                  )}
                  <TableMenu
                    show={true}
                    onEdit={() => handleEdit(status.getValue())}
                    onDelete={() => setIsShow(true)}
                  />
                </div>
              );
            },
          },
        ]}
        data={dataTable}
        pagination={true}
      />
    </React.Fragment>
  );
};
export const FwlDataTable = ({ dataTable, handleDelete, handleEdit }) => {
  const [isShow, setIsShow] = useState(false);
  const cell = (status, navigatePath) => {
    return (
      <div className="w-full cursor-pointer flex justify-start items-center">
        <h1 className="">{status.getValue()}</h1>
      </div>
    );
  };
  return (
    <React.Fragment>
      <DataTable
        columns={[
          {
            id: "fwlName",
            accessorFn: (row) => <span className="m-2">{row?.fwlName}</span>,
            enableSorting: true,
            header: () => (
              <span className="m-2 flex justify-start capitalize">
                Singapore Foreign Worker Levy Name Secondary
              </span>
            ),
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "fwlStartDate",
            accessorFn: (row) => (
              <span className="m-2">
                {dayjs(row?.fwlStartDate).format("DD MMMM YYYY")}
              </span>
            ),
            enableSorting: true,
            header: () => (
              <span className="m-2 flex justify-start capitalize">
                Start Date Period
              </span>
            ),
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "fwlEndDate",
            accessorFn: (row) => (
              <span className="m-2">
                {dayjs(row?.fwlEndDate).format("DD MMMM YYYY")}
              </span>
            ),
            enableSorting: true,
            header: () => (
              <span className="m-2 flex justify-start capitalize">
                End Date Period
              </span>
            ),
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "fwlStatus",
            accessorFn: (row) => (
              <span className="m-2">
                {row?.fwlStatus ? "Active" : "Not Active"}
              </span>
            ),
            enableSorting: true,
            header: () => (
              <span className="m-2 flex justify-start capitalize">status</span>
            ),
            cell: (status) => {
              return cell(status);
            },
          },
          {
            accessorFn: (row) => row,
            disableSorting: true,
            header: () => {},
            id: "action",
            cell: (status) => {
              return (
                <div className="cursor-pointer w-fit">
                  {isShow && (
                    <ModalConfirmationV2
                      className={"min-w-[600px]"}
                      title={"Delete Singapore Foreign Worker Levy"}
                      onClose={() => setIsShow(false)}
                      onConfirm={() => {
                        handleDelete(status.getValue());
                        setIsShow(false);
                      }}
                      headMessage={""}
                      message={
                        <div className="text-left w-full text-lg font-light">
                          Do you want to delete this item?
                        </div>
                      }
                    />
                  )}
                  <TableMenu
                    show={true}
                    onEdit={() => handleEdit(status.getValue())}
                    onDelete={() => setIsShow(true)}
                  />
                </div>
              );
            },
          },
        ]}
        data={dataTable}
        pagination={true}
      />
    </React.Fragment>
  );
};
export const SdlDataTable = ({ dataTable, handleDelete, handleEdit }) => {
  const [isShow, setIsShow] = useState(false);

  const navigate = useNavigate();
  const cell = (status, navigatePath) => {
    return (
      <div className="w-full cursor-pointer flex justify-start items-center">
        <h1 className="">{status.getValue()}</h1>
      </div>
    );
  };
  return (
    <React.Fragment>
      <DataTable
        title="SalaryCeilingsName"
        columns={[
          {
            id: "sdlName",
            accessorFn: (row) => <span className="m-2">{row?.sdlName}</span>,
            enableSorting: true,
            header: () => (
              <span className="m-2 flex justify-start capitalize">
                Skills Development Levy Name
              </span>
            ),
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "sdlStartDate",
            accessorFn: (row) => (
              <span className="m-2">
                {dayjs(row?.sdlStartDate).format("DD MMMM YYYY")}
              </span>
            ),
            enableSorting: true,
            header: () => (
              <span className="m-2 flex justify-start capitalize">
                Start Date Period
              </span>
            ),
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "sdlEndDate",
            accessorFn: (row) => (
              <span className="m-2">
                {dayjs(row?.sdlEndDate).format("DD MMMM YYYY")}
              </span>
            ),
            enableSorting: true,
            header: () => (
              <span className="m-2 flex justify-start capitalize">
                End Date Period
              </span>
            ),
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "sdlStatus",
            accessorFn: (row) => (
              <span className="m-2">
                {row?.sdlStatus ? "Active" : "Not Active"}
              </span>
            ),
            enableSorting: true,
            header: () => (
              <span className="m-2 flex justify-start capitalize">status</span>
            ),
            cell: (status) => {
              return cell(status);
            },
          },
          {
            accessorFn: (row) => row,
            disableSorting: true,
            header: () => {},
            id: "action",
            cell: (status) => {
              return (
                <div className="cursor-pointer w-fit">
                  {isShow && (
                    <ModalConfirmationV2
                      className={"min-w-[600px]"}
                      title={"Delete Skills Development Levy"}
                      onClose={() => setIsShow(false)}
                      onConfirm={() => {
                        handleDelete(status.getValue());
                        setIsShow(false);
                      }}
                      headMessage={""}
                      message={
                        <div className="text-left w-full text-lg font-light">
                          Do you want to delete this item?
                        </div>
                      }
                    />
                  )}
                  <TableMenu
                    show={true}
                    onEdit={() => handleEdit(status.getValue())}
                    onDelete={() => setIsShow(true)}
                  />
                </div>
              );
            },
          },
        ]}
        data={dataTable}
        pagination={true}
      />
    </React.Fragment>
  );
};
export const SdlAddEditDataTable = ({
  dataTable,
  handleDelete,
  handleEdit,
}) => {
  const cell = (status, navigatePath) => {
    return (
      <div className="w-full cursor-pointer flex justify-start items-center">
        <h1 className="">{status.getValue()}</h1>
      </div>
    );
  };
  return (
    <React.Fragment>
      <DataTable
        columns={[
          {
            id: "twm",
            accessorFn: (row) => <span className="m-2">{row?.twm}</span>,
            enableSorting: true,
            header: () => (
              <span className="m-2 flex justify-start capitalize">
                Total wages for the calendar month
              </span>
            ),
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "sdlPayable",
            accessorFn: (row) => (
              <span className="m-2">{row?.sdlPayable?.toString()}</span>
            ),
            enableSorting: true,
            header: () => (
              <span className="m-2 flex justify-start capitalize">
                SDL payable
              </span>
            ),
            cell: (status) => {
              return cell(status);
            },
          },
          {
            accessorFn: (row) => row,
            disableSorting: true,
            header: () => {},
            id: "action",
            cell: (status) => {
              const index = status.row.index;
              return (
                <div className="cursor-pointer w-fit relative">
                  <TableMenu
                    show={true}
                    onEdit={() =>
                      handleEdit({ data: status.getValue(), id: index })
                    }
                    onDelete={() => handleDelete(index)}
                  />
                </div>
              );
            },
          },
        ]}
        data={dataTable}
      />
    </React.Fragment>
  );
};
export const FwlConstructionDataTable = ({
  dataTable,
  handleDelete,
  handleEdit,
}) => {
  const cell = (status, navigatePath) => {
    return (
      <div className="w-full cursor-pointer flex justify-start items-center">
        <h1 className="">{status.getValue()}</h1>
      </div>
    );
  };
  return (
    <React.Fragment>
      <DataTable
        columns={[
          {
            id: "tier",
            accessorFn: (row) => <span className="m-2">{row?.tier}</span>,
            enableSorting: true,
            header: () => (
              <span className="m-2 flex justify-start capitalize">Tier</span>
            ),
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "monthly",
            accessorFn: (row) => <span className="m-2">{row?.monthly}</span>,
            enableSorting: true,
            header: () => (
              <span className="m-2 flex justify-start capitalize">
                monthly ($$)
              </span>
            ),
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "daily",
            accessorFn: (row) => <span className="m-2">{row?.daily}</span>,
            enableSorting: true,
            header: () => (
              <span className="m-2 flex justify-start capitalize">
                daily ($$)
              </span>
            ),
            cell: (status) => {
              return cell(status);
            },
          },
          {
            accessorFn: (row) => row,
            disableSorting: true,
            header: () => {},
            id: "action",
            cell: (status) => {
              const index = status.row.index;
              return (
                <div className="cursor-pointer w-fit relative">
                  <TableMenu
                    show={true}
                    onEdit={() =>
                      handleEdit({ data: status.getValue(), id: index })
                    }
                    onDelete={() => handleDelete(index)}
                  />
                </div>
              );
            },
          },
        ]}
        data={dataTable}
      />
    </React.Fragment>
  );
};
export const FwlManufacturingDataTable = ({
  dataTable,
  handleDelete,
  handleEdit,
}) => {
  const cell = (status, navigatePath) => {
    return (
      <div className="w-full cursor-pointer flex justify-start items-center">
        <h1 className="">{status.getValue()}</h1>
      </div>
    );
  };
  return (
    <React.Fragment>
      <DataTable
        columns={[
          {
            id: "quote",
            accessorFn: (row) => <span className="m-2">{row?.quote}</span>,
            enableSorting: true,
            header: () => (
              <span className="m-2 flex justify-start capitalize">quote</span>
            ),
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "monthlyBasicSkilled",
            accessorFn: (row) => (
              <span className="m-2">{row?.monthlyBasicSkilled}</span>
            ),
            enableSorting: true,
            header: () => (
              <span className="m-2 flex justify-start capitalize">
                Monthly for basic-skilled ($$)
              </span>
            ),
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "dailyBasicSkilled",
            accessorFn: (row) => (
              <span className="m-2">{row?.dailyBasicSkilled}</span>
            ),
            enableSorting: true,
            header: () => (
              <span className="m-2 flex justify-start capitalize">
                daily for basic-skilled ($$)
              </span>
            ),
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "monthlyHigherSkilled",
            accessorFn: (row) => (
              <span className="m-2">{row?.monthlyHigherSkilled}</span>
            ),
            enableSorting: true,
            header: () => (
              <span className="m-2 flex justify-start capitalize">
                Monthly for higher-skilled ($$)
              </span>
            ),
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "dailyHigherSkilled",
            accessorFn: (row) => (
              <span className="m-2">{row?.dailyHigherSkilled}</span>
            ),
            enableSorting: true,
            header: () => (
              <span className="m-2 flex justify-start capitalize">
                daily for higher-skilled ($$)
              </span>
            ),
            cell: (status) => {
              return cell(status);
            },
          },
          {
            accessorFn: (row) => row,
            disableSorting: true,
            header: () => {},
            id: "action",
            cell: (status) => {
              const index = status.row.index;
              return (
                <div className="cursor-pointer w-fit relative">
                  <TableMenu
                    show={true}
                    onEdit={() =>
                      handleEdit({ data: status.getValue(), id: index })
                    }
                    onDelete={() => handleDelete(index)}
                  />
                </div>
              );
            },
          },
        ]}
        data={dataTable}
      />
    </React.Fragment>
  );
};
export const FwlMarineDataTable = ({ dataTable, handleDelete, handleEdit }) => {
  const cell = (status, navigatePath) => {
    return (
      <div className="w-full cursor-pointer flex justify-start items-center">
        <h1 className="">{status.getValue()}</h1>
      </div>
    );
  };
  return (
    <React.Fragment>
      <DataTable
        columns={[
          {
            id: "tier",
            accessorFn: (row) => <span className="m-2">{row?.tier}</span>,
            enableSorting: true,
            header: () => (
              <span className="m-2 flex justify-start capitalize">
                category
              </span>
            ),
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "monthly",
            accessorFn: (row) => <span className="m-2">{row?.monthly}</span>,
            enableSorting: true,
            header: () => (
              <span className="m-2 flex justify-start capitalize">
                monthly levy rate
              </span>
            ),
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "daily",
            accessorFn: (row) => <span className="m-2">{row?.daily}</span>,
            enableSorting: true,
            header: () => (
              <span className="m-2 flex justify-start capitalize">
                daily levy rate
              </span>
            ),
            cell: (status) => {
              return cell(status);
            },
          },
          {
            accessorFn: (row) => row,
            disableSorting: true,
            header: () => {},
            id: "action",
            cell: (status) => {
              const index = status.row.index;
              return (
                <div className="cursor-pointer w-fit relative">
                  <TableMenu
                    show={true}
                    onEdit={() =>
                      handleEdit({ data: status.getValue(), id: index })
                    }
                    onDelete={() => handleDelete(index)}
                  />
                </div>
              );
            },
          },
        ]}
        data={dataTable}
      />
    </React.Fragment>
  );
};
export const FwlProcessDataTable = ({
  dataTable,
  handleDelete,
  handleEdit,
}) => {
  const cell = (status, navigatePath) => {
    return (
      <div className="w-full cursor-pointer flex justify-start items-center">
        <h1 className="">{status.getValue()}</h1>
      </div>
    );
  };
  return (
    <React.Fragment>
      <DataTable
        columns={[
          {
            id: "tier",
            accessorFn: (row) => <span className="m-2">{row?.tier}</span>,
            enableSorting: true,
            header: () => (
              <span className="m-2 flex justify-start capitalize">Tier</span>
            ),
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "monthly",
            accessorFn: (row) => <span className="m-2">{row?.monthly}</span>,
            enableSorting: true,
            header: () => (
              <span className="m-2 flex justify-start capitalize">
                monthly ($$)
              </span>
            ),
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "daily",
            accessorFn: (row) => <span className="m-2">{row?.daily}</span>,
            enableSorting: true,
            header: () => (
              <span className="m-2 flex justify-start capitalize">
                daily ($$)
              </span>
            ),
            cell: (status) => {
              return cell(status);
            },
          },
          {
            accessorFn: (row) => row,
            disableSorting: true,
            header: () => {},
            id: "action",
            cell: (status) => {
              const index = status.row.index;
              return (
                <div className="cursor-pointer w-fit relative">
                  <TableMenu
                    show={true}
                    onEdit={() =>
                      handleEdit({ data: status.getValue(), id: index })
                    }
                    onDelete={() => handleDelete(index)}
                  />
                </div>
              );
            },
          },
        ]}
        data={dataTable}
      />
    </React.Fragment>
  );
};
export const FwlServiceDataTable = ({
  dataTable,
  handleDelete,
  handleEdit,
}) => {
  const cell = (status, navigatePath) => {
    return (
      <div className="w-full cursor-pointer flex justify-start items-center">
        <h1 className="">{status.getValue()}</h1>
      </div>
    );
  };
  return (
    <React.Fragment>
      <DataTable
        columns={[
          {
            id: "quote",
            accessorFn: (row) => <span className="m-2">{row?.quote}</span>,
            enableSorting: true,
            header: () => (
              <span className="m-2 flex justify-start capitalize">quote</span>
            ),
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "monthlyBasicSkilled",
            accessorFn: (row) => (
              <span className="m-2">{row?.monthlyBasicSkilled}</span>
            ),
            enableSorting: true,
            header: () => (
              <span className="m-2 flex justify-start capitalize">
                Monthly for basic-skilled ($$)
              </span>
            ),
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "dailyBasicSkilled",
            accessorFn: (row) => (
              <span className="m-2">{row?.dailyBasicSkilled}</span>
            ),
            enableSorting: true,
            header: () => (
              <span className="m-2 flex justify-start capitalize">
                daily for basic-skilled ($$)
              </span>
            ),
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "monthlyHigherSkilled",
            accessorFn: (row) => (
              <span className="m-2">{row?.monthlyHigherSkilled}</span>
            ),
            enableSorting: true,
            header: () => (
              <span className="m-2 flex justify-start capitalize">
                Monthly for higher-skilled ($$)
              </span>
            ),
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "dailyHigherSkilled",
            accessorFn: (row) => (
              <span className="m-2">{row?.dailyHigherSkilled}</span>
            ),
            enableSorting: true,
            header: () => (
              <span className="m-2 flex justify-start capitalize">
                daily for higher-skilled ($$)
              </span>
            ),
            cell: (status) => {
              return cell(status);
            },
          },
          {
            accessorFn: (row) => row,
            disableSorting: true,
            header: () => {},
            id: "action",
            cell: (status) => {
              const index = status.row.index;
              return (
                <div className="cursor-pointer w-fit relative">
                  <TableMenu
                    show={true}
                    onEdit={() =>
                      handleEdit({ data: status.getValue(), id: index })
                    }
                    onDelete={() => handleDelete(index)}
                  />
                </div>
              );
            },
          },
        ]}
        data={dataTable}
      />
    </React.Fragment>
  );
};
export const EmployeeDataTable = ({
  dataTable = [],
  setFieldValue = (data) => data,
  values = [],
}) => {
  const [select, setSelect] = useState([]);
  const findSelect = (d) => {
    const temp = [...select];
    if (select?.some((d2) => d2?.uId === d?.uId)) {
      const findIndex = select.findIndex((d2) => d2?.uId === d?.uId);
      if (findIndex >= 0) {
        temp.splice(findIndex, 1);
      }
    } else {
      temp.push(d);
    }
    setSelect(temp);
    setFieldValue("selectedEmployees", temp);
  };
  const idAll = dataTable?.map((d) => d);

  const selectAll = () => {
    if (select.length === idAll.length) {
      setSelect([]);
      setFieldValue("selectedEmployees", []);
    } else {
      console.log("tes", select);
      setFieldValue("selectedEmployees", idAll);
      setSelect(idAll);
    }
  };

  useEffect(() => {
    if (values?.length > 0) {
      setSelect(values);
    } else {
      setSelect([]);
    }
  }, [values]);

  const columns = useMemo(() => {
    return [
      {
        id: "checkbox_user",
        accessorFn: (row) => row,
        disableSorting: true,
        header: () => (
          <CustomCheckbox
            onChange={() => selectAll()}
            nameInput={`user.select.all`}
            checked={select.length === idAll.length}
          />
        ),
        cell: (status) => {
          const id = status?.row?.original?.uId;
          const data = status?.row?.original;
          return (
            <div className="flex flex-row gap-2 justify-center items-center cursor-pointer">
              <CheckBox
                onChange={() => findSelect(data)}
                nameInput={`user.select.${id}`}
                value={select?.find((d2) => d2?.uId === id)}
              />
            </div>
          );
        },
      },
      {
        id: "employee",
        accessorFn: (row) => row,
        enableSorting: true,
        header: () => (
          <span className="m-2 w-[125px] flex justify-start test">
            Employee
          </span>
        ),
        cell: (status) => {
          const row = status.getValue();
          return (
            <div className="flex flex-row gap-2 justify-center items-center cursor-pointer">
              <img
                src={row?.photo[0]?.link}
                alt="ProfileUser"
                className="w-10 h-10 my-auto ml-4 rounded-full object-contain"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = IMAGE_PLACEHOLDER_PATH;
                }}
              />
              <span className="m-2">{row?.firstName}</span>
            </div>
          );
        },
      },
      {
        id: "location",
        accessorFn: (row) => row,
        header: () => (
          <span className="m-2 flex justify-start capitalize">location</span>
        ),
        enableSorting: true,
        cell: (status) => {
          const row = status.getValue();
          return (
            <div className="flex flex-row gap-2 justify-center items-center cursor-pointer">
              {row?.address}
            </div>
          );
        },
      },
      {
        id: "userInformation",
        accessorFn: (row) => row,
        header: () => (
          <span className="m-2 flex justify-start capitalize">department</span>
        ),
        enableSorting: true,
        cell: (status) => {
          const row = status.getValue();
          const departments =
            row?.userInformation?.employeementDetail?.departments;
          return (
            <div className="flex flex-row gap-2 justify-center items-center cursor-pointer">
              {departments}
            </div>
          );
        },
      },
      {
        id: "job",
        accessorFn: (row) => row,
        header: () => (
          <span className="m-2 flex justify-start">Job Position</span>
        ),
        enableSorting: true,
        cell: (status) => {
          const row = status.getValue();
          const jobsPosition =
            row?.userInformation?.employeementDetail?.jobPosition;
          return (
            <div className="flex flex-row gap-2 justify-center items-center cursor-pointer">
              {jobsPosition}
            </div>
          );
        },
      },
    ];
  }, [dataTable, select]);

  return (
    <React.Fragment>
      <DataTable
        title="Leave Data"
        columns={columns}
        data={dataTable}
        pagination={true}
      />
    </React.Fragment>
  );
};

export const ReviewEmployeeDataTable = ({ dataTable = [] }) => {
  const columns = useMemo(() => {
    return [
      {
        id: "employee",
        accessorFn: (row) => row,
        enableSorting: true,
        header: () => (
          <span className="m-2 w-[125px] flex justify-start test">
            Employee
          </span>
        ),
        cell: (status) => {
          const row = status?.row?.original?.user;
          return (
            <div className="flex flex-row gap-2 justify-center items-center cursor-pointer">
              <img
                src={row?.photo[0]?.link}
                alt="ProfileUser"
                className="w-10 my-auto ml-4 rounded-full object-contain"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = IMAGE_PLACEHOLDER_PATH;
                }}
              />
              <span className="m-2">{row?.firstName}</span>
            </div>
          );
        },
      },
      {
        id: "userInformation",
        accessorFn: (row) => row,
        header: () => (
          <span className="m-2 flex justify-start capitalize">
            job position
          </span>
        ),
        enableSorting: true,
        cell: (status) => {
          const row = status?.row?.original?.user;
          return (
            <div className="flex flex-row gap-2 justify-center items-center cursor-pointer">
              {row?.userInformation?.employeementDetail?.jobPosition}
            </div>
          );
        },
      },
      {
        id: "salary",
        accessorFn: (row) => row,
        header: () => (
          <span className="m-2 flex justify-start capitalize">
            basic salary
          </span>
        ),
        enableSorting: true,
        cell: (status) => {
          const row = status?.row?.original?.user;
          const paymentDetail = row?.userInformation?.paymentDetail;
          return (
            <div className="flex flex-row gap-2 justify-center items-center cursor-pointer">
              {formatRupiah(paymentDetail?.basicSalary)}
            </div>
          );
        },
      },
      {
        id: "approved-ot",
        accessorFn: (row) => row,
        header: () => (
          <span className="m-2 flex justify-start capitalize">approved OT</span>
        ),
        enableSorting: true,
        cell: (status) => {
          const row = status.row.original;
          const index = status.row.index;

          return (
            <div className="flex flex-row gap-2 justify-center items-center cursor-pointer">
              <ReviewModal index={index} dataUser={row} />
            </div>
          );
        },
      },
      {
        id: "additional-earning",
        accessorFn: (row) => row,
        header: () => (
          <span className="m-2 flex justify-start capitalize">
            additional earning
          </span>
        ),
        enableSorting: true,
        cell: (status) => {
          const getValues = status?.row?.original;
          const basicSalary =
            getValues?.user?.userInformation?.paymentDetail?.basicSalary;
          return (
            <div className="flex flex-col gap-4 text-left  cursor-pointer">
              {getValues?.approvedEarning?.data.map((d, i) => {
                return (
                  <div key={i + "-id"}>
                    <div
                      className={`${
                        d.status === "total-earning" && "font-bold"
                      }`}
                    >
                      <div className="flex gap-1">
                        <div>{formatRupiah(d?.price)}</div>
                        <div>
                          {d?.status === "earning-percent" &&
                            `(${findPartPercentage(d?.price, basicSalary)} %) `}
                        </div>
                      </div>
                    </div>
                    <div className="text-[12px] text-slate-500 capitalize">
                      {d?.label}
                    </div>
                  </div>
                );
              })}
              <div>
                <div className={`font-bold`}>
                  {formatRupiah(getValues?.approvedEarning?.totalEarning)}
                </div>
                <div className="text-[12px] text-slate-500 capitalize">
                  total earning
                </div>
              </div>
            </div>
          );
        },
      },
      {
        id: "claims-reimbursements",
        accessorFn: (row) => row,
        header: () => (
          <span className="m-2 flex justify-start capitalize">
            Claims Reimbursements
          </span>
        ),
        enableSorting: true,
        cell: (status) => {
          const getValues = status?.row?.original;

          return (
            <div className="flex flex-col gap-4 text-left  cursor-pointer">
              {getValues?.claimsReimbursements?.data.map((d, i) => {
                return (
                  <div key={i + "-id"}>
                    <div
                      className={`${
                        d.status === "total-earning" && "font-bold"
                      }`}
                    >
                      {formatRupiah(d?.price)}
                    </div>
                    <div className="text-[12px] text-slate-500 capitalize">
                      {d?.label}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        },
      },
      {
        id: "deductions",
        accessorFn: (row) => row,
        header: () => (
          <span className="m-2 flex justify-start capitalize">deductions</span>
        ),
        enableSorting: true,
        cell: (status) => {
          const getValues = status?.row?.original;
          const index = status.row.index;
          return (
            <div className="flex flex-col gap-4 text-left  cursor-pointer">
              <Deduction
                index={index}
                getValues={getValues}
                dataDeduction={getValues}
              />
            </div>
          );
        },
      },
      {
        id: "total",
        accessorFn: (row) => row,
        header: () => (
          <span className="m-2 flex justify-start capitalize">total</span>
        ),
        enableSorting: true,
        cell: (status) => {
          const getValues = status?.row?.original;
          return <div>{formatRupiah(getValues?.total)}</div>;
        },
      },
      {
        accessorFn: (row) => row,
        disableSorting: true,
        header: () => {},
        id: "action",
        cell: (status) => {
          const index = status.row.index;

          const getValues = status?.row?.original;
          return (
            <AdditionalModal
              index={index}
              getValues={getValues}
              userId={getValues?.user?.uId}
            />
          );
        },
      },
    ];
  }, [dataTable]);

  return (
    <div className="grow w-full overflow-auto">
      <DataTable
        title="Leave Data"
        columns={columns}
        data={dataTable}
        isBorder={true}
        pagination={false}
      />
    </div>
  );
};
export const ReviewPayrollReportDataTable = ({ dataTable = [] }) => {
  const columns = useMemo(() => {
    return [
      {
        id: "employee",
        accessorFn: (row) => row,
        enableSorting: true,
        header: () => (
          <span className="m-2 w-[125px] flex justify-start test">
            Employee
          </span>
        ),
        cell: (status) => {
          const row = status?.row?.original?.user;
          return (
            <div className="flex flex-row gap-2 justify-center items-center cursor-pointer">
              <img
                src={row?.photo[0]?.link}
                alt="ProfileUser"
                className="w-10 my-auto ml-4 rounded-full object-contain"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = IMAGE_PLACEHOLDER_PATH;
                }}
              />
              <span className="m-2">{row?.firstName}</span>
            </div>
          );
        },
      },
      {
        id: "userInformation",
        accessorFn: (row) => row,
        header: () => (
          <span className="m-2 flex justify-start capitalize">
            job position
          </span>
        ),
        enableSorting: true,
        cell: (status) => {
          const row = status?.row?.original?.user;
          return (
            <div className="flex flex-row gap-2 justify-center items-center cursor-pointer">
              {row?.userInformation?.employeementDetail?.jobPosition}
            </div>
          );
        },
      },
      {
        id: "salary",
        accessorFn: (row) => row,
        header: () => (
          <span className="m-2 flex justify-start capitalize">
            basic salary
          </span>
        ),
        enableSorting: true,
        cell: (status) => {
          const row = status?.row?.original?.user;
          const paymentDetail = row?.userInformation?.paymentDetail;
          return (
            <div className="flex flex-row gap-2 justify-center items-center cursor-pointer">
              {formatRupiah(paymentDetail?.basicSalary)}
            </div>
          );
        },
      },
      {
        id: "approved-ot",
        accessorFn: (row) => row,
        header: () => (
          <span className="m-2 flex justify-start capitalize">approved OT</span>
        ),
        enableSorting: true,
        cell: (status) => {
          const row = status.row.original;
          const index = status.row.index;

          return (
            <div className="flex flex-row gap-2 justify-center items-center cursor-pointer">
              <ReviewModal index={index} dataUser={row} />
            </div>
          );
        },
      },
      {
        id: "additional-earning",
        accessorFn: (row) => row,
        header: () => (
          <span className="m-2 flex justify-start capitalize">
            additional earning
          </span>
        ),
        enableSorting: true,
        cell: (status) => {
          const getValues = status?.row?.original;
          const basicSalary =
            getValues?.user?.userInformation?.paymentDetail?.basicSalary;
          return (
            <div className="flex flex-col gap-4 text-left  cursor-pointer">
              {getValues?.approvedEarning?.data.map((d, i) => {
                return (
                  <div key={i + "-id"}>
                    <div
                      className={`${
                        d.status === "total-earning" && "font-bold"
                      }`}
                    >
                      <div className="flex gap-1">
                        <div>{formatRupiah(d?.price)}</div>
                        <div>
                          {d?.status === "earning-percent" &&
                            `(${findPartPercentage(d?.price, basicSalary)} %) `}
                        </div>
                      </div>
                    </div>
                    <div className="text-[12px] text-slate-500 capitalize">
                      {d?.label}
                    </div>
                  </div>
                );
              })}
              <div>
                <div className={`font-bold`}>
                  {formatRupiah(getValues?.approvedEarning?.totalEarning)}
                </div>
                <div className="text-[12px] text-slate-500 capitalize">
                  total earning
                </div>
              </div>
            </div>
          );
        },
      },
      {
        id: "claims-reimbursements",
        accessorFn: (row) => row,
        header: () => (
          <span className="m-2 flex justify-start capitalize">
            Claims Reimbursements
          </span>
        ),
        enableSorting: true,
        cell: (status) => {
          const getValues = status?.row?.original;

          return (
            <div className="flex flex-col gap-4 text-left  cursor-pointer">
              {getValues?.claimsReimbursements?.data.map((d, i) => {
                return (
                  <div key={i + "-id"}>
                    <div
                      className={`${
                        d.status === "total-earning" && "font-bold"
                      }`}
                    >
                      {formatRupiah(d?.price)}
                    </div>
                    <div className="text-[12px] text-slate-500 capitalize">
                      {d?.label}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        },
      },
      {
        id: "deductions",
        accessorFn: (row) => row,
        header: () => (
          <span className="m-2 flex justify-start capitalize">deductions</span>
        ),
        enableSorting: true,
        cell: (status) => {
          const getValues = status?.row?.original;
          const index = status.row.index;
          return (
            <div className="flex flex-col gap-4 text-left  cursor-pointer">
              <Deduction
                index={index}
                getValues={getValues}
                dataDeduction={getValues}
              />
            </div>
          );
        },
      },
      {
        id: "total",
        accessorFn: (row) => row,
        header: () => (
          <span className="m-2 flex justify-start capitalize">total</span>
        ),
        enableSorting: true,
        cell: (status) => {
          const getValues = status?.row?.original;
          return <div>{formatRupiah(getValues?.total)}</div>;
        },
      },
    ];
  }, [dataTable]);

  return (
    <div className="overflow-auto grow min-w-fit w-full">
      <DataTable
        columns={columns}
        isBorder={true}
        data={dataTable}
        pagination={false}
      />
    </div>
  );
};
export const ReviewPayrollReportPayslipDataTable = ({
  dataTable = [],
  setFieldValue = (data) => data,
}) => {
  const [select, setSelect] = useState([]);
  const idAll = dataTable?.map((d) => d);
  const dispatch = useDispatch();
  const { detail } = useContext(ContextReportAndPayslip);
  const navigate = useNavigate();
  const { currentRole } = useSelector((state) => state.loginSlice);

  const findSelect = (d) => {
    setSelect((preData) => {
      const temp = [...preData];
      if (select?.includes(d)) {
        const findIndex = select?.findIndex((d2) => d2?._id === d?._id);
        if (findIndex >= 0) {
          temp.splice(findIndex, 1);
        }
      } else {
        temp.push(d);
      }
      setFieldValue("selectedEmployees", temp);
      return temp;
    });
  };
  const selectAll = () => {
    if (select.length === idAll.length) {
      setSelect([]);
      setFieldValue("selectedEmployees", []);
    } else {
      setSelect(idAll);
      setFieldValue("selectedEmployees", idAll);
    }
  };

  const columns = useMemo(() => {
    return [
      {
        id: "checkbox_user",
        accessorFn: (row) => row,
        disableSorting: true,
        header: () =>
          currentRole?.toLocaleLowerCase() === "admin" ? (
            <CustomCheckbox
              onChange={() => selectAll()}
              nameInput={`user.select.all`}
              checked={select.length === idAll.length}
            />
          ) : (
            ""
          ),

        cell: (status) => {
          const id = status?.row?.original?._id;
          const data = status?.row?.original;
          return currentRole?.toLocaleLowerCase() === "admin" ? (
            <div className="flex flex-row gap-2 justify-center items-center cursor-pointer">
              <CheckBox
                onChange={() => findSelect(data)}
                nameInput={`user.select.${id}`}
                value={select?.find((d2) => d2?._id === id)}
              />
            </div>
          ) : (
            ""
          );
        },
      },
      {
        id: "employee",
        accessorFn: (row) => row,
        enableSorting: true,
        header: () => (
          <span className="m-2 min-w-fit w-[100px] flex justify-start test">
            Employee
          </span>
        ),
        cell: (status) => {
          const row = status?.row?.original?.user;
          return (
            <div className="flex flex-row gap-2 justify-center items-center cursor-pointer">
              <img
                src={row?.photo[0]?.link}
                alt="ProfileUser"
                className="w-10 my-auto ml-4 rounded-full object-contain"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = IMAGE_PLACEHOLDER_PATH;
                }}
              />
              <span className="m-2">{row?.firstName}</span>
            </div>
          );
        },
      },
      {
        id: "userInformation",
        accessorFn: (row) => row,
        header: () => (
          <span className="m-2 flex justify-start capitalize">
            job position
          </span>
        ),
        enableSorting: true,
        cell: (status) => {
          const row = status?.row?.original?.user;
          return (
            <div className="flex flex-row gap-2 justify-center items-center cursor-pointer">
              {row?.userInformation?.employeementDetail?.jobPosition}
            </div>
          );
        },
      },
      {
        id: "salary",
        accessorFn: (row) => row,
        header: () => (
          <span className="m-2 flex justify-start capitalize">
            basic salary
          </span>
        ),
        enableSorting: true,
        cell: (status) => {
          const row = status?.row?.original?.user;
          const paymentDetail = row?.userInformation?.paymentDetail;
          return (
            <div className="flex flex-row gap-2 justify-center items-center cursor-pointer">
              {formatRupiah(paymentDetail?.basicSalary)}
            </div>
          );
        },
      },
      {
        id: "approved-ot",
        accessorFn: (row) => row,
        header: () => (
          <span className="m-2 flex justify-start capitalize">approved OT</span>
        ),
        enableSorting: true,
        cell: (status) => {
          const row = status.row.original;
          const index = status.row.index;

          return (
            <div className="flex flex-row gap-2 justify-center items-center cursor-pointer">
              <ReviewModal index={index} dataUser={row} />
            </div>
          );
        },
      },
      {
        id: "additional-earning",
        accessorFn: (row) => row,
        header: () => (
          <span className="m-2 flex justify-start capitalize">
            additional earning
          </span>
        ),
        enableSorting: true,
        cell: (status) => {
          const getValues = status?.row?.original;
          const basicSalary =
            getValues?.user?.userInformation?.paymentDetail?.basicSalary;
          return (
            <div className="flex flex-col gap-4 text-left  cursor-pointer">
              {getValues?.approvedEarning?.data.map((d, i) => {
                return (
                  <div key={i + "-id"}>
                    <div
                      className={`${
                        d.status === "total-earning" && "font-bold"
                      }`}
                    >
                      <div className="flex gap-1">
                        <div>{formatRupiah(d?.price)}</div>
                        <div>
                          {d?.status === "earning-percent" &&
                            `(${findPartPercentage(d?.price, basicSalary)} %) `}
                        </div>
                      </div>
                    </div>
                    <div className="text-[12px] text-slate-500 capitalize">
                      {d?.label}
                    </div>
                  </div>
                );
              })}
              <div>
                <div className={`font-bold`}>
                  {formatRupiah(getValues?.approvedEarning?.totalEarning)}
                </div>
                <div className="text-[12px] text-slate-500 capitalize">
                  total earning
                </div>
              </div>
            </div>
          );
        },
      },
      {
        id: "claims-reimbursements",
        accessorFn: (row) => row,
        header: () => (
          <span className="m-2 flex justify-start capitalize">
            Claims Reimbursements
          </span>
        ),
        enableSorting: true,
        cell: (status) => {
          const getValues = status?.row?.original;

          return (
            <div className="flex flex-col gap-4 text-left  cursor-pointer">
              {getValues?.claimsReimbursements?.data.map((d, i) => {
                return (
                  <div key={i + "-id"}>
                    <div
                      className={`${
                        d.status === "total-earning" && "font-bold"
                      }`}
                    >
                      {formatRupiah(d?.price)}
                    </div>
                    <div className="text-[12px] text-slate-500 capitalize">
                      {d?.label}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        },
      },
      {
        id: "deductions",
        accessorFn: (row) => row,
        header: () => (
          <span className="m-2 flex justify-start capitalize">deductions</span>
        ),
        enableSorting: true,
        cell: (status) => {
          const getValues = status?.row?.original;
          const index = status.row.index;
          return (
            <div className="flex flex-col gap-4 text-left  cursor-pointer">
              <Deduction
                index={index}
                getValues={getValues}
                dataDeduction={getValues}
              />
            </div>
          );
        },
      },
      {
        id: "total",
        accessorFn: (row) => row,
        header: () => (
          <span className="m-2 flex justify-start capitalize">total</span>
        ),
        enableSorting: true,
        cell: (status) => {
          const getValues = status?.row?.original;
          return <div>{formatRupiah(getValues?.total)}</div>;
        },
      },
      {
        accessorFn: (row) => row,
        disableSorting: true,
        header: () => {},
        id: "action",
        cell: (status) => {
          const getValues = status?.row?.original;
          function onRedirect() {
            navigate(`/employee-payslip/detail/${getValues?._id}`, {
              state: detail,
            });
          }
          return (
            <button
              onClick={() => onRedirect()}
              className="cursor-pointer w-fit"
            >
              <DotThreeSVG color={"#000"} />
            </button>
          );
        },
      },
    ];
  }, [dataTable, select]);

  return (
    <div className="overflow-auto grow w-full min-w-fit">
      <DataTable
        columns={columns}
        data={dataTable}
        isBorder={true}
        pagination={false}
      />
    </div>
  );
};
export const ReviewEmployeeDataDetailTable = ({ dataTable = [] }) => {
  // attendance setting
  const columns = useMemo(() => {
    return [
      {
        id: "date",
        accessorFn: (row) => row,
        header: () => (
          <span className="m-2 flex justify-start capitalize">date</span>
        ),
        enableSorting: true,
        footer: () => (
          <span className="flex justify-start capitalize font-bold">
            grand total
          </span>
        ),
        cell: (status) => {
          const result = status?.row;
          const original = result?.original;
          // Get the date from the previous row
          const previousDate = dataTable[result?.index - 1]?.date;
          const currentDate = original?.date;

          // Check conditions
          const shouldDisplayDate =
            result?.index >= 0 &&
            dayjs(previousDate).format("DD/MM/YYYY") !==
              dayjs(currentDate).format("DD/MM/YYYY");
          return (
            <div className="flex flex-row gap-2 justify-center items-center cursor-pointer ">
              {shouldDisplayDate &&
                dayjs(result?.original?.date).format("DD MMMM YYYY")}
            </div>
          );
        },
      },
      {
        id: "overtimeDuration",
        accessorFn: (row) => row,
        header: () => (
          <span className="m-2 flex justify-start capitalize">hours</span>
        ),
        enableSorting: true,
        footer: (status) => {
          const resultHour = dataTable?.reduce((pre, v) => {
            pre += parseFloat(v?.overtimeDuration);
            return pre;
          }, 0);
          return (
            <span className="flex justify-start capitalize font-bold">
              {resultHour}
            </span>
          );
        },
        cell: (status) => {
          const result = status?.row;
          const original = result?.original;
          return (
            <div className="flex flex-row gap-2 justify-center items-center cursor-pointer">
              {original.overtimeDuration}
            </div>
          );
        },
      },
      {
        id: "x",
        accessorFn: (row) => row,
        header: () => (
          <span className="m-2 flex justify-start capitalize">x</span>
        ),
        enableSorting: true,
        cell: (status) => {
          return (
            <div className="flex flex-row gap-2 justify-center items-center cursor-pointer">
              x
            </div>
          );
        },
        footer: (status) => {
          return (
            <span className="flex justify-start capitalize font-bold"></span>
          );
        },
      },
      {
        id: "multiplier",
        accessorFn: (row) => row,
        header: () => (
          <span className="m-2 flex justify-start capitalize">multiplier</span>
        ),
        enableSorting: true,
        cell: (status) => {
          const row = status?.row?.original;
          return (
            <div className="flex flex-row gap-2 justify-center items-center cursor-pointer">
              {row?.multiplier}
            </div>
          );
        },
      },
      {
        id: "x",
        accessorFn: (row) => row,
        header: () => (
          <span className="m-2 flex justify-start capitalize">=</span>
        ),
        enableSorting: true,
        cell: (status) => {
          return (
            <div className="flex flex-row gap-2 justify-center items-center cursor-pointer">
              =
            </div>
          );
        },
        footer: (status) => {
          return (
            <span className="flex justify-start capitalize font-bold"></span>
          );
        },
      },
      {
        id: "multiply",
        accessorFn: (row) => row,
        header: () => (
          <span className="m-2 flex justify-start capitalize">multiply</span>
        ),
        enableSorting: true,
        cell: (status) => {
          const row = status?.row?.original;
          return (
            <div className="flex flex-row gap-2 justify-center items-center cursor-pointer">
              {row?.multiply}
            </div>
          );
        },
        footer: (status) => {
          const resultHour = dataTable?.reduce((pre, v) => {
            pre += parseFloat(v?.multiply);
            return pre;
          }, 0);
          return (
            <span className="flex justify-start capitalize font-bold">
              {resultHour}
            </span>
          );
        },
      },
      {
        id: "total-payment",
        accessorFn: (row) => row,
        header: () => (
          <span className="m-2 flex justify-start capitalize">
            total payment
          </span>
        ),
        enableSorting: true,
        cell: (status) => {
          const row = status?.row?.original;
          return (
            <div className="flex flex-row gap-2 justify-center items-center cursor-pointer">
              {formatRupiah(row?.total)}
            </div>
          );
        },
        footer: (status) => {
          const row = status?.row?.original;

          const total = dataTable?.reduce((pre, v) => {
            pre += parseFloat(v?.total);
            return pre;
          }, 0);
          return (
            <span className="flex justify-start capitalize font-bold">
              {formatRupiah(total)}
            </span>
          );
        },
      },
    ];
  }, [dataTable]);

  return (
    <DataTable
      title="Leave Data"
      columns={columns}
      data={dataTable}
      pagination={false}
      footerClassNameThead={"bg-secondary/20 pt-[10px]"}
    />
  );
};
export const LeaveDataDetailTable = ({ dataTable = [] }) => {
  // attendance setting
  const columns = useMemo(() => {
    return [
      {
        id: "durations",
        accessorFn: (row) => row,
        header: () => (
          <span className="m-2 flex justify-start capitalize">leave date</span>
        ),
        enableSorting: true,
        footer: () => (
          <span className="flex justify-start capitalize font-bold">
            grand total
          </span>
        ),
        cell: (status) => {
          const result = status?.row?.original;
          return (
            <div className="flex  gap-2 justify-center items-center cursor-pointer font-bold ">
              {dayjs(result?.leaveDate, "DD/MM/YYYY")?.format("DD MMMM YYYY")}
            </div>
          );
        },
      },
      {
        id: "overtimeDuration",
        accessorFn: (row) => row,
        header: () => (
          <span className="m-2 flex justify-start capitalize">durations</span>
        ),
        enableSorting: true,

        cell: (status) => {
          const result = status?.row;
          const original = result?.original;

          return (
            <div className="flex flex-row gap-2 justify-center items-center cursor-pointer">
              {original?.durations}
            </div>
          );
        },
        footer: (status) => {
          return (
            <span className=" capitalize font-bold">
              {dataTable?.totalTime} hours
            </span>
          );
        },
      },
      {
        id: "x",
        accessorFn: (row) => row,
        header: () => (
          <span className="m-2 flex justify-start capitalize">
            paid per hour
          </span>
        ),
        enableSorting: true,
        cell: (status) => {
          const result = status?.row;
          const original = result?.original;

          return (
            <div className="flex flex-row gap-2 justify-center items-center cursor-pointer">
              {formatRupiah(original?.paidHour)}
            </div>
          );
        },
      },
      {
        id: "multiplier",
        accessorFn: (row) => row,
        header: () => (
          <span className="m-2 flex justify-start capitalize">
            total unpaid leave
          </span>
        ),
        enableSorting: true,
        cell: (status) => {
          const original = status?.row?.original;
          return (
            <div className="flex flex-row gap-2 justify-center items-center cursor-pointer">
              {formatRupiah(original?.totalUnpaidLeave)}
            </div>
          );
        },
        footer: (status) => {
          return (
            <span className="flex justify-start capitalize font-bold">
              {formatRupiah(dataTable?.totalEarning)}
            </span>
          );
        },
      },
    ];
  }, [dataTable?.data]);

  return (
    <DataTable
      title="Leave Data"
      columns={columns}
      data={dataTable?.data}
      pagination={false}
      footerClassNameThead={"bg-secondary/20 pt-[10px]"}
    />
  );
};
export const AbsenceDetailTable = ({ dataTable = [] }) => {
  // attendance setting
  const columns = useMemo(() => {
    return [
      {
        id: "durations",
        accessorFn: (row) => row,
        header: () => (
          <span className="m-2 flex justify-start capitalize">absence</span>
        ),
        enableSorting: true,
        footer: () => (
          <span className="flex justify-start capitalize font-bold">
            grand total
          </span>
        ),
        cell: (status) => {
          const result = status?.row?.original;
          return (
            <div className="flex  gap-2 justify-center items-center cursor-pointer font-bold ">
              {dayjs(result?.absence, "YYYY/MM/DD")?.format("DD MMMM YYYY")}
            </div>
          );
        },
      },
      {
        id: "overtimeDuration",
        accessorFn: (row) => row,
        header: () => (
          <span className="m-2 flex justify-start capitalize">durations</span>
        ),
        enableSorting: true,

        cell: (status) => {
          const result = status?.row;
          const original = result?.original;

          return (
            <div className="flex flex-row gap-2 justify-center items-center cursor-pointer">
              {convertHoursToFullDuration(original?.durations)}
            </div>
          );
        },
        footer: (status) => {
          return (
            <span className=" capitalize font-bold">
              {dataTable?.totalTime} hours
            </span>
          );
        },
      },
      {
        id: "x",
        accessorFn: (row) => row,
        header: () => (
          <span className="m-2 flex justify-start capitalize">
            paid per hour
          </span>
        ),
        enableSorting: true,
        cell: (status) => {
          const result = status?.row;
          const original = result?.original;

          return (
            <div className="flex flex-row gap-2 justify-center items-center cursor-pointer">
              {formatRupiah(original?.paidHour)}
            </div>
          );
        },
      },
      {
        id: "multiplier",
        accessorFn: (row) => row,
        header: () => (
          <span className="m-2 flex justify-start capitalize">
            total absence
          </span>
        ),
        enableSorting: true,
        cell: (status) => {
          const original = status?.row?.original;
          return (
            <div className="flex flex-row gap-2 justify-center items-center cursor-pointer">
              {formatRupiah(original?.totalAbsence)}
            </div>
          );
        },
        footer: (status) => {
          return (
            <span className="flex justify-start capitalize font-bold">
              {formatRupiah(dataTable?.totalEarning)}
            </span>
          );
        },
      },
    ];
  }, [dataTable?.data]);

  return (
    <DataTable
      title="Leave Data"
      columns={columns}
      data={dataTable?.data || []}
      pagination={false}
      footerClassNameThead={"bg-secondary/20 pt-[10px]"}
    />
  );
};
export const CPFDataDetailTable = ({ dataTable = [] }) => {
  // attendance setting
  const { data } = useFetchPayrollAttendanceSettingLeaveQuery();

  const columns = useMemo(() => {
    return [
      {
        id: "date",
        accessorFn: (row) => row,
        header: () => (
          <span className="m-2 flex justify-start capitalize">leave date</span>
        ),
        enableSorting: true,
        footer: () => (
          <span className="flex justify-start capitalize font-bold">
            grand total
          </span>
        ),
        cell: (status) => {
          const result = status?.row;
          return (
            <div className="flex  gap-2 justify-center items-center cursor-pointer ">
              <div>
                {dayjs(result?.original?.leaveTimes[0]?.date).format(
                  "DD MMMM YYYY",
                )}
              </div>
              {result?.original?.leaveTimes?.length > 1 && (
                <div>
                  {dayjs(
                    result?.original?.leaveTimes[
                      result?.original?.leaveTimes?.length - 1
                    ]?.date,
                  ).format("DD MMMM YYYY")}
                </div>
              )}
            </div>
          );
        },
      },
      {
        id: "overtimeDuration",
        accessorFn: (row) => row,
        header: () => (
          <span className="m-2 flex justify-start capitalize">durations</span>
        ),
        enableSorting: true,

        cell: (status) => {
          const result = status?.row;
          const original = result?.original;

          return (
            <div className="flex flex-row gap-2 justify-center items-center cursor-pointer">
              {original?.totalDuration}
            </div>
          );
        },
        footer: (status) => {
          const leaveTimes = dataTable?.reduce((pre, v) => {
            const total = v?.leaveTimes.reduce((pre, v) => {
              pre += v?.duration?.time;
              return pre;
            }, 0);
            pre += total;
            return pre;
          }, 0);

          return (
            <span className=" capitalize font-bold">{leaveTimes} hours</span>
          );
        },
      },
      {
        id: "x",
        accessorFn: (row) => row,
        header: () => (
          <span className="m-2 flex justify-start capitalize">
            paid per hour
          </span>
        ),
        enableSorting: true,
        cell: (status) => {
          const original = status.row?.original;
          const salary =
            original?.employeeInformation?.userInformation?.paymentDetail
              ?.basicSalary;
          return (
            <div className="flex flex-row gap-2 justify-center items-center cursor-pointer">
              {formatRupiah(calculateEarningsForHours(salary, 1))}
            </div>
          );
        },
      },
      {
        id: "multiplier",
        accessorFn: (row) => row,
        header: () => (
          <span className="m-2 flex justify-start capitalize">
            total unpaid leave
          </span>
        ),
        enableSorting: true,
        cell: (status) => {
          const original = status?.row?.original;
          const salary =
            original?.employeeInformation?.userInformation?.paymentDetail
              ?.basicSalary;
          const leaveTimes = original?.leaveTimes.reduce((pre, v) => {
            pre += v?.duration?.time;
            return pre;
          }, 0);

          return (
            <div className="flex flex-row gap-2 justify-center items-center cursor-pointer">
              {formatRupiah(calculateEarningsForHours(salary, leaveTimes))}
            </div>
          );
        },
        footer: (status) => {
          const total = status?.table
            .getCoreRowModel()
            ?.rows?.reduce((pre, v) => {
              const original = v?.original;
              const salary =
                original?.employeeInformation?.userInformation?.paymentDetail
                  ?.basicSalary;
              const totalTime = original?.leaveTimes?.reduce((pre, v) => {
                pre += v?.duration?.time;
                return pre;
              }, 0);
              pre += calculateEarningsForHours(salary, totalTime);
              return pre;
            }, 0);
          return (
            <span className="flex justify-start capitalize font-bold">
              {formatRupiah(total)}
            </span>
          );
        },
      },
    ];
  }, [dataTable, data]);

  return (
    <DataTable
      title="Leave Data"
      columns={columns}
      data={dataTable}
      pagination={false}
      footerClassNameThead={"bg-secondary/20 pt-[10px]"}
    />
  );
};
