import React from "react";

const EmployeePaySliptSVG = ({ color, ...otherProps }) => {
  return (
    <svg
      width="24"
      height="24"
      className={`w-6 h-6 ${otherProps.className}`}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.82435 2.33987H5.38968C4.00301 2.33387 2.86635 3.4392 2.83368 4.82587V12.0585C2.81035 13.4252 3.89901 14.5525 5.26635 14.5765C5.30701 14.5765 5.34835 14.5765 5.38968 14.5752H10.715C12.0943 14.5612 13.2037 13.4379 13.2017 12.0585V5.85853L9.82435 2.33987Z"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.64886 2.33203V4.27136C9.64886 5.21803 10.4149 5.98536 11.3615 5.98803H13.1975"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.52851 9.66536H6.26184"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default EmployeePaySliptSVG;
