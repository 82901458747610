import React from "react";
import {
  Accordion,
  Button,
  InputSelect,
  InputText,
} from "@bluesilodev/timhutcomponents";
import CircleXMarxSVG from "assets/icons/CircleXMarkSVG/CircleXMarkSVG";
import TickCircleSVG from "assets/icons/TickCircleSVG/TickCircleSVG";
import { useLocation } from "react-router-dom";

const ClaimDetails = () => {
  const location = useLocation();
  const { state } = location;

  return (
    <div className="flex flex-col m-1">
      <div className="w-full  bg-white overflow-x-auto h-max mt-5">
        <div className="flex justify-between items-center">
          <div className="font-semibold">
            Requested on 24 August 2023, 13:03
          </div>
          <div className="flex flex-row gap-2">
            <Button
              label={
                <div className="flex px-4 gap-1">
                  <CircleXMarxSVG color={"white"} />
                  <div>Reject</div>
                </div>
              }
              style={"solid"}
              className={"w-[230px]"}
              backgroundColor={"#DD4848"}
            />
            <Button
              label={
                <div className="flex px-4 gap-1">
                  <TickCircleSVG color={"white"} />
                  <div>
                    <span>Approve</span>
                  </div>
                </div>
              }
              style={"solid"}
              className={"w-[230px]"}
              backgroundColor={"#4BD394"}
            />
          </div>
        </div>
      </div>
      <div className="w-full mt-5">
        <Accordion
          title={<div className="p-2">Employee Details</div>}
          icons={[]}
          children={
            <div className="p-4 w-full flex flex-row">
              <img
                className="self-center rounded-full w-[150px] h-[150px]"
                src={state?.img}
                alt="User Profile"
              />

              <div className="flex flex-col p-1 ml-10 gap-2 w-full">
                <div className="grid grid-cols-3 gap-5">
                  <div className={"w-full"}>
                    <InputText
                      title={"Claim Status"}
                      label={<React.Fragment></React.Fragment>}
                      value={state?.byHRAdmin}
                      className="w-full flex items-center gap-2"
                      disabled
                    />
                  </div>
                  <div className="w-full"></div>
                  <div className="w-full"></div>
                  <InputText
                    title={"First Name"}
                    label={<React.Fragment></React.Fragment>}
                    value={"Henry"}
                    disabled
                  />
                  <InputText
                    title={"Last Name"}
                    label={<React.Fragment></React.Fragment>}
                    value={"Chua"}
                    disabled
                  />
                  <InputText
                    title={"Empoloyee ID"}
                    label={<React.Fragment></React.Fragment>}
                    value={"29432"}
                    disabled
                  />
                  <InputSelect
                    title={"Location"}
                    value={"Cafe Halim"}
                    disabled
                  />
                  <InputSelect
                    title={"Departments"}
                    options={[]}
                    value={"Operations"}
                    disabled
                  />
                  <InputSelect
                    title={"Job Positions"}
                    options={[]}
                    value={"Cook"}
                    disabled
                  />
                </div>
              </div>
            </div>
          }
        />
      </div>
      <div className="w-full mt-5">
        <Accordion
          title={<div className="p-2">Claim Details</div>}
          icons={[]}
          children={
            <div className="p-4 w-full flex flex-row">
              <div className="flex flex-col p-1 gap-2 w-full">
                <div className="grid grid-cols-3 gap-5">
                  <div className={"w-full"}>
                    <InputText
                      title={"Claims Name"}
                      label={<React.Fragment></React.Fragment>}
                      value={state?.byHRAdmin}
                      className="w-full flex items-center gap-2"
                      disabled
                    />
                  </div>
                  <div className="w-full">
                    <InputText
                      title={"Amount"}
                      label={<React.Fragment></React.Fragment>}
                      value={state?.byHRAdmin}
                      className="w-full flex items-center gap-2"
                      disabled
                    />
                  </div>
                  <div className="w-full">
                    <InputText
                      title={"Amount"}
                      label={<React.Fragment></React.Fragment>}
                      value={state?.byHRAdmin}
                      className="w-full flex items-center gap-2"
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default ClaimDetails;
