import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useFetchPayrollOptionMutation,
  useGeneratePdfPayrollMutation,
} from "store/api/payrollApi";
const PayrollReportDetail = () => {
  const params = useParams();
  const [screenSize, setScreenSize] = useState({
    width: window.screen.width,
    height: window.screen.height,
  });

  const [requestPayrollOption, resultPayrollOption] =
    useFetchPayrollOptionMutation();

  useEffect(() => {
    requestPayrollOption({ id: params?.id });
  }, [params?.id]);
  const data = useMemo(() => {
    return resultPayrollOption?.data?.data[0];
  }, [resultPayrollOption]);

  const [generatePdf, { data: dataPdf }] = useGeneratePdfPayrollMutation();

  useEffect(() => {
    generatePdf(data?._id);
  }, [data?._id]);
  const pdf = useMemo(() => {
    if (dataPdf) {
      const data = new Blob([dataPdf], { type: "application/pdf" });
      return URL.createObjectURL(data);
    }
    return null;
  }, [dataPdf]);
  const handleResize = () => {
    setScreenSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };
  useEffect(() => {
    // Adding event listener when the component mounts
    window.addEventListener("resize", handleResize);

    // Cleaning up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array to run effect only once on mount

  return (
    <div className="py-5">
      {!resultPayrollOption.isLoading ? (
        <iframe
          src={pdf}
          width="100%"
          height={`${screenSize.height - 150}px`}
        ></iframe>
      ) : (
        <div className="text-center font-bold text-2xl">loading...</div>
      )}
    </div>
  );
};
export default PayrollReportDetail;
