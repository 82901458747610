import * as Yup from "yup";
/**
 * Employee data object.
 *
 * @typedef {Object} EmployeeData
 * @property {string} employee - The employee's name.
 * @property {string} twm - The TWM value.
 * @property {string} sdlPayable - The SDL payable amount.
 */
/**
 * Validation schema for employee form.
 * This schema ensures that the following fields are required:
 * - `employee`: A string representing the employee's name.
 * - `twm`: A string representing the TWM value.
 * - `sdlPayable`: A string representing the SDL payable amount.
 *
 * @type {Yup.ObjectSchema}
 */
export const SdlValidationSchema = Yup.object().shape({
  twm: Yup.number().required("TWM is required"),
  sdlPayable: Yup.number().required("SDL Payable is required"),
});

/**
 * Schema for validating SDL objects using Yup.
 *
 * @typedef {Object} SdlSchema
 * @property {string} sdlName - The name of the SDL, required.
 * @property {number} sdlRate - The SDL rate, required.
 * @property {string} sdlStartDate - The start date, required.
 * @property {string} sdlEndDate - The end date, required.
 * @property {boolean} [sdlStatus=false] - The status of the SDL, defaults to false.
 * @property {Array} [sdlList=[]] - The list of SDL items, defaults to an empty array.
 *
 * @returns {Object} - Validation object for SDL.
 */
export const SdlSchemaYup = Yup.object().shape({
  sdlName: Yup.string().required("SDL name is required."),
  sdlRate: Yup.number().required("SDL rate is required."),
  sdlStartDate: Yup.string().required("Start date is required."),
  sdlEndDate: Yup.string().required("End date is required."),
  sdlStatus: Yup.boolean().default(false),
  sdlList: Yup.array(SdlValidationSchema).default([]),
});
