import React from "react";
import PropTypes from "prop-types";

const Index = ({ color, className, ...otherProps }) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.5 4.66797L9.16667 4.66797"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M6.5 10L8.5 10"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M6.5 7.33203L10.5 7.33203"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M13.1668 7.33333V6.66667C13.1668 5.11217 13.1668 4.33492 12.8858 3.73215C12.5877 3.09292 12.0739 2.57915 11.4347 2.28108C10.8319 2 10.0547 2 8.50016 2V2C6.94566 2 6.16841 2 5.56564 2.28108C4.92642 2.57915 4.41265 3.09292 4.11457 3.73215C3.8335 4.33492 3.8335 5.11217 3.8335 6.66667V9.33333C3.8335 10.8878 3.8335 11.6651 4.11457 12.2679C4.41265 12.9071 4.92642 13.4208 5.56564 13.7189C6.16841 14 6.94566 14 8.50016 14V14"
        stroke={color}
        stroke-width="1.5"
      />
      <circle
        cx="12.1667"
        cy="11.6667"
        r="1.66667"
        stroke={color}
        stroke-width="1.5"
      />
      <path
        d="M14.5 14L13.5 13"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );
};

Index.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

Index.defaultProps = {
  color: "#111111",
  className: "",
};

export default Index;
