import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "services/axios";

const ENDPOINT = process.env.REACT_APP_EMPLOYEE_API_URL;
const API = `${ENDPOINT}/api`;

const EmployeeComponentApi = createApi({
  reducerPath: "employee-component",
  baseQuery: axiosBaseQuery({
    baseUrl: API,
  }),
  endpoints(builder) {
    return {
      fetchEmployeeComponent: builder.query({
        query: (params) => {
          return {
            url: "/user",
            method: "GET",
            params,
          };
        },
      }),

      fetchEmployeeById: builder.mutation({
        query: (id) => {
          return {
            url: `/user/${id}`,
            method: "GET",
          };
        },
      }),
      fetchEmployeeUserInformationById: builder.mutation({
        query: (id) => {
          return {
            url: `/userInformation/${id}`,
            method: "GET",
          };
        },
      }),
      fetchEmployeeLocation: builder.query({
        query: (params) => {
          return {
            url: "/location",
            method: "GET",
            params: params,
          };
        },
      }),
    };
  },
});

export const {
  useFetchEmployeeComponentQuery,
  useFetchEmployeeLocationQuery,
  useFetchEmployeeByIdMutation,
} = EmployeeComponentApi;
export { EmployeeComponentApi };
