import React from "react";
import PropTypes from "prop-types";

function TrashSvg({ color, className, ...props }) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke={color} />
      <path
        d="M14 16H15.3333H26"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.3359 16.0007V14.6673C17.3359 14.3137 17.4764 13.9746 17.7265 13.7245C17.9765 13.4745 18.3156 13.334 18.6693 13.334H21.3359C21.6896 13.334 22.0287 13.4745 22.2787 13.7245C22.5288 13.9746 22.6693 14.3137 22.6693 14.6673V16.0007M24.6693 16.0007V25.334C24.6693 25.6876 24.5288 26.0267 24.2787 26.2768C24.0287 26.5268 23.6896 26.6673 23.3359 26.6673H16.6693C16.3156 26.6673 15.9765 26.5268 15.7265 26.2768C15.4764 26.0267 15.3359 25.6876 15.3359 25.334V16.0007H24.6693Z"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.3359 19.334V23.334"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.6641 19.334V23.334"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

TrashSvg.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

TrashSvg.defaultProps = {
  color: "red",
  className: "w-[20px] h-[20px] ",
};

export default TrashSvg;
