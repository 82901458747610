import React from "react";
import { Button } from "@bluesilodev/timhutcomponents";
import { useNavigate } from "react-router-dom";
import { FwlDataTable } from "components/common/customTables";
import DocumentIconSVG from "assets/icons/DocumentIconSVG/DocumentIconSVG";
import { useAlertApi } from "services/alert-api";
import { useDispatch } from "react-redux";
import { setCpfbReducer } from "store/slice/cpfbSlice";
import { useDeleteFwlMutation, useFetchFwlQuery } from "store/api/fwl";

const Fwl = () => {
  const navigate = useNavigate();
  const [requestFwlType, resultFwlType] = useDeleteFwlMutation();
  const dispatch = useDispatch();

  useAlertApi(resultFwlType);

  const { data, refetch } = useFetchFwlQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  const handleDelete = (id) => {
    requestFwlType({ id: id }).then(() => {
      refetch();
    });
  };
  /**
   * @function handleEdit
   * @param {import("type/typeCpf").EmployeeContribution} data
   */
  const handleEdit = (data) => {
    navigate(`/fwl-edit/${data?._id}`, { state: data });
  };
  return (
    <>
      <div className="w-full h-max mt-5">
        <div className="w-full flex justify-between">
          <div className="w-[100%] flex flex-row gap-5 justify-end">
            <Button
              className={"w-[270px]"}
              style="solid"
              onClick={() => {
                dispatch(setCpfbReducer(null));
                navigate("/fwl-edit");
              }}
              label={
                <div className="flex px-4 gap-1">
                  <DocumentIconSVG color={"white"} />
                  <div>Add new FWL</div>
                </div>
              }
            />
          </div>
        </div>
        <div className="flex flex-col mt-6 p-1 gap-2 w-[100%]">
          <FwlDataTable
            dataTable={
              data?.data?.map((a) => ({
                ...a,
              })) ?? []
            }
            handleDelete={handleDelete}
            handleEdit={handleEdit}
          />
        </div>
      </div>
    </>
  );
};

export default Fwl;
