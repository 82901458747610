import { createSlice } from "@reduxjs/toolkit";

const cpfbSlice = createSlice({
  name: "cpfb-slice",
  initialState: {
    data: null,
    dataShg: null,
  },
  reducers: {
    setCpfbReducer: (state, action) => {
      state.data = action.payload;
    },
    setShgReducer: (state, action) => {
      state.dataShg = action.payload;
    },
  },
});

export const { setCpfbReducer,setShgReducer } = cpfbSlice.actions;
export default cpfbSlice.reducer;
