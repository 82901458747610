import React from "react";

const PayrollSetupSVG = ({ color, ...otherProps }) => {
  return (
    <svg
      width="24"
      height="24"
      className={`w-6 h-6 ${otherProps.className}`}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.8711 5.58322L13.4561 4.86316C13.1051 4.25388 12.3271 4.04369 11.717 4.39329V4.39329C11.4266 4.56438 11.08 4.61292 10.7538 4.5282C10.4275 4.44349 10.1484 4.23249 9.97785 3.94173C9.86816 3.75691 9.80922 3.54641 9.80699 3.33151V3.33151C9.81689 2.98696 9.6869 2.65308 9.44664 2.40593C9.20638 2.15877 8.87632 2.01939 8.53163 2.01953H7.69563C7.35794 2.01953 7.03417 2.15409 6.79596 2.39344C6.55775 2.6328 6.42474 2.95721 6.42637 3.29489V3.29489C6.41636 3.9921 5.84828 4.55202 5.151 4.55195C4.9361 4.54972 4.7256 4.49078 4.54078 4.38109V4.38109C3.93067 4.03149 3.1527 4.24168 2.80165 4.85096L2.35619 5.58322C2.00557 6.19174 2.21289 6.96922 2.81996 7.32235V7.32235C3.21456 7.55017 3.45765 7.97121 3.45765 8.42685C3.45765 8.8825 3.21456 9.30353 2.81996 9.53135V9.53135C2.21366 9.88211 2.00611 10.6577 2.35619 11.2644V11.2644L2.77724 11.9905C2.94172 12.2873 3.21769 12.5063 3.54409 12.5991C3.87048 12.6919 4.22039 12.6507 4.51637 12.4848V12.4848C4.80735 12.315 5.15408 12.2685 5.47952 12.3556C5.80495 12.4427 6.08212 12.6562 6.2494 12.9486C6.35909 13.1334 6.41803 13.3439 6.42026 13.5588V13.5588C6.42026 14.2632 6.99126 14.8342 7.69563 14.8342H8.53163C9.23362 14.8342 9.80363 14.2669 9.80699 13.5649V13.5649C9.80536 13.2262 9.9392 12.9008 10.1787 12.6613C10.4183 12.4218 10.7436 12.2879 11.0824 12.2896C11.2967 12.2953 11.5064 12.354 11.6926 12.4604V12.4604C12.3011 12.811 13.0786 12.6037 13.4317 11.9966V11.9966L13.8711 11.2644C14.0411 10.9725 14.0878 10.6248 14.0008 10.2984C13.9137 9.97197 13.7001 9.69372 13.4073 9.52525V9.52525C13.1145 9.35679 12.9009 9.07853 12.8138 8.75211C12.7268 8.42568 12.7735 8.07803 12.9435 7.78612C13.0541 7.59304 13.2142 7.43295 13.4073 7.32235V7.32235C14.0107 6.96941 14.2176 6.19648 13.8711 5.58933V5.58933V5.58322Z"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle
        cx="8.11669"
        cy="8.42541"
        r="1.75744"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default PayrollSetupSVG;
