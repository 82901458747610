import React from "react";
import { WidgetCard } from "@bluesilodev/timhutcomponents";
const CardWidget = ({ title, cardData, handleClick, icon, isActive }) => {
  return (
    <div className="flex cursor-pointer">
      <WidgetCard
        height="125px"
        onClick={handleClick}
        radius="lg"
        width="220px"
        className={`cursor-pointer rounded-lg shadow-xl flex items-center justify-center border-gray-300 border-2 ${
          isActive && "border-orange-500"
        }`}
      >
        <div className="flex flex-col items-center justify-center">
          <div className="border-2 border-gray-300 rounded-full p-2">
            {icon}
          </div>
          <div className="flex flex-col items-center justify-center">
            <p className="input-label">{title}</p>
            <p className="text-lg font-semibold">{cardData}</p>
          </div>
        </div>
      </WidgetCard>
    </div>
  );
};

export default CardWidget;
