import React from "react";
import PropTypes from "prop-types";

const Index = ({ color, className, ...otherProps }) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.3245 1.83986H5.88984C4.50318 1.83453 3.36718 2.9392 3.33384 4.32586V11.4839C3.30384 12.8852 4.41584 14.0452 5.81651 14.0752C5.84118 14.0752 5.86584 14.0759 5.88984 14.0752H11.2152C12.6085 14.0259 13.7098 12.8779 13.7018 11.4839V5.3572L10.3245 1.83986Z"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.1501 1.83203V3.77136C10.1501 4.71803 10.9161 5.48536 11.8628 5.48803H13.6988"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.26082 6.60547V10.6328"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.82442 8.17547L8.26109 6.60547L6.69775 8.17547"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

Index.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

Index.defaultProps = {
  color: "#111111",
  className: "",
};

export default Index;
