import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGeneratePdfPayslipDetailMutation } from "store/api/payrollApi";

const PayrollPayslipDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const { state } = location;
  const [screenSize, setScreenSize] = useState({
    width: window.screen.width,
    height: window.screen.height,
  });
  useEffect(() => {
    if (!id) {
      navigate("/employee-payslip");
    }
  }, [id]);
  const handleResize = () => {
    setScreenSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };
  useEffect(() => {
    // Adding event listener when the component mounts
    window.addEventListener("resize", handleResize);

    // Cleaning up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array to run effect only once on mount

  const [generatePdf, { isLoading, data: dataPdf }] =
    useGeneratePdfPayslipDetailMutation();

  const pdf = useMemo(() => {
    if (dataPdf) {
      const data = new Blob([dataPdf], { type: "application/pdf" });
      return URL.createObjectURL(data);
    }
    return null;
  }, [dataPdf]);
  useEffect(() => {
    if (!id) {
      return;
    }
    if (!state) {
      return;
    }
    const parentId = state?._id;
    generatePdf({ idSelect: { idSelect: id }, idParent: parentId });
  }, [id, state]);

  return (
    <div className="py-5">
      {!isLoading ? (
        <iframe
          src={pdf}
          width="100%"
          height={`${screenSize.height - 150}px`}
        ></iframe>
      ) : (
        <div className="text-center font-bold text-2xl">loading...</div>
      )}
    </div>
  );
};
export default PayrollPayslipDetail;
