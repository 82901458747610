import styled from "styled-components";

const Container = styled.label`
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border-radius: 2px;

  ${HiddenCheckbox}:checked + & {
    background-color: #ffff;
  }

  &::after {
    content: "";
    position: absolute;
    display: none;
  }

  ${HiddenCheckbox}:checked + &::after {
    display: block;
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid #ff8b37;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
`;

const CustomCheckbox = ({ className, checked, ...props }) => (
  <Container className={className}>
    <HiddenCheckbox checked={checked} {...props} />
    <Checkmark />
  </Container>
);

export default CustomCheckbox;
