import * as Yup from "yup";

/**
 * @typedef {Object} ConstructionSetting
 * @property {string} tier - The tier level, required.
 * @property {number} monthly - The monthly rate, required.
 * @property {number} daily - The daily rate, required.
 */

/**
 * @typedef {Object} ManufactureSetting
 * @property {string} quote - The quote for the manufacture, required.
 * @property {number} monthlyBasicSkilled - The monthly rate for basic skilled workers, required.
 * @property {number} dailyBasicSkilled - The daily rate for basic skilled workers, required.
 * @property {number} monthlyHigherSkilled - The monthly rate for higher skilled workers, required.
 * @property {number} dailyHigherSkilled - The daily rate for higher skilled workers, required.
 */

/**
 * @typedef {Object} PayrollFwl
 * @property {string} organizationID - The organization ID, required.
 * @property {string} fwlName - The FWL name, required.
 * @property {Date} fwlStartDate - The FWL start date, required.
 * @property {Date} fwlEndDate - The FWL end date, required.
 * @property {boolean} fwlStatus - The FWL status (active/inactive), required.
 * @property {Array.<ConstructionSetting>} constructionSettings - An array of construction settings.
 * @property {Array.<ManufactureSetting>} manufactureSettings - An array of manufacture settings.
 * @property {Array.<ConstructionSetting>} marineShipyardSettings - An array of marine shipyard settings.
 * @property {Array.<ConstructionSetting>} processSettings - An array of process settings.
 * @property {Array.<ManufactureSetting>} serviceSettings - An array of service settings.
 */

/**
 * Yup schema for construction settings validation
 */
export const constructionSchema = Yup.object().shape({
  tier: Yup.string().required("Tier is required"),
  monthly: Yup.number().required("Monthly price is required"),
  daily: Yup.number().required("Daily price is required"),
});

/**
 * Yup schema for manufacture settings validation
 */
export const manufactureSchema = Yup.object().shape({
  quote: Yup.string().required("Quote is required"),
  monthlyBasicSkilled: Yup.number().required(
    "Monthly basic skilled price is required"
  ),
  dailyBasicSkilled: Yup.number().required(
    "Daily basic skilled price is required"
  ),
  monthlyHigherSkilled: Yup.number().required(
    "Monthly higher skilled price is required"
  ),
  dailyHigherSkilled: Yup.number().required(
    "Daily higher skilled price is required"
  ),
});

/**
 * Yup schema for payroll FWL validation
 * @type {Yup.ObjectSchema<PayrollFwl>}
 */
export const payrollFwlSchema = Yup.object().shape(
  {
    fwlName: Yup.string().required("FWL name is required"),
    fwlStartDate: Yup.string().required("FWL start date is required"),
    fwlEndDate: Yup.string().required("FWL end date is required"),
    fwlStatus: Yup.boolean().required("FWL status is required"),
    constructionSettings: Yup.array().of(constructionSchema),
    manufactureSettings: Yup.array().of(manufactureSchema),
    marineShipyardSettings: Yup.array().of(constructionSchema),
    processSettings: Yup.array().of(constructionSchema),
    serviceSettings: Yup.array().of(manufactureSchema),
  },
  {
    strict: true,
  }
);
