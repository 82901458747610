import React, { useContext, useEffect, useMemo, useState } from "react";
import { Button } from "@bluesilodev/timhutcomponents";
import { ModalDialog } from "@bluesilodev/timhutcomponents";
import UserAddIcon from "../../../assets/icons/UserAddSVG/UserAdd";
import { InputSelect, InputText } from "@bluesilodev/timhutcomponents";
import { useFormik } from "formik";
import CloseRoundSvg from "assets/icons/CloseRoundSvg/CloseRoundSvg";
import { AdditionalSchema } from "type/typePayroll";
import TrashSvg from "assets/icons/TrashSvg/TrashSvg";
import { formatRupiah, currencyToNumber } from "utils/numberUtils";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { DotThreeSVG } from "assets/icons/DotThreeSVG";
import { ContextReview } from "../review";
dayjs.extend(utc);

export const AdditionalModal = ({ userId, index, getValues }) => {
  const [isShow, setIsShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const { setAdditionalComponents, additionalComponents } =
    useContext(ContextReview);

  const { handleBlur, setFieldValue, values, handleSubmit, errors } = useFormik(
    {
      initialValues: {
        userId: "",
        listAdditional: [
          {
            amount: "Rp 0",
            name: "",
            componentType: "earning",
            amountType: "fixed",
          },
        ],
      },
      validationSchema: AdditionalSchema,
      onSubmit: async (values) => {
        try {
          setAdditionalComponents((preData) => {
            let temp = [...preData];
            const filterComponent = values?.listAdditional?.map((d) => {
              if (d?.amountType === "percent") {
                return {
                  amount: d?.amount,
                  name: d?.name,
                  amountType: d.amountType,
                  componentType: d.componentType,
                };
              } else {
                return d;
              }
            });
            temp[index] = filterComponent?.map((d) => ({
              idUser: values?.userId,
              type: d?.componentType,
              status: d?.amountType,
              name: d?.name,
              amount: currencyToNumber(d?.amount),
            }));

            return temp;
          });
          setIsShow(false);
        } catch (e) {
          setLoading(false);
          console.error(e);
        }
      },
    },
  );

  useEffect(() => {
    setFieldValue("userId", userId);
  }, [userId]);

  const addClaims = () => {
    const temp = [...values.listAdditional];

    temp.push({
      amount: "Rp 0",
      name: "",
      componentType: "earning",
      amountType: "fixed",
    });
    setFieldValue("listAdditional", temp);
  };
  const removeClaims = (i = 0) => {
    const temp = [...values.listAdditional];
    temp.splice(i, 1);
    setFieldValue("listAdditional", temp);
  };
  useEffect(() => {
    if (additionalComponents?.length === 0) {
      return;
    }

    const transformedData = additionalComponents[index]
      ? additionalComponents[index]?.map((item) => ({
          amount:
            item.status === "fixed" ? formatRupiah(item.amount) : item?.amount,
          name: item.name || "",
          componentType: item.type || "earning",
          amountType: item.status || "fixed",
        }))
      : [
          {
            amount: "Rp 0",
            name: "",
            componentType: "earning",
            amountType: "fixed",
          },
        ];

    setFieldValue("listAdditional", transformedData);
  }, [additionalComponents, index]);
  return (
    <>
      <button
        onClick={() => setIsShow(true)}
        type="button"
        className="cursor-pointer w-fit relative"
      >
        <DotThreeSVG color={"#000"} />
      </button>

      <ModalDialog
        show={isShow}
        className={"min-w-[1000px] w-[90vw] h-[90vh]"}
        onClose={() => setIsShow(false)}
        title={`Add Additional Component`}
        children={
          <div className={"flex flex-col gap-4 my-4 "}>
            {values?.listAdditional.map((d, i) => {
              return (
                <div
                  className={"grid grid-cols-2 gap-4  border-b-2 py-3"}
                  key={i}
                >
                  <button
                    type="button"
                    onClick={() => removeClaims(i)}
                    className={"rounded-full col-span-full justify-self-end"}
                  >
                    <TrashSvg color={"#000"} />
                  </button>
                  <div>
                    <InputText
                      value={d?.name || ""}
                      name={`listAdditional.${i}.name`}
                      id={`listAdditional.${i}.name`}
                      onChange={(d) => {
                        setFieldValue(
                          `listAdditional.${i}.name`,
                          d?.target?.value,
                        );
                      }}
                      title={"Component Name *"}
                      className="w-full flex items-center gap-2"
                    />
                    {errors?.listAdditional?.length > 0 && (
                      <div className="text-red-400 mt-4 capitalize col-span-full text-center">
                        <div> {errors?.listAdditional[i]?.name}</div>
                      </div>
                    )}
                  </div>
                  <div>
                    <InputSelect
                      classname={"w-full"}
                      title={"Components Type"}
                      value={d?.componentType || ""}
                      onChange={(d) => {
                        setFieldValue(
                          `listAdditional.${i}.componentType`,
                          d?.target?.value,
                        );
                      }}
                      onBlur={handleBlur}
                      name={`listAdditional.${i}.componentType`}
                      id={`listAdditional.${i}.componentType`}
                      options={[
                        { label: "Earning", value: "earning" },
                        { label: "Deduction", value: "deduction" },
                      ]}
                    />
                    {errors?.listAdditional?.length > 0 && (
                      <div className="text-red-400 mt-4 capitalize col-span-full text-center">
                        <div> {errors?.listAdditional[i]?.componentType}</div>
                      </div>
                    )}
                  </div>
                  <div>
                    <InputSelect
                      classname={"w-full"}
                      title={"Amount Type"}
                      value={d?.amountType || ""}
                      onBlur={handleBlur}
                      onChange={(d) => {
                        setFieldValue(`listAdditional.${i}.amount`, "0");
                        setFieldValue(
                          `listAdditional.${i}.amountType`,
                          d?.target?.value,
                        );
                      }}
                      name={`listAdditional.${i}.amountType`}
                      id={`listAdditional.${i}.amountType`}
                      options={[
                        { label: "Fixed", value: "fixed" },
                        { label: "Percentage", value: "percent" },
                      ]}
                    />
                    {errors?.listAdditional?.length > 0 && (
                      <div className="text-red-400 mt-4 capitalize col-span-full text-center">
                        <div> {errors?.listAdditional[i]?.amountType}</div>
                      </div>
                    )}
                  </div>
                  <div>
                    {d?.amountType === "fixed" ? (
                      <InputText
                        value={d?.amount || ""}
                        name={`listAdditional.${i}.amount`}
                        id={`listAdditional.${i}.amount`}
                        onChange={(d) => {
                          setFieldValue(
                            `listAdditional.${i}.amount`,
                            formatRupiah(currencyToNumber(d?.target?.value)),
                          );
                        }}
                        title={"Amount *"}
                        className="w-full flex items-center gap-2"
                      />
                    ) : (
                      <InputText
                        value={d?.amount || ""}
                        name={`listAdditional.${i}.amount`}
                        id={`listAdditional.${i}.amount`}
                        onChange={(d) => {
                          setFieldValue(
                            `listAdditional.${i}.amount`,
                            d?.target?.value,
                          );
                        }}
                        title={"Percentage *"}
                        className="w-full flex items-center gap-2"
                      />
                    )}
                    {errors?.listAdditional?.length > 0 && (
                      <div className="text-red-400 mt-4 capitalize col-span-full text-center">
                        <div> {errors?.listAdditional[i]?.amount}</div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
            <Button
              onClick={() => addClaims()}
              className={"w-full"}
              backgroundColor={"#DD7224"}
              label={
                <div className="flex p-4 gap-1 capitalize text-white">
                  <UserAddIcon color={"#fff"} />
                  <div>add additional component</div>
                </div>
              }
            />
            <div className={"grid grid-cols-2 gap-4 col-span-full mb-4"}>
              <Button
                onClick={() => setIsShow(false)}
                className={"w-full"}
                type={"button"}
                backgroundColor={"#fff"}
                label={
                  <div className="flex p-4 gap-1 capitalize items-center text-secondary">
                    <CloseRoundSvg color={"#DD7224"} />
                    <div>Cancel</div>
                  </div>
                }
              />
              <div className="relative">
                {loading && (
                  <div
                    className="absolute top-0 right-0 bottom-0 left-0 bg-secondary z-10 
                  rounded-md p-4 text-white flex justify-center items-center"
                  >
                    <div>loading...</div>
                  </div>
                )}
                <Button
                  className={"w-full"}
                  type={"button"}
                  onClick={() => handleSubmit()}
                  backgroundColor={"#DD7224"}
                  label={
                    <div className="flex p-4 gap-1 capitalize text-white items-center">
                      +<div>add adtional component</div>
                    </div>
                  }
                />
              </div>
            </div>
          </div>
        }
      />
    </>
  );
};
