import React from "react";
import PropTypes from "prop-types";

function TimeSquareSvg({ color, className, ...props }) {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.3919 1.83398H5.6126C3.5986 1.83398 2.33594 3.25998 2.33594 5.27798V10.7233C2.33594 12.7413 3.59194 14.1673 5.6126 14.1673H11.3913C13.4119 14.1673 14.6693 12.7413 14.6693 10.7233V5.27798C14.6693 3.25998 13.4119 1.83398 11.3919 1.83398Z"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.7607 9.34584L8.5 7.99718V5.08984"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

TimeSquareSvg.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

TimeSquareSvg.defaultProps = {
  color: "red",
  className: "w-[20px] h-[20px] ",
};

export default TimeSquareSvg;
