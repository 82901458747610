import React from "react";

export const DotThreeSVG = ({ color, ...otherProps }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99976 4C9.10425 4 9.99976 3.10456 9.99976 2C9.99976 0.895437 9.10425 0 7.99976 0C6.89526 0 5.99976 0.895437 5.99976 2C5.99976 3.10456 6.89526 4 7.99976 4Z"
        fill={color}
      />
      <path
        d="M9.99976 7.98438C9.99976 9.08894 9.10425 9.98438 7.99976 9.98438C6.89526 9.98438 5.99976 9.08894 5.99976 7.98438C5.99976 6.87981 6.89526 5.98438 7.99976 5.98438C9.10425 5.98438 9.99976 6.87981 9.99976 7.98438Z"
        fill={color}
      />
      <path
        d="M9.99976 14C9.99976 15.1046 9.10425 16 7.99976 16C6.89526 16 5.99976 15.1046 5.99976 14C5.99976 12.8954 6.89526 12 7.99976 12C9.10425 12 9.99976 12.8954 9.99976 14Z"
        fill={color}
      />
    </svg>
  );
};
