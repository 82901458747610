import React, { useState, useEffect } from "react";
import {
  Button,
  SearchBox,
  ModalDialog,
  InputText,
} from "@bluesilodev/timhutcomponents";
import { useNavigate } from "react-router-dom";
import { ClaimsTypeDataTable } from "components/common/customTables";
import DocumentIconSVG from "assets/icons/DocumentIconSVG/DocumentIconSVG";
import { useAlertApi } from "services/alert-api";
import {
  useFetchClaimsTypeQuery,
  useDeleteClaimsTypeMutation,
} from "store/api/claimsTypeApi";
const ClaimsType = () => {
  const navigate = useNavigate();
  const [requestClaimsType, resultClaimsType] = useDeleteClaimsTypeMutation();

  useAlertApi(resultClaimsType);
  const { data, refetch } = useFetchClaimsTypeQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  const handleDelete = (id) => {
    requestClaimsType({ id: id }).then(() => {
      refetch();
    });
  };

  const handleEdit = (id) => {
    navigate("/create-claim-type", {
      state: data?.data?.find((i) => i?._id === id),
    });
  };
  return (
    <>
      <div className="w-full h-max mt-5">
        <div className="w-full flex justify-between">
          <div className="w-[100%] flex flex-row gap-5 justify-end">
            <Button
              className={"w-[270px]"}
              style="solid"
              onClick={() => navigate("/create-claim-type")}
              label={
                <div className="flex px-4 gap-1">
                  <DocumentIconSVG color={"white"} />
                  <div>Add New Claims Type</div>
                </div>
              }
            />
          </div>
        </div>
        <div className="flex flex-col mt-6 p-1 gap-2 w-[100%]">
          <ClaimsTypeDataTable
            dataTable={
              data?.data?.map((a) => ({
                ...a,
                isActive: a.isActive == true ? "Active" : "In Active",
              })) ?? []
            }
            handleDelete={handleDelete}
            handleEdit={handleEdit}
          />
        </div>
      </div>
    </>
  );
};

export default ClaimsType;
