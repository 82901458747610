import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "services/axios";

const ENDPOINT = process.env.REACT_APP_API_URL;
const API = `${ENDPOINT}/api/payment-schedule`;

const PaymentScheduleApi = createApi({
  reducerPath: "payment-schedule",
  baseQuery: axiosBaseQuery({
    baseUrl: API,
  }),
  endpoints(builder) {
    return {
      fetchPaymentSchedule: builder.query({
        providesTags: () => ["paymentSchedule"],

        query: () => {
          return {
            url: "/read",
            method: "GET",
          };
        },
      }),
      fetchPaymentScheduleBiUserId: builder.query({
        providesTags: () => ["paymentScheduleById"],
        query: (body) => {
          return {
            url: "/readById",
            method: "POST",
            body: body,
          };
        },
      }),
      updatePaymentSchedule: builder.mutation({
        query: (body) => {
          return {
            url: `/update`,
            method: "PUT",
            body: body,
          };
        },
      }),
      addPaymentSchedule: builder.mutation({
        invalidatesTags: (result, error, body) => {
          return [{ type: "" }];
        },
        query: (body) => {
          return {
            url: "/create",
            method: "POST",
            body: body,
          };
        },
      }),
      deletePaymentSchedule: builder.mutation({
        invalidatesTags: (result, error, body) => {
          return [{ type: "" }];
        },
        query: (body) => {
          return {
            url: "/delete",
            method: "POST",
            body: body,
          };
        },
      }),
    };
  },
});
export const {
  useAddPaymentScheduleMutation,
  useFetchPaymentScheduleBiUserIdQuery,
  useUpdatePaymentScheduleMutation,
} = PaymentScheduleApi;

export { PaymentScheduleApi };
