import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "services/axios";

const ENDPOINT = process.env.REACT_APP_API_URL;
const API = `${ENDPOINT}/api/claim`;

const claimsApi = createApi({
  reducerPath: "claim",
  baseQuery: axiosBaseQuery({
    baseUrl: API,
  }),
  endpoints(builder) {
    return {
      fetchClaims: builder.query({
        providesTags: () => ["claimFetch"],
        query: (query) => {
          return {
            url: "/read",
            method: "GET",
            params: query,
          };
        },
      }),
      uploadImageClaims: builder.mutation({
        query: (body) => {
          return {
            url: "/uploadImagePayroll",
            method: "post",
            body: body,
          };
        },
      }),
      fetchClaimsId: builder.query({
        providesTags: (id) => ["claimFetchId", id],
        query: (id) => {
          return {
            url: `/${id}`,
            method: "GET",
          };
        },
      }),
      addClaims: builder.mutation({
        invalidatesTags: (result, error, body) => {
          return [{ type: "" }];
        },
        query: (body) => {
          return {
            url: "/create",
            method: "POST",
            body: body,
          };
        },
      }),
      updateClaims: builder.mutation({
        invalidatesTags: (result, error, body) => {
          return [{ type: "LeaveReportsAll" }];
        },
        query: (body) => {
          return {
            url: `/update`,
            method: "PUT",
            body: body,
          };
        },
      }),
      updateClaimsStatus: builder.mutation({
        invalidatesTags: (result, error, body) => {
          return [{ type: "updateClaimStatus" }];
        },
        query: (body) => {
          return {
            url: `/updateStatus`,
            method: "PUT",
            body: body,
          };
        },
      }),
      deleteClaims: builder.mutation({
        invalidatesTags: (result, error, body) => {
          return [{ type: "" }];
        },
        query: (body) => {
          return {
            url: "/delete",
            method: "POST",
            body: body,
          };
        },
      }),
    };
  },
});

export const {
  useFetchClaimsQuery,
  useAddClaimsMutation,
  useFetchClaimsIdQuery,
  useUpdateClaimsMutation,
  useDeleteClaimsMutation,
  useUpdateClaimsStatusMutation,
  useUploadImageClaimsMutation,
} = claimsApi;
export { claimsApi };
