import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import Layout from "layouts";
import Payroll from "pages/payroll";
import ClaimsType from "pages/payrollSetup/claimsType";
import PaymentSchedule from "pages/payrollSetup/paymentSchedule";
import PayrollComponent from "pages/payrollSetup/payrollComponent";
import EmployeePayslip from "pages/reports/employeePayslip";
import PayrollReport from "pages/reports/payrollReport";
import RunPayroll from "pages/runPayroll";
import ClaimDetails from "pages/payroll/claimDetails";
import AddEditClaimsType from "pages/payrollSetup/claimsType/addEditClaimsType";
import Detail from "pages/payroll/detail";
import Cpfb from "pages/cpf-configuration";
import Shg from "pages/cpf-configuration/shg";
import Sdl from "pages/cpf-configuration/sdl";
import Fwl from "pages/cpf-configuration/fwl";
import AddEditFwl from "pages/cpf-configuration/fwl/addEditFwl";
import SalaryCeilings from "pages/cpf-configuration/salary-ceilings";
import AddEditCpfb from "pages/cpf-configuration/addEditCpfb";
import AddEditShg from "pages/cpf-configuration/shg/addEditShg";
import AddEditSalaryCeilings from "pages/cpf-configuration/salary-ceilings/addEditShg";
import AddEditSdl from "pages/cpf-configuration/sdl/addEditSdl";
import PayrollReportDetail from "pages/reports/payrollReport/detail";
import PayrollPayslipDetail from "pages/reports/employeePayslip/detail";

const AppRouter = () => {
  return (
    <Router basename={window.__POWERED_BY_QIANKUN__ ? '/payroll' : '/'}>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Payroll />} />
          <Route path="detail" element={<Navigate to="/" replace />}></Route>
          <Route path="detail/:id" element={<Detail />} />
          <Route path="claims-type" element={<ClaimsType />} />
          <Route path="shg-funds" element={<Shg />} />
          <Route path="shg-funds-create-edit" element={<AddEditShg />} />
          <Route path="shg-funds-create-edit/:id" element={<AddEditShg />} />
          <Route path="salary-ceilings" element={<SalaryCeilings />} />
          <Route
            path="salary-ceilings-create-edit"
            element={<AddEditSalaryCeilings />}
          />
          <Route
            path="salary-ceilings-create-edit/:id"
            element={<AddEditSalaryCeilings />}
          />
          <Route path="cpf-configuration" element={<Cpfb />} />
          <Route path="fwl" element={<Fwl />} />
          <Route path="fwl-edit/:id" element={<AddEditFwl />} />
          <Route path="fwl-edit" element={<AddEditFwl />} />
          <Route
            path="cpf-configuration-create-edit"
            element={<AddEditCpfb />}
          />
          <Route
            path="/cpf-configuration-create-edit/:id"
            element={<AddEditCpfb />}
          />
          <Route path="sdl" element={<Sdl />} />
          <Route path="sdl-create-edit" element={<AddEditSdl />} />
          <Route path="sdl-create-edit/:id" element={<AddEditSdl />} />
          <Route path="payment-schedule" element={<PaymentSchedule />} />
          <Route path="payroll-component" element={<PayrollComponent />} />
          <Route path="employee-payslip" element={<EmployeePayslip />} />
          <Route path="employee-payslip/detail" element={<EmployeePayslip />} />
          <Route
            path="employee-payslip/detail/:id"
            element={<PayrollPayslipDetail />}
          />
          <Route path="payroll-report" element={<PayrollReport />} />
          <Route
            path="payroll-report/detail/:id"
            element={<PayrollReportDetail />}
          />
          <Route path="run-payroll" element={<RunPayroll />} />
          <Route path="run-payroll/:id" element={<RunPayroll />} />
          <Route path="claim-detail" element={<ClaimDetails />} />
          <Route path="create-claim-type" element={<AddEditClaimsType />} />
          <Route
            path="*"
            element={<h1 className="text-2xl text-red-500">PAGE NOT FOUND</h1>}
          />
        </Route>
      </Routes>
    </Router>
  );
};

export default AppRouter;
