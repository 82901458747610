import React from "react";
import { Button } from "@bluesilodev/timhutcomponents";
import { useNavigate } from "react-router-dom";
import { SalaryCeilingsDataTable } from "components/common/customTables";
import DocumentIconSVG from "assets/icons/DocumentIconSVG/DocumentIconSVG";
import { useAlertApi } from "services/alert-api";
import { useDispatch } from "react-redux";
import { setShgReducer } from "store/slice/cpfbSlice";
import {
  useDeleteSalaryCeilingsMutation,
  useFetchSalaryCeilingsQuery,
} from "store/api/salaryCeilingsApi";

const SalaryCeilings = () => {
  const navigate = useNavigate();
  const [requestShgType, resultShgType] = useDeleteSalaryCeilingsMutation();
  const dispatch = useDispatch();

  useAlertApi(resultShgType);

  const { data, refetch } = useFetchSalaryCeilingsQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  const handleDelete = (id) => {
    requestShgType({ id: id }).then(() => {
      refetch();
    });
  };
  /**
   * @function handleEdit
   * @param {import("type/typeCpf").EmployeeContribution} data
   */
  const handleEdit = (data) => {
    navigate(`/salary-ceilings-create-edit/${data?._id}`, { state: data });
    dispatch(setShgReducer(data));
  };
  return (
    <>
      <div className="w-full h-max mt-5">
        <div className="w-full flex justify-between">
          <div className="w-[100%] flex flex-row gap-5 justify-end">
            <Button
              className={"w-[270px]"}
              style="solid"
              onClick={() => {
                dispatch(setShgReducer(null));
                navigate("/salary-ceilings-create-edit");
              }}
              label={
                <div className="flex px-4 gap-1 capitalize">
                  <DocumentIconSVG color={"white"} />
                  <div>Add new salary ceilings</div>
                </div>
              }
            />
          </div>
        </div>
        <div className="flex flex-col mt-6 p-1 gap-2 w-[100%]">
          <SalaryCeilingsDataTable
            dataTable={
              data?.data?.map((a) => ({
                ...a,
              })) ?? []
            }
            handleDelete={handleDelete}
            handleEdit={handleEdit}
          />
        </div>
      </div>
    </>
  );
};

export default SalaryCeilings;
